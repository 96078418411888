import isNullorUndefined from "./isNullOrUndefined";

// Pass in a origin a and a null destination in order to open one location and not directions
export default function openInGoogleMaps(origin, destination) {
  if (!isNullorUndefined(origin) && !isNullorUndefined(destination)) {
    window.open('https://www.google.com/maps/dir/' + origin.replace(/ /g, '+') + '/' + destination.replace(/ /g, '+'), '_blank');
  }
  if (!isNullorUndefined(origin) && isNullorUndefined(destination)) {
    window.open('https://www.google.com/maps/search/?api=1&query=' + origin.replace(/ /g, '+'))
  }  
}