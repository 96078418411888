
import Image from 'material-ui-image'
import { useContext, useLayoutEffect, useState } from "react"
import { Button, Modal } from 'react-bootstrap'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Checkbox, FormControl, Select, Switch, MenuItem } from "@mui/material"
import { AppointBlueButton, AppointGreyButton, AppointRedButton } from '../../../utils/AppointButton'
import MuiAlert from "@material-ui/lab/Alert"
import { TextField } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { Calendar } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useHistory } from 'react-router-dom'
import PlatformStyles from '../../../PlatformStyles'
import config from '../../../config'
import UserContext from '../../../context/UserContext'
import { getNA } from "../../../functions/getNA"
import { notNullString } from "../../../functions/notNullString"
import { socket } from '../../../socket'
import FileUploader from "../../FileUpload/FileUploader"
import Table from '../../Users/Table'
import { Alert } from "../../misc/Alert"
import { militaryTimeToSeconds } from "../../misc/militaryTimeToSeconds"
import secondsToMilitaryTime from "../../misc/secondsToMilitaryTime"
import { secondsToTime } from "../../misc/secondsToTime"
import NursingTopicCard from './NursingTopicCard'
import './SettingsArea.css'
import SingleAddStudent from './SingleAddStudent'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import axios from 'axios'
import { axiosURL } from '../../../axiosURL_Front'
import capitalize from "../../../functions/capitalize"
import getAccessLevel from "../../../functions/getAccessLevel"
import { getAuthHeader } from '../../../functions/getToken'
import { isInternalUser } from "../../../functions/isInternalUser"
import isNullorUndefined from '../../../functions/isNullOrUndefined'
import isStartDateBeforeEndDate from '../../../functions/isStartDateBeforeEndDate'
import { toReadableDate } from '../../../functions/normalizeTime'
import { notify } from '../../Notification/notify'
import InformationToolTip from '../../misc/InformationToolTip'
import { ProfilePicture } from "../../misc/ProfilePicture"
import { listToString, listToStringWithDelimeter } from '../../misc/listToString'
import SingleShareCoordinator from "./SingleShareCoordinator"

const useStyles = makeStyles(() => ({
  SharedWithUserRowWrapper: {
    margin: '5px',
    height: '60px',
    borderRadius: PlatformStyles.BorderRadius,
    display: 'flex',
    alignItems: 'center',
    boxShadow: PlatformStyles.BoxShadow
  },
  SharedWithUserRowPicture: {
    top: '-10px !important',
    width: '40px',
    minWidth: '40px',
    borderRadius: PlatformStyles.BorderRadius,
    marginLeft: '5px',
    height: '40px',
  },
  SharedWithUserRowMiddle: {
    fontSize: '12px',
    marginLeft: '5px'
  },
  SharedWithUserRowAccess: {
    marginLeft: 'auto',
    order: '2',
    width: '30%',
    marginRight: '5px',
    textAlign: 'right'
  },
  FullWidth: {
    width: '100% !important',
  },
  EditIcon: {
    width: '35px',
    padding: '3px',
    borderRadius: PlatformStyles.BorderRadius,
    float: 'right',
    "&:hover": {
        color: 'white',
        backgroundColor: '#474f7a',
        borderRadius: PlatformStyles.BorderRadius,
        transition:' background-color .3s, color .1s',   
      }
  },
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    width: "308px",
    margin: '10px 0px 10px 6px !important',
    flexWrap: "wrap",
    height: '40px',
    borderRadius:'4px',
    "&> div.container": {
        gap: "6px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    "& > div.container > span": {
        padding: "1px 3px",
        borderRadius: "4px",
    },
  },
  FormInput: {
      width: '100%',
  },
  AddIcon: {
    height: '40px',
    fontSize: '20px',
    "&:hover": {
      color: "green",
    },  
  },
  DropDownButton: {
    margin: "12px 0px 0px 0px",
    fontSize: "1rem",
    width: "320px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none !important",
    borderRadius: PlatformStyles.BorderRadius,
    boxShadow: PlatformStyles.BoxShadow +' !important',
    cursor: "pointer",
    padding: "0px 20px",
  },
  fileUploadButton: {
    marginBottom: '20px',
    marginLeft: '7px'
  },
  ModalBody: {
    // backgroundColor: 'red',
    // height: '800px',
  },
  Type: {
    marginBottom: '5px !important',
  },
  Left: {
    // backgroundColor: 'blue',
    width: '49.2%',
    // marginTop: '20px'
    margin: '5px 5px 5px 0px !important'
  },
  Right: {
    // backgroundColor: 'red',
    width: '49.2%',
    margin: '5px 0px 5px 5px !important',
  },
  WeekDays: {
    margin: '15px 0 !important',
  },
  WeekDayButton: {
    boxShadow: PlatformStyles.BoxShadowWhiteBackground + ' !important',
  },
  Calendar: {
    width: '100% !important',
    margin: 'auto',
    // marginTop: '8px !important',
    // textAlign: 'center',
    boxShadow: 'none',
    border: '.5px solid #bcbcbc',
    minHeight: '310px'
  },
  ClinicalDayText: {
    marginTop: '5px',
    marginLeft: '16px',
    textAlign: 'left', 
    fontSize: '14px',
    color: '#666666'
  },
  Alert: {
    marginTop: '10px'
  },
  PublishedSubTextTrue: {
    marginLeft: '10px',
    marginTop: '5px',
    backgroundColor: '#00a921',
    color: '#f7f7f7',
    padding: '0px 15px',
    borderRadius: PlatformStyles.BorderRadius,
    width: 'fit-content'
  },
  PublishedSubTextFalse: {
    fontStyle: 'italic',
    marginLeft: '10px',
    marginTop: '5px'
  },
  fileNameArea: {
    margin: '15px 0px',
  },
  BufferTimeWrapper: {
    marginLeft: '8px' ,
    height: '50px',
    marginTop: '20px'
  }
  
}));

const ClinicalModalType = {
  ADD: 'add',
  EDIT: 'edit',
  DUPLICATE: 'duplicate'
}

const SettingsArea = ({props, handleRemove, handleUpload, handleClearImportErrors}) => {
      
    const selection = props.settingsSelection
    const schoolID = props.schoolID
    const fileID = props.fileID
    const students = props.students
    const clinicals = props.clinicals
    const clinicalImportErrors = props.clinicalImportErrors
    const nursingTopics = props.nursingTopics
    const published = props.published
    const deleted = props.deleted
    const fileName = props.fileName
    const fileSettings = props.fileSettings
    const studentMessage = props.studentMessage
    let sharedWith = props.sharedWith
    const { userData } = useContext(UserContext)

    // We do this to make column sorting easier
    for (let i = 0; i < students.length; i++) {
        students[i].firstLastName = students[i].firstName + " " + students[i].lastName   
        students[i].avatar = <div style={{height: '40px', width: '40px',  display: 'block', marginLeft: 'auto', marginRight: 'auto'}}><Image src={config.deafaultUserPath} animationDuration='0' color='transparent' imageStyle={{width: '40px', height: '40px'}} /></div> 
    }

    const handleStudentUpload = (e, addTestingData) => {
        handleUpload(e, addTestingData)
    }

    const handleClinicalUpload = (e) => {
      const packagedData = {
        fileID: fileID,
        data: e.data,
        existingData: {clinicals},
        type: 'clinicals'
      }

      socket.emit('uploadClinical', packagedData)
    }

    const StudentSettings = ({handleRemove}) => {
        const [confirmRemoveModal, setConfirmRemoveModal] = useState(false)
        const [studentToRemove, setStudentToRemove] = useState({})
        const [addTestingData, setAddTestingData] = useState(false)
        const [editStudentMessageModal, setEditStudentMessageModal] = useState(false)
        const classes = useStyles();

        const ManageStudentColumns = [
            {
              title: "",
              field: "avatar",
              width: '100px',
              filtering: false,
              render: (rowData) => {        
                return (<ProfilePicture fileName={rowData.profilePicture} size={'small'} />)
              },
            },
            {
              title: "Name",
              field: "firstLastName",
            },
            {
              title: "Student ID",
              field: "studentID",
              render: rowData => notNullString(rowData.studentID) ? rowData.studentID : getNA()
            },
            {
              title: "Email",
              field: "email",
            },
            {
              title: "",
              render: (rowData) => (
                <img onClick={() => {setStudentToRemove(rowData); setConfirmRemoveModal(true)}} alt="Remove Student" src={config.trashCanIcon} className="clinicalTableIcon trashCanIcon" />          
              ),
              width: '20%',
              filtering: false
            }
            
          ];

          function editStudentMessage(studentMessage){
            const data = {
              fileObjectID: fileID,        
              studentMessage         
            }
            
            socket.emit('EditStudentMessageRequest', data);
    
            setEditStudentMessageModal(false)
          }    

          function EditStudentMessageModal(props) {
            const [newStudentMessage, setNewStudentMessage] = useState(studentMessage)
            const [errorMessage, setErrorMessage] = useState('')
  
            return (
              <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Edit Custom Student Message
                  </Modal.Title>
                </Modal.Header>
    
                <Modal.Body>
                  <div className={classes.ModalBody}>
                    <TextField 
                        value={newStudentMessage}
                        onChange={(e) => setNewStudentMessage(e.target.value)}
                        multiline
                      
                        minRows={10}
                        className={classes.FullWidth}
                        id="New Student Message"
                        label="New Student Message" 
                      />
                  </div>
                  
                  {errorMessage != '' &&
                    <>
                      <br/>
                      <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>
                    </>
                  }
                </Modal.Body>
    
                <Modal.Footer>
                  <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
                  <AppointBlueButton onClick={() => props.onSubmit(newStudentMessage)}>Confirm</AppointBlueButton>
                </Modal.Footer>
              </Modal>
            );
          }

          function ConfirmRemoveModal(props) {
            return (
              <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Confirm
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p style={{textAlign: "center"}}>
                    Would you like to remove {studentToRemove.firstName} {studentToRemove.lastName} from this schedule?
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
                  <AppointBlueButton onClick={props.onSubmit}>Confirm</AppointBlueButton>
                </Modal.Footer>
              </Modal>
            );
          }

        const handleSubmit = (e) => {
            let data = {
                studentObjectID: e.studentObjectID,
                fileObjectID: fileID,
            }
            handleRemove(data)
        }
        return (
        <div className='settingsAreaContent'>

            <ConfirmRemoveModal
                show={confirmRemoveModal}
                onHide={() => {setConfirmRemoveModal(false); setStudentToRemove({})}}
                onSubmit={() => {handleSubmit(studentToRemove); setConfirmRemoveModal(false); setStudentToRemove({})}}
            />

            <EditStudentMessageModal
              show={editStudentMessageModal}
              onHide={() => {setEditStudentMessageModal(false)}}
              onSubmit={editStudentMessage}
            />

            <div>
                <div className='settingsAreaTitle'>
                  Add Students
                </div>
                <SingleAddStudent schoolID={schoolID} fileID={fileID} addTestingData={addTestingData} />
                <FileUploader upload={e => handleStudentUpload(e, addTestingData)} fileTypes={['csv']} />

                {isInternalUser(userData.user.schoolID) &&
                  <>
                    Add Testing Data
                    <Switch
                      checked={addTestingData}
                      color={'primary'}
                      thumbon
                      onChange={() => setAddTestingData(!addTestingData)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <InformationToolTip>This is for internal use only, when turned on, it will add testing data to all students that are imported.</InformationToolTip>
                  </>
                }

                <div className='settingsAreaTitle'>
                  Custom Student Message
                  <InformationToolTip>This section allows you to type out a message to be shown to students when inputting their schedules</InformationToolTip>
                  <EditOutlined className={classes.EditIcon} onClick={() => setEditStudentMessageModal(true)} />
                </div>

                <div className={classes.fileNameArea} style={{marginBottom: '100px'}}>
                  {studentMessage !== '' ?
                    <>{studentMessage}</>
                    :
                    <i>No message has been entered</i>
                  }
                </div>                
            </div>
            
            <div className>
                <div className='settingsAreaTitle'>Manage Students</div>
                {/* HandleAddStudents is what we are using since it is already in the table component but it is used to remove students */}
                <Table data={students} columns={ManageStudentColumns} type='removeStudent' />
            </div>
            
        </div>
        )
    }

    const ClinicalSettings = () => {
      const [confirmRemoveModal, setConfirmRemoveModal] = useState(false)
      const [clinicalToRemove, setClinicalToRemove] = useState({})
      const [confirmRemoveAllModal, setConfirmRemoveAllModal] = useState(false) // New Addition (Hunter)
      // Used when needing to edit or duplicate a clinical
      const defaultClinicalContext = {type: '', clinical: {type: '', section: '', capacity: 0, remainingSeats: 0, professor: '', location: '', startTime: 0, endTime: 0, inpatientDays: [], weekDays: []}}
      const [clinicalContext, setClinicalContext] = useState(defaultClinicalContext)
      const [clinicalModal, setClinicalModal] = useState(false)
      const [addNursingTopicValue, setAddNursingTopicValue] = useState('')
      const [importErrorsMessageBox, setImportErrorsMessageBox] = useState(false);
      const [linkedClinicalSiteEntities, setLinkedClinicalSiteEntities] = useState([])

      useLayoutEffect(() => {
        axios.post(axiosURL + '/entity/getLinkedSites', {schoolId: schoolID}, getAuthHeader())
          .then(res => {
            if (res.data.success) {
              setLinkedClinicalSiteEntities(res.data.data)
            } else {
              notify('e', res.data.message)
            }
          })
      }, [])

      const classes = useStyles();

      function openClinicalModal(rowData, type) {
        setClinicalContext({type, clinical: rowData})
        setClinicalModal(true)
      }

      function addNursingTopic(nursingTopic){
        const data = {
          fileID,
          nursingTopic
        }

        if(nursingTopic != '') {
          socket.emit('addNursingTopic', data)
          setAddNursingTopicValue('')
        }
      }

      const ManageClinicalColumns = [
          {
            title: "Type",
            field: "type",
          },
          {
            title: "Section",
            field: "section",
            width: 'fit content'
          },
          {
            title: "Week Days",
            field: "weekDays",
            render: (rowData) => {
              return <div>{listToString(rowData.weekDays)}</div>      
            }
          },
          {
            title: "Start Time",
            field: "startTime",
            render: (rowData) => {
              return <>{secondsToTime(rowData.startTime)}</>      
            }
          },
          {
            title: "End Time",
            field: "endTime",
            render: (rowData) => {
              return <>{secondsToTime(rowData.endTime)}</>      
            }
          },
          {
            title: "",
            cellStyle: {
              width: '28%',
            },
            render: (rowData) => {
              return (
                <div>
                  <img onClick={() => {openClinicalModal(rowData, ClinicalModalType.DUPLICATE)}} alt='Duplicate Clinical' title='Duplicate Clinical' src={config.duplicateIcon} className='clinicalTableIcon duplicateIcon' />
                  <EditOutlined onClick={() =>  {openClinicalModal(rowData, ClinicalModalType.EDIT)}} alt='Edit Clinical' title='Edit Clinical' className='clinicalTableIcon editIcon editIconClinical'/>
                  <img onClick={() => {setClinicalToRemove(rowData); setConfirmRemoveModal(true)}} alt='Delete Clinical' title='Delete Clinical'src={config.trashCanIcon} className='clinicalTableIcon trashCanIcon' />
                </div>
              )
            },
            filtering: false
          },
        ];

        function ConfirmRemoveModal(props) {
          return (
            <Modal
              {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Confirm
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{textAlign: "center"}}>
                  Would you like to remove {clinicalToRemove.type} {clinicalToRemove.section} from this schedule?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
                <AppointBlueButton onClick={props.onSubmit}>Confirm</AppointBlueButton>
              </Modal.Footer>
            </Modal>
          );
        }

        // function ConfirmRemoveAllModal(props) {
        function RemoveAllTheClinicalsModal(props) {
          return (
            <Modal
              {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Confirm
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{textAlign: "center"}}>
                  Would you like to remove all clinicals from this schedule?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
                <AppointBlueButton onClick={props.onSubmit}>Confirm</AppointBlueButton>
              </Modal.Footer>
            </Modal>
          );
        }

      const handleSubmit = (e) => {
          const data = {
            clinicals: [e.clinicalObjectID],
            fileObjectID: fileID
          }

          socket.emit('RemoveClinicalsRequest', data);
      }

      function removeAllClinicals(){
        const data = {
          fileObjectID: fileID,
          clinicals: clinicals.map(clinical => clinical.clinicalObjectID)
        }      
        socket.emit('RemoveClinicalsRequest', data);                  
        setConfirmRemoveAllModal(false)
      }

      function ClinicalModal(props) {
        const isAdd = clinicalContext.type === ClinicalModalType.ADD
        const isEdit = clinicalContext.type === ClinicalModalType.EDIT
        const isDuplicate = clinicalContext.type === ClinicalModalType.DUPLICATE
        const useClinicalContext = clinicalContext.type === ClinicalModalType.EDIT || clinicalContext.type === ClinicalModalType.DUPLICATE
        const clinical = clinicalContext.clinical

        const [weekDays, setWeekDays] = useState(useClinicalContext ? clinical.weekDays : [])
        const [clinicalType, setClinicalType] = useState(useClinicalContext ? clinical.type : '')
        const [section, setSection] = useState(useClinicalContext ? (isDuplicate ? '' : clinical.section) : '')    
        const [seats, setSeats] = useState(useClinicalContext ? clinical.capacity : '')     
        const [professor, setProfessor] = useState(useClinicalContext ? clinical.professor : '')
        const [location, setLocation] = useState(useClinicalContext ? clinical.location : null)
        const [startTime, setStartTime] = useState(useClinicalContext ? secondsToMilitaryTime(clinical.startTime) : '')
        const [endTime, setEndTime] = useState(useClinicalContext ? secondsToMilitaryTime(clinical.endTime) : '')
        const [values, setValues] = useState(useClinicalContext ? clinical.inpatientDays : []); 
        
        // Weird bug where if the variable is undefiend it will set it to today's date
        if (useClinicalContext && typeof clinical.startDate == 'undefined') clinical.startDate = null
        if (useClinicalContext && typeof clinical.endDate == 'undefined') clinical.endDate = null
        const [startDate, setStartDate] = useState(useClinicalContext ? clinical.startDate : null)
        const [endDate, setEndDate] = useState(useClinicalContext ? clinical.endDate : null)
        const [selectClinicalDays, setSelectClinicalDays] = useState(useClinicalContext ? !clinical.isEveryDayInpatient : false)
        const [alert, setAlert] = useState('')

        const handleWeekdayChange = (day) => {
          let index = weekDays.indexOf(day)
          if(index > -1){ // Removes weekday from list
              setWeekDays(weekDays.filter(weekDay => weekDay != day))
          } else { // Adds weekday to list
              setWeekDays(weekDays.concat(day))
          }
        }
        
        const validateClinical = () => {
          console.log(typeof location)
          console.log(location)
          if (section === '') {
            // we handle this one more specifically since the duplicate clincial the only thing it doesn't fill in is a section
            setAlert('Please provide a section') 
          } else if(clinicalType === '' || professor === '' || weekDays.length === 0 || startTime === '' || endTime === '' || startDate  === null || endDate === null){
            setAlert('Please fill in every field') 
          } else if(seats < 0){
            setAlert('Number of Seats cannot be negative')
          } else if (seats == 0) {
            setAlert('Number of Seats cannot be zero')
          } else if (startTime >= endTime) {
            setAlert('Cannot have a Start Time after an End Time')
          } else if (!isStartDateBeforeEndDate(startDate, endDate)) {
            setAlert('Cannot have a Start Date after an End Date')
          }  else if (location === '' || isNullorUndefined(location)) {
            setAlert('Please select a location')
          } else {
            return true
          }
          return false
        }

        const getClinicalDays = () => {
          // When clinicals days are not edited, they are going to return in
          // MM/DD/YYYY format. When they are edited, they return as javascript Date
          // Objects. Calling .toString() on a date obeject will return MM/DD/YYYY and 
          // Calling it on the string doesn't change it
          let returnDates = []
          for (let date of values) {
            returnDates.push(date.toString())
          }
          return returnDates
        }

        const getWeekDays = () => {
            return weekDays.join('; ');
        }
    
        const handleAddClinical = () => {
          setAlert('')
    
          if(validateClinical()){
          // if(true){
            if (clinicals.findIndex(e => e.type.trim().toLowerCase() === clinicalType.trim().toLowerCase() && e.section.trim().toLowerCase() === section.trim().toLowerCase()) > -1) {
              setAlert('Clinical with this type and section already exists')
              return
            }
            let tempWeekDays = getWeekDays()
            let tempClinicalDays = getClinicalDays()
            
            const packagedData = {
              fileID: fileID,
              // First one is to mock a header. We don't pass in the selectClinicals Boolean becuase if it is true, clinical days will be empty
              data: [[], [clinicalType, section, seats, professor, location, tempWeekDays, secondsToTime(militaryTimeToSeconds(startTime)), secondsToTime(militaryTimeToSeconds(endTime)), toReadableDate(startDate), toReadableDate(endDate), listToStringWithDelimeter(tempClinicalDays, ';')]],
              existingData: {clinicals},
            }
      
            socket.emit('uploadClinical', packagedData)
            
            setAlert('')            
          }
        }

        const handleEditClinical = () => {
          console.log(clinicals)
          console.log(clinicalContext)
          if(!validateClinical()) {
            return
          }
          if (clinicals.findIndex(e => e.type.trim().toLowerCase() === clinicalType.trim().toLowerCase() && e.section.trim().toLowerCase() === section.trim().toLowerCase() && e.clinicalObjectID != clinicalContext.clinical.clinicalObjectID) > -1) {
            setAlert('Clinical with this type and section already exists')
            return
          } else if (seats < clinicals[clinicals.findIndex(e => e.clinicalObjectID == clinicalContext.clinical.clinicalObjectID)].students.length) {
            setAlert('Cannot lower seat number below the number of students already assigned to ' + clinicalContext.type + ' ' + clinicalContext.section )
            return
          } else if (isNullorUndefined(location) || isNaN(location)) {
            setAlert('Location is Required')
            return
          }
          console.log('location', location)

            let TempClinicalDays = getClinicalDays()         
            const packagedData = {
              fileID: fileID,
              clinicalObjectID: clinicalContext.clinical.clinicalObjectID,
              type: clinicalType,
              section,
              clinicalType,
              seats, 
              professor, 
              location, 
              weekDays,
              startDate,
              endDate,
              startTime: militaryTimeToSeconds(startTime),
              endTime: militaryTimeToSeconds(endTime),
              inpatientDays: TempClinicalDays,
            }
      
            socket.emit('editClinical', packagedData)
            
            setAlert('')
            props.onHide()
        }
    
        function Alert(props) {
          return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        function handleInpatientCheckBoxChange(e) {
          // Value passed in is the value that we are going to
          // When unchecking box, we are going to clear out clinical days
          const checked = e.target.checked
          if (!checked) {
            setValues([])
          } 
          setSelectClinicalDays(checked)
        }

        function getModalTypeString(isAdd, isEdit, isDuplicate, isButton) {
          if (isAdd) return 'Add Clinical'
          if (isEdit) return 'Edit Clincial'
          if (isDuplicate) {
            if (isButton) return 'Add Clinical'
            return 'Duplicate Clinical'
          }
        }
        return (
          <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {getModalTypeString(isAdd, isEdit, isDuplicate, false)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.ModalBody} style={{padding: '0px 50px !important'}}>
            <p style={{textAlign: "center"}}>
              <FormControl fullWidth className={classes.Type}>
                  <TextField style={{float: 'left'}} select value={clinicalType} label={"Type"} id={''} onChange={(e) => setClinicalType(e.target.value)} fullWidth>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                    {nursingTopics != null &&
                      nursingTopics.map(topic => {
                        return <MenuItem value={topic}>{topic}</MenuItem>
                      })
                    }
                  </TextField>
              </FormControl>
    
              <TextField value={section} onChange={(e) => setSection(e.target.value)} className={classes.Left} id="section" label="Section" />
              <TextField value={seats} onChange={(e) => setSeats(e.target.value)} className={classes.Right} id="seats" label="Seats" type="number" />
              <TextField value={professor} onChange={(e) => setProfessor(e.target.value)} className={classes.Left} id="professor" label="Professor" />
              <FormControl className={classes.Right}>
                  <TextField select value={location} label={'Location'} onChange={(e) => setLocation(e.target.value)} variant="outlined" >
                  <MenuItem value={null}>&nbsp;</MenuItem>
                    {nursingTopics != null &&
                      linkedClinicalSiteEntities.map(site => {
                        return <MenuItem value={site.id}>{site.name}</MenuItem>
                      })
                    }
                  </TextField>
              </FormControl>
  
              <div className={classes.WeekDays + ' addClassWeekDayButtons'}>
                  <button onClick={() => handleWeekdayChange('Sunday')} className={weekDays.indexOf('Sunday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Sun</button>
                  <button onClick={() => handleWeekdayChange('Monday')} className={weekDays.indexOf('Monday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Mon</button>
                  <button onClick={() => handleWeekdayChange('Tuesday')} className={weekDays.indexOf('Tuesday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Tue</button>
                  <button onClick={() => handleWeekdayChange('Wednesday')} className={weekDays.indexOf('Wednesday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Wed</button>
                  <button onClick={() => handleWeekdayChange('Thursday')} className={weekDays.indexOf('Thursday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Thu</button>
                  <button onClick={() => handleWeekdayChange('Friday')} className={weekDays.indexOf('Friday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Fri</button>
                  <button onClick={() => handleWeekdayChange('Saturday')} className={weekDays.indexOf('Saturday') > -1 ? 'addClassWeekTrue': 'addClassWeekFalse'}>Sat</button>
              </div>
              
              <TextField value={startTime} onChange={(e) => setStartTime(e.target.value)} className={classes.Left} id="startTime" label="Start Time" InputLabelProps={{ shrink: true }} type='time' />
              <TextField value={endTime} onChange={(e) => setEndTime(e.target.value)} className={classes.Right} id="endTime" label="End Time" InputLabelProps={{ shrink: true }} type='time' />
             
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={setStartDate}
                  renderInput={(params) => <TextField {...params} className={classes.Left}/>}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={setEndDate}
                  renderInput={(params) => <TextField {...params} className={classes.Right}/>}
                />
              </LocalizationProvider>

              <div style={{float: 'left', width: '100%', display: 'flex', alignItems: 'center'}}>
                Select Individual Clinical Days
                <Checkbox 
                  checked={selectClinicalDays}
                  onChange={handleInpatientCheckBoxChange}
                  color="success"
                />
                <InformationToolTip>When not everyday of the semester is a clinical day, turning this on allows you to select individual clinical days</InformationToolTip>
              </div>

              {selectClinicalDays &&
                <>
                  <div className={classes.ClinicalDayText}>
                    Clinical Days
                  </div>
                  <Calendar 
                    numberOfMonths={2}
                    className={classes.Calendar}
                    show={true}
                    multiple
                    value={values} 
                    onChange={setValues}
                    format="MM/DD/YYYY"
                    plugins={[
                      <DatePanel />
                      ]}
                  />
                </>
              }
            
              {alert != '' &&
                <Alert className={classes.Alert} severity="error">{alert}</Alert>  
              }              
            </p>
          </Modal.Body>
          <Modal.Footer>
            <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            <AppointBlueButton onClick={(e) => {
                if (isAdd || isDuplicate) {
                  handleAddClinical(e)
                } else if (isEdit) {
                  handleEditClinical(e)
                }              
              }}>
              {getModalTypeString(isAdd, isEdit, isDuplicate, true)}
            </AppointBlueButton>
          </Modal.Footer>
        </Modal>
        )
      }

      function ImportErrorsMessageBox(props) {
        // Creates row based on what type of error is present
        const rowCreation = (row) => {
          if (row.row !== null){
            return (
              <div id='importErrorRow'>
                <span id='row'>Row: <strong>{row.row}</strong></span>
                <span id='column'>Column: <strong>{row.column}</strong></span>
                <span id='message'>{row.message}</span>
              </div>
              )
          } else {
            return (
              <div id='importErrorRow'>
                <span id='messageFull'>{row.message}</span>
              </div>
              )
          }
        }
        
        // If there are more than 20 errors found, it prompts the user to check that they imported the right file (20 is random number)
        const excessiveErrors = () =>{
            if (clinicalImportErrors.length > 20){
                return (<div id='excessiveErrors'>A large amount of errors were found, please ensure that the right file was imported.</div>)
            }
        }
        if(clinicalImportErrors.length > 0){
          setImportErrorsMessageBox(true)
        }
        return (
          <Modal
            {...props}
            backdrop='static'
            keyboard='false'
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="messageBox"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
              Import Errors
              </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className='importErrorModalBody'>
                    <div id='importErrorDefaultMsg'>Please read and correct the following errors before importing again. For assitance with importing data, please contact us.</div>
                    {excessiveErrors()}
                    {clinicalImportErrors.map((error) => (
                        rowCreation(error)
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* TODO: Add help method eventually */}
                {/* <Button variant="primary" onClick={props.onHelp}>Help with Importing Data</Button> */}
                <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            </Modal.Footer>
          </Modal>
        );
      }

      function closeImportErrorFunction(){
        console.log('first')
        handleClearImportErrors()
      }

      return (
      <div className='settingsAreaContentTop'>

            <ConfirmRemoveModal
                show={confirmRemoveModal}
                onHide={() => { setConfirmRemoveModal(false); setClinicalToRemove({}) }} 
                onSubmit={() => { handleSubmit(clinicalToRemove); setConfirmRemoveModal(false); setClinicalToRemove({}) }}
            />

            <ClinicalModal
              show={clinicalModal} 
              onHide={() => { setClinicalContext(defaultClinicalContext); setClinicalModal(false) }} 
            />

            <RemoveAllTheClinicalsModal
              show={confirmRemoveAllModal} 
              onHide={() => { setConfirmRemoveAllModal(false) }}
              onSubmit={() => { removeAllClinicals() }}
            />

            <ImportErrorsMessageBox
              show={importErrorsMessageBox}
              onHide={() => { closeImportErrorFunction() }}
            />  

        <div className="row">
          <div className='left'>            

            <div style={{marginBottom: '50px'}}>
              <div className='settingsAreaTitle'>
                Clinical Types
              </div>

              <div style={{marginRight: '300px'}}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                <TextField
                  id="clinicalTypeInput"
                  placeholder="Ex. Maternity"
                  autoComplete='off'
                  variant='standard'
                  className={classes.FormInput}
                  value={addNursingTopicValue}
                  onKeyDown={e => {
                    if (e.keyCode === 13) { // Enter Key
                      addNursingTopic(addNursingTopicValue)
                    }
                  }}
                  onChange={e => setAddNursingTopicValue(e.target.value)}
                />
                  <PlusCircleOutlined className={classes.AddIcon} onClick={() => addNursingTopic(addNursingTopicValue)} />  
                </FormControl>
              </div>
              
              <div className='nursingTopicCardArea'>
                { nursingTopics != null &&
                    nursingTopics.map(topic => {
                      return <NursingTopicCard fileID={fileID} nursingTopic={topic} />
                    })
                }
              </div>
            </div>

          </div>

          <div className='right'>

            <div>
                <div className='settingsAreaTitle'>Add Clinicals</div>
                  <AppointBlueButton
                      type="button"
                      className={classes.DropDownButton}
                      onClick={() => {
                        setClinicalContext({type: ClinicalModalType.ADD, clinical: {}})
                        setClinicalModal(true)
                      }}
                    >
                      Add Clinical
                  </AppointBlueButton>
                  <>&nbsp;</>
                  <FileUploader width={'100%'} height={'150px'} upload={e => handleClinicalUpload(e)} fileTypes={['csv']} />
            </div>
            
          </div>
          <div className='full'>
            <div> 
                <div className='settingsAreaTitle'>Manage Clinicals</div>

                {/* HandleAddStudents is what we are using since it is already in the table component but it is used to remove students */}
                <Table data={clinicals} columns={ManageClinicalColumns} type='removeClinical' />
                <AppointBlueButton
                    type="button"
                    className={classes.DropDownButton}
                    onClick={() => setConfirmRemoveAllModal(true)}
                    disabled={clinicals.length === 0}
                  >
                    Remove All Clinicals
                </AppointBlueButton>
            </div>
          </div>
        </div>
      </div>
      )
    }

    const GeneralSettings = () => {
      const classes = useStyles();
      const [confirmUnassignAllClinicalsModal, setConfirmUnassignAllClinicalsModal] = useState(false)
      const [confirmDeleteFileModal, setConfirmDeleteFileModal] = useState(false)
      const [editFileNameModal, setEditFileNameModal] = useState(false)
      const history = useHistory();

      // Only admins can edit other's permissions
      for (let shareInfo of sharedWith) {
        if (getAccessLevel(sharedWith, userData.user.id) != 'admin') {
          shareInfo.disabled = true
        }
        // We don't allow the user to edit their own level of permission
        if (shareInfo.id === userData.user.id) {
          shareInfo.disabled = true
        }
      }

      function deleteFile(){
        const data = {
          userID: userData.user.id,
          fileObjectID: fileID        
        }      

        socket.emit('deleteFile', data)
        setConfirmDeleteFileModal(false)
      }
      
      function publishFile(){
        const data = {
          fileObjectID: fileID            
        }      

        socket.emit('publishFile', data);          
      }

      function unAssignAllClinicals(removeLocked){
        const data = {
          fileObjectID: fileID,
          removeLocked        
        }    

        setConfirmUnassignAllClinicalsModal(false)
        socket.emit('unassignAllClinicals', data);          
      }

      function editFileName(newName){
        const data = {
          fileObjectID: fileID,        
          newFileName: newName         
        }
        
        socket.emit('editFileName', data);

        setEditFileNameModal(false)
      }

      function editBufferTime(time){
        const data = {
          fileObjectID: fileID,        
          bufferTime: time.target.value         
        }
        
        socket.emit('editBufferTime', data);
      }

      function editCompactView(){
        const data = {
          fileObjectID: fileID,        
        }
        
        socket.emit('ChangeScheduleViewRequest', data);
      }

      function ConfirmDeleteFileModal (props){
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirm
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {deleted ? 
                <>
                  <p style={{textAlign: "center"}}>
                    Undeleting file will allow coordinator and students to view it again
                  </p>
                </>
                :
                <>
                  <p style={{textAlign: "center"}}>
                    Would you like to delete this schedule?
                  </p>
                  <p style={{textAlign: "center"}}>
                    <b>Warning:</b> This cannot be undone without support intervention. 
                  </p>
                </>  
              }
              
            </Modal.Body>
            <Modal.Footer>
              <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
              <AppointRedButton onClick={props.onDelete}>Confirm</AppointRedButton>
            </Modal.Footer>
          </Modal>
        );
      }

      function ConfirmUnassignAllClinicalsModal(props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirm
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{textAlign: "center"}}>
                Would you like to remove all of the assigned clinicals in this schedule from unlocked students, all students, or cancel?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
              <AppointBlueButton onClick={props.onSubmit}>Unlocked Only</AppointBlueButton>
              <AppointRedButton onClick={props.onDelete}>All Students</AppointRedButton>
            </Modal.Footer>
          </Modal>
        );
      }

      function EditFileNameModal(props) {
        const [newFileName, setNewFileName] = useState(fileName)
        const [errorMessage, setErrorMessage] = useState('')

        function validateFileName(){
          if (notNullString(newFileName)) {
            setErrorMessage('')
            props.onSubmit(newFileName)
          } else {
            setErrorMessage('Please enter file name')
          }
        }
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Rename Schedule
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className={classes.ModalBody}>

                <TextField 
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    style={{marginTop:'10px'}}
                    className={classes.FullWidth}
                  
                    id="New File Name"
                    label="New File Name" 
                  />
              </div>
              
              {errorMessage != '' &&
                <>
                  <br/>
                  <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>
                </>
              }
            </Modal.Body>

            <Modal.Footer>
              <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
              <AppointBlueButton onClick={() => validateFileName()}>Confirm</AppointBlueButton>
            </Modal.Footer>
          </Modal>
        );
      }

      function handleConflictOverrideToggle() {
        const packagedData = {
          fileID: fileID
        }
  
        socket.emit('conflictOverrideToggle', packagedData)
      }

      function handleAccessChange (id, action){
        if (action == 'remove') {
          socket.emit('removeAccessRequest', id, action, fileID)
        } else {
          socket.emit('changeAccessRequest', id, action, fileID)
        }
      }

      const SharedWithUserRow = ({name, email, access, id, disabled}) => {
        function accessChange(e) {
          handleAccessChange(id, e.target.value)
        }

        return (
          <div className={classes.SharedWithUserRowWrapper}>
            <div className={classes.SharedWithUserRowPicture} >
              <Image src={config.deafaultUserPath} animationDuration='0' imageStyle={{width: '100%', height: '100%', border: '1px solid grey', borderRadius: '20%'}} />
            </div>
            <div className={classes.SharedWithUserRowMiddle} >
              <div>
                {name}
              </div>
              <div>
                {email}
              </div>
            </div>
            <div className={classes.SharedWithUserRowAccess} >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={access}
                disabled={disabled}
                style={{textAlign: 'center', fontSize: '15px', width: '100px'}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null
                }}
                label="Age"
                onChange={accessChange}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'edit'}>Edit</MenuItem>
                <MenuItem value={'view'}>Viewer</MenuItem>
                <>&nbsp;</>
                <MenuItem value={'remove'}>Remove</MenuItem>
              </Select>
              
            </div>

          </div>
        )
      }

      return (
        <div>
          <ConfirmUnassignAllClinicalsModal
              show={confirmUnassignAllClinicalsModal}
              onHide={() => {setConfirmUnassignAllClinicalsModal(false)}}
              onSubmit={() => {unAssignAllClinicals(false)}}    /// Delete locked
              onDelete={() => {unAssignAllClinicals(true)}}     /// Delete all
          />

          <ConfirmDeleteFileModal
              show={confirmDeleteFileModal}
              onHide={() => {setConfirmDeleteFileModal(false)}}
              onDelete={() => {deleteFile()}}
          />

          <EditFileNameModal
              show={editFileNameModal}
              onHide={() => {setEditFileNameModal(false)}}
              onSubmit={(newName) => {editFileName(newName)}}
          />

          <div className="settingsAreaMain">

            <div className='settingsAreaTitle'>Schedule Sharing</div>

            <SingleShareCoordinator fileID={fileID} />

            {sharedWith.length > 0 &&
              <>
                Coordinators with Access
                {sharedWith.map(user => {
                    return (
                      <SharedWithUserRow name={user.name} email={user.email} access={user.access} id={user.id} disabled={user.disabled} />
                    )
                })}
              </>
            }
            &nbsp;

            <div className='settingsAreaTitle'>Schedule Name<EditOutlined className={classes.EditIcon} onClick={() => setEditFileNameModal(true)} /></div>

            <div className={classes.fileNameArea} style={{marginBottom: '100px'}}>
              {fileName}
            </div>       

            <div className='settingsAreaTitle'>General Settings</div>

            <div className={classes.BufferTimeWrapper}>
              Buffer Time
              {/* Fix this forcontrol and switch. I changed from mui core to normal mui and it doesn't look right */}
              <FormControl variant='standard' style={{minWidth: '200px', bottom: '0', verticalAlign: 'middle', float: 'right'}} >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fileSettings[0].bufferTime}
                  style={{textAlign: 'center', fontSize: '15px'}}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  label="BufferTime"
                  onChange={editBufferTime}
                >
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={3600}>1 Hour</MenuItem>
                  <MenuItem value={7200}>2 Hours</MenuItem>
                  <MenuItem value={14400}>4 Hours</MenuItem>
                  <MenuItem value={21600}>6 Hours</MenuItem>
                  <MenuItem value={28800}>8 Hours</MenuItem>
                  <MenuItem value={86400}>Entire Day</MenuItem>
                </Select>
              </FormControl>
            </div>


            <div className={classes.BufferTimeWrapper}>
              Compact Schedule View
              <Switch
                checked={fileSettings[0].compactView}
                color={'primary'}
                thumbon
                onChange={() => {editCompactView()}}
                inputProps={{ 'aria-label': 'controlled' }}
              />

              <InformationToolTip>This allows you to choose between the standard weekly view of students' schedules, or choose 
                the compact option which simply displays each class or clinical as sorted bullet points.
              </InformationToolTip>
            </div>

            <div className={classes.BufferTimeWrapper}>
              Disable Conflict Warning
              <Switch
                checked={fileSettings[0].conflictOverride}
                onChange={() => {handleConflictOverrideToggle()}}
                color={'primary'}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <InformationToolTip>This setting, if turned on, disables the warning you see when trying to schedule a clinical over a student's existing class or blocked time request</InformationToolTip>
            </div>

            {published ?
              <AppointGreyButton
                type="button"
                className={`${classes.DropDownButton}`}
                onClick={() => publishFile()}
              >
                  Unpublish Schedule
              </AppointGreyButton>
              :
              <AppointBlueButton
              type="button"
              className={`${classes.DropDownButton}`}
              onClick={() => publishFile()}
              >
                Publish Schedule
              </AppointBlueButton>
            }

            {published ?
              <div className={classes.PublishedSubTextTrue}>Schedule is currently published</div>
              :
              <div className={classes.PublishedSubTextFalse}>Schedule is not published</div>
            }

            <br></br>
            <AppointBlueButton
                type="button"
                className={classes.DropDownButton}
                onClick={() => setConfirmUnassignAllClinicalsModal(true)}
              >
                Unassign All Clinicals
            </AppointBlueButton>

            <br></br>

            <AppointRedButton
                type="button"
                className={classes.DropDownButton}
                onClick={() => setConfirmDeleteFileModal(true)}
              >
                {deleted ? 
                  <>UnDelete Schedule</> 
                  : 
                  <>Delete Schedule</>
                  }
            </AppointRedButton>
          </div>
        </div>                        
      )
    }

    function invalidPermissionDiv(string) {
      return <div>You do not have control over the <b>{capitalize(string)}</b> settings for this schedule. If you need it, please request additional access from the owner of the file.</div>
    }
    
    function renderSelection(userAccessLevel, selection) {
      if (selection === 'students') {
        if (userAccessLevel === 'view') return invalidPermissionDiv(selection)
        return <StudentSettings handleRemove={(e) => handleRemove(e)} />
      }

      if (selection === 'clinicals') {
        if (userAccessLevel === 'view') return invalidPermissionDiv(selection)
        return <ClinicalSettings />
      }

      if (selection === 'general') {
        if (userAccessLevel === 'view' || userAccessLevel === 'edit') return invalidPermissionDiv(selection)
        return <GeneralSettings />
      }
    }

    return (
      <section className='settingsArea settingsAreaMain'>
          { renderSelection(getAccessLevel(sharedWith, userData.user.id), selection) }
      </section>
    )      
}

export default SettingsArea;
