import { makeStyles } from "@mui/styles";
import { MenuItem, InputBase, Menu, Divider, TextField } from "@mui/material";
import CheckIcon from "@material-ui/icons/Check";
import { useEffect, useState, useContext } from "react";
import { Button } from "reactstrap";
import {socket} from '../../../socket'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import UserContext from '../../../context/UserContext'
import PlatformStyles from "../../../PlatformStyles";

const useStyles = makeStyles(() => ({
  DropDownButton: {
    margin: "12px 0px 0px 7px",
    fontSize: "1rem",
    width: "320px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none !important",
    borderRadius: PlatformStyles.BorderRadius,
    boxShadow: PlatformStyles.BoxShadow +' !important',
    
    backgroundColor: "white !important",
    color:"black !important",
    cursor: "pointer",
    padding: "0px 20px",
    "&:hover":{
      backgroundColor: '#e6e6e6 !important',
      color: 'black'
    }
  },
  fileUploadButton: {
    marginBottom: '20px',
    marginLeft: '7px'
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  search: {
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    marginRight: "20px",
    marginLeft: 0,
    width: "100%",
    border: "1px solid grey"
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create("width"),
    width: "100%"
  },
  searchBarContainer: {
    minWidth: "inherit",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "default",
    "&.MuiListItem-button": {
      "&:hover": {
        backgroundColor: "white"
      }
    }
  },
  menuDivider: {
    margin: "0 20px"
  },
  dashboardSelectMenu: {
    "& .MuiPopover-paper": {
      minWidth: "380px",
      maxWidth: "fit-content",
      transform: 'translate(0%, 35%)',

    }
  },
  externalLinkIcon: {
    borderLeft: "1px solid var(--color-gray-eighty-five)",
    padding: "10px 0px 10px 10px",
    color: "var(--color-primary)",
    cursor: "pointer"
  },
  checkedItem: {
    color: "indigo"
  }
}));

const SingleShareCoordinator = ({fileID}) => {
  // Needed to get schoolID
  const {userData, setUserData} = useContext(UserContext)

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selection, setSelection] = useState('');
  const [selectionEmail, setSelectionEmail] = useState('');
  const [options, setOptions] = useState([])

  // useEffect(() => {
  //   setSelection(options[0].label);
  // }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {      
      socket.emit('coordinatorSearch', userData.user.schoolID, userData.user.id, fileID, searchText);
      socket.on('coordinatorSearch', result => {
        socket.off('coordinatorSearch');
        setOptions(result)
        
      });
      
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])


  const handleMenuOpen = async (event) => {
  
    setAnchorEl(event.currentTarget);
    socket.emit('coordinatorSearch', userData.user.schoolID, userData.user.id, fileID,  searchText);
    socket.on('coordinatorSearch', result => {
      socket.off('coordinatorSearch');
      setOptions(result)
      
    });
  };

  const handleClose = (e) => {   

    if (e.target.id !== selection && e.target.id !== "") {
      let studentInfo = e.target.id.split('^&*')
      setSelection(studentInfo);
      setSelectionEmail(studentInfo[1]);
    }
    
    setAnchorEl(null);
    setOptions([])    
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleShare = () => {
    if(selectionEmail != ''){
      socket.emit('shareFile', selectionEmail, fileID)
      setSearchText('')
      setSelection('')
      setSelectionEmail('')
    }
  }

  return (
    <div>
      <Button
        type="button"
        className={classes.DropDownButton}
        onClick={handleMenuOpen}
      >
        {selection == ''?
          <i>Share Schedule</i>
          :
          selection
        }
        <KeyboardArrowDownIcon />
      </Button>
      {renderDashboardMenu()}
      
      <button id="UploadButton" className={'fileUploadButton ' + classes.fileUploadButton} onClick={() => handleShare()}>Share</button>
    </div>
  );

  function renderDashboardMenu() {

    function renderOption(name, email) {
      // ^&* We use this as something unique that we can split on later
      return <div style={{height: '100%'}} id={name + '^&*' + email}>{name} <i id={name + '^&*' + email} style={{fontSize: '13px'}}>{email}</i></div>;
    }

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted={true}
          open={!!anchorEl}
          onClose={handleClose}
          className={classes.dashboardSelectMenu}
          transformOrigin={{
            vertical: -5
          }}
        >
          <MenuItem
            className={classes.searchBarContainer}
            disableTouchRipple={true}
          >
             <TextField
              value={searchText}
              onChange={handleSearchChange}
              id="Coordinator Name" 
              placeholder="Coordinator Name"
              style={{
                width: '100%'
              }}
              />
          </MenuItem>
          <Divider />
          {options.length === 0 ? (
            <MenuItem disabled>
              <i>No Coordinators Found</i>
            </MenuItem>
            ) : (
              options.map((item, index) => {
                return (
                  <div key={index}>
                    <MenuItem onClick={(e) => handleClose(e)}>
                      {renderOption(item.label, item.email)}
                    </MenuItem>
                    <Divider className={classes.menuDivider} />
                  </div>
                );
              })
          )}
        </Menu>
      </>
    );
  }
}

export default SingleShareCoordinator;
