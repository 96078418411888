import React, { useEffect, useRef, useState } from "react";
import "./SearchBar.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import removeExtraSpaces from "../../../functions/removeExtraSpaces.js";
import { LockOutlined} from '@ant-design/icons';
import isNullorUndefined from "../../../functions/isNullOrUndefined";
import config from "../../../config";
import { makeStyles } from "@mui/styles";

let active = false
function useOutsideAlerter(ref) {

  // We use this so that the arrow key functionality is only happening when you click into the student list
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        active = false
      }
    }

    function handleInsideClick(event) {
      if (ref.current.contains(event.target)) {
        active = true
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleInsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleInsideClick);
    };
  }, [ref]);
}

export default function SearchBar({published, students, clinicals, selectedStudent, studentListChange}) {

  const useStyles = makeStyles(() => ({
    PersonIcon: {
        width: '15px',
        height: '15px',
        marginBottom: '3px',
        
    },
    StudentCount: {
        position: 'absolute',
        right: '0',
        top: '10px',
        color: 'black',
        fontSize: '13px'
    }
  }));
  const classes = useStyles();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  let [filteredData, setFilteredData] = useState(students);
  const [student, setStudent] = useState('')
  const [wordEntered, setWordEntered] = useState('');
  const [searchWord, setSearchWord] = useState('')
  const [completedFilter, setCompletedFilter] = useState(false);
  const [inProgressFilter, setInProgressFilter] = useState(false);
  const [situationFilter, setSituationFilter] = useState(false);

  useEffect(() => {
    const func = (event) => {
      // Prevents user from holding down key which causes bad behavior
      if (event.repeat && active && event.key !== 'Backspace') {
        event.preventDefault()
        return
      }
      handleKeyEvent(event.code, student)
    };
    document.addEventListener("keydown", func)
    return () => {
      document.removeEventListener("keydown", func)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student])

  let completeFilterCss = completedFilter ? 'searchBarFilterTrue': ''
  let inProgressFilterCss = inProgressFilter ? 'searchBarFilterTrue': ''
  let situationFilterCss = situationFilter ? 'searchBarFilterTrue': ''

  let searchWordTMP = removeExtraSpaces(searchWord)      

  let newFilter = students.filter((value) => {
    return (value.firstName.concat(' ', value.lastName).toLowerCase().includes(searchWordTMP.toLowerCase().trim()));
  });

  function swapCompleted(){
    setCompletedFilter(!completedFilter)  /// Handles change
    setInProgressFilter(false)            /// Ensures the opposite is false
  }

  function swapInProgress(){
    setInProgressFilter(!inProgressFilter)
    setCompletedFilter(false)
  }
      
  if(completedFilter){
    newFilter = newFilter.filter((value) => {
      if (value.studentClinicalList.length > 0 && value.nursingTopics.length <= value.studentClinicalList.length) {
        return (value.firstName.concat(' ', value.lastName).toLowerCase());
      }
    });
  }

  if(inProgressFilter){
    newFilter = newFilter.filter((value) => {
      if (( value.nursingTopics.length == 0 && value.studentClinicalList.length == 0 ) || value.nursingTopics.length > value.studentClinicalList.length) {
        return (value.firstName.concat(' ', value.lastName).toLowerCase());
      }
    });
  }

  if(situationFilter){
    newFilter = newFilter.filter((value) => {
      if (value.circumstance != '' || value.blockedTimeList.length > 0) {
        return (value.firstName.concat(' ', value.lastName).toLowerCase());
      }
    });
  }

  filteredData = newFilter

  const clearInput = () => {
    setFilteredData(students);
    setWordEntered("");
  };

  function handleKeyEvent(key, student) {
    if (student !== '' && student !== undefined ){
      if (active && key === "ArrowUp" ) {
        const currentStudentIndex = filteredData.slice(0, students.length).findIndex(studentInData => studentInData.studentObjectID == student)
        if (currentStudentIndex === 0) return
        let objectID = filteredData.slice(0, students.length)[currentStudentIndex - 1].studentObjectID
        setStudent(objectID)
        studentListChange(objectID)
        const divElement = document.getElementById(objectID);
        divElement.scrollIntoView({block: 'nearest', inline: 'start' });
      }

      if (active && key === "ArrowDown" ) {  
        const currentStudentIndex = filteredData.slice(0, students.length).findIndex(studentInData => studentInData.studentObjectID == student)
        if (currentStudentIndex === filteredData.length - 1) return
        let objectID = filteredData.slice(0, students.length)[currentStudentIndex + 1].studentObjectID
        setStudent(objectID)
        studentListChange(objectID)
        const divElement = document.getElementById(objectID);
        divElement.scrollIntoView({block: 'nearest', inline: 'start' });
      }
    }
  }

  // Prevents list from scrolling up or down on arrow key. NOt ideal but remove and you can see the bad behavior
  window.addEventListener("keydown", function(e) {
    if(["ArrowUp","ArrowDown" ].indexOf(e.code) > -1 && active) {
        e.preventDefault();
    }
  }, false);

  return (
    <div className="search" ref={wrapperRef}>
      <label id="studentListTitle">
          <strong>Students</strong>
          <span className={classes.StudentCount}>
              <img src={config.personIcon} className={classes.PersonIcon} />
              {filteredData.length}
          </span>
      </label>
      <div className="searchInputs">
        <input
          type="text"
          placeholder='Search...'
          autoComplete="off"
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
        />
        <div className="searchIcon">
          {wordEntered.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>

      <div className='studentSearchFileterArea'>
          <div className='studentSearchInnerIcons'>
              <button className={'studentSearchInnerButton ' + completeFilterCss} onClick={() => swapCompleted()}> 
                  <span className={"studentSearchButton" + completeFilterCss}>Complete</span>
              </button>
              <button className={'studentSearchInnerButton ' + inProgressFilterCss} onClick={() => swapInProgress()}>
                  <span className={"studentSearchButton" + inProgressFilterCss}>In Progress</span>
              </button>
              <button className={'studentSearchInnerButton ' + situationFilterCss} onClick={() => setSituationFilter(!situationFilter)}>
                  <span className={"studentSearchButton" + situationFilterCss}>Exception</span>
              </button>
          </div>
      </div>
      
        <div className="dataResult">                
          {/* Adds each student to the list */}
          {filteredData.slice(0, students.length).map((student, key) => {
            let SearchBarStudentBackground = ''
            let tempCheckMark = <span>&nbsp;</span>
            let tempCircumstance = <span>&nbsp;</span>
            let tempLocked = <span>&nbsp;</span>
            let tempChecklistRed = <span>&nbsp;</span>

            if (student.locked == true){
              tempLocked = <span> <LockOutlined></LockOutlined> </span>
            }

            if (published) {
              for (let clinical of student.studentClinicalList) {
                let workingClin = clinicals.find(i => i.clinicalObjectID === clinical);
                if (isNullorUndefined(workingClin)) continue

                let clinicalChecklist = workingClin.checklist
                let studentChecklist = student.checklists[workingClin.location]
                
                if (isNullorUndefined(studentChecklist)) {
                  if (clinicalChecklist.length > 0) {
                    tempChecklistRed = <img alt='Red checklist icon' style={{width: '16px', height: '16px'}} src={config.redChecklistIcon}/>
                  } 
                  continue
                }

                if (studentChecklist.length < clinicalChecklist.length) {
                  tempChecklistRed = <img alt='Red checklist icon' style={{width: '16px', height: '16px'}} src={config.redChecklistIcon}/>
                }
              }
            }

            if (student.blockedTimeList.length > 0){
              tempCircumstance = <span className="tempCircumstance">!</span>
            }
            
            if (student.studentClinicalList.length > 0 && student.nursingTopics.length <= student.studentClinicalList.length) {                    
              tempCheckMark = <span className="tempCheckMark">{String.fromCharCode(10004)}</span>
            }

            if (student.circumstance != '') {
              tempCircumstance = <span className="tempCircumstance">!</span>
            }
            if(student.studentObjectID === selectedStudent.studentObjectID){
              SearchBarStudentBackground += " SearchBarStudentBackground"
            }
            
            return (
              <a className={"dataItem" + SearchBarStudentBackground} onClick={() => {setStudent(student.studentObjectID); studentListChange(student.studentObjectID)}}>
                <li id={student.studentObjectID} key={student.studentObjectID} className="dataItemStyling">
                  <span className="StudentSearchBarStudentName">
                    {student.firstName.concat(' ', student.lastName)}
                  </span>
                  <span style={{float: "right", width:'5px'}}>
                    {tempCircumstance}
                  </span>
                  <span style={{float: "right", width:'25px'}}>
                    {tempCheckMark}
                  </span>
                  <span style={{float: "right" , width:'25px', marginTop:'-3.5px'}}>
                    {tempLocked}
                  </span>
                  <span style={{float: "right", width:'25px'}}>
                    {tempChecklistRed}
                  </span>
                </li>
              </a>
            );
          })}                
        </div>
    </div>
  );
}
