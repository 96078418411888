import React, { useState, useEffect } from 'react';
import PlatformStyles from '../../PlatformStyles';
import { socket } from '../../socket'
import { styled, keyframes } from '@mui/system';

const PROGRESS_EVENT = 'ProgressBarEvent'
const ONE_HUNDRED = 100

const swirl = keyframes({
  '0%': {
    backgroundPosition: '0 0'
  },
  '100%': {
    backgroundPosition: '50px 50px'
  }
});

const ProgressBarWrapper = styled('div')(({ progress }) => ({
  backgroundColor: 'rgba(128, 128, 128, 0.5)',
  width: '100%',
  position: 'fixed',
  bottom: '0px',
  zIndex: '9999',
  visibility: progress !== null ? 'visible' : 'hidden',
}));

const ProgressBarDiv = styled('div')(({ progress }) => ({
  width: `${progress}%`,
  height: '10px',
  backgroundColor: PlatformStyles.LogoPurple,
  transition: 'width .2s linear',
  opacity: '1 !important',
  backgroundImage: 'linear-gradient(-45deg, rgba(255,255,255,0.25) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.25) 50%, rgba(255,255,255,0.25) 75%, transparent 75%, transparent)',
  backgroundSize: '50px 50px',
  animation: `${swirl} 1s linear infinite`
}));

const ProgressBarText = styled('div')({
  position: 'fixed',
  bottom: '15px',
  right: '20px',
  fontSize: '12px',
  color: PlatformStyles.Gray,
});

export default function ProgressBar() {  
  const [progress, setProgress] = useState(null)
  const [timer, setTimer] = useState(null);
  
  function adjustProgress(progress){
    let adjProgress = (200 / (1+ (2.71828182845904523536028747135266249)**(-0.06 * progress))) - 100

    if (progress >= 99){
      return progress
    }
    return adjProgress  
  }

  useEffect(() => {
    socket.on(PROGRESS_EVENT, (data => {
      // When we get the first event, progress will be null and we start a timer
      // After 25 seconds if something has not compeleted the bar will dissapear automatically
      if (progress == null) {
        setTimer(setInterval(() => {
          reset()
        }, 25000))
      }
      setProgress(adjustProgress(data))
      if (data >= ONE_HUNDRED) { 
        setTimer(setInterval(() => {
          reset()
        }, 1000))
      }
    }))
    return () => {
      socket.off(PROGRESS_EVENT);
      clearInterval(timer);
    };
  }, [timer, progress]);

  function reset() {
    setProgress(null)
    clearInterval(timer)
    setTimer(null)
  }

  return (
    <ProgressBarWrapper progress={progress}>
      <ProgressBarDiv progress={progress} />
      {progress !== null && <ProgressBarText>{Math.round(progress)}%</ProgressBarText>}
    </ProgressBarWrapper>
  );
}