import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppointBlueButton, AppointGreyButton } from '../../utils/AppointButton';
import UserContext from '../../context/UserContext';

function ErrorPage(props) {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  console.log('hi')
  return (
    <div style={styles.container}>
      <span role="img" aria-label="sad" style={styles.emoji}>
        😞
      </span>
      <h1 style={styles.message}>Oops! Something went wrong...</h1>
      {userData.user ? (
        <div style={{display: 'flex'}}>
          <AppointGreyButton style={{marginRight: '10px'}} onClick={() => history.push('/home')}>Home</AppointGreyButton>
          <AppointBlueButton onClick={() => history.push('/dashboard')}>Dashboard</AppointBlueButton>
        </div>
      ) : (
        <AppointBlueButton onClick={() => history.push('/home')}>Home</AppointBlueButton>
      )}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    color: '#212529',
    textAlign: 'center',
    padding: '0 1rem',
  },
  emoji: {
    fontSize: '5rem',
  },
  message: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '2rem',
  },
};

export default ErrorPage;