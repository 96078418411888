export function secondsToTimeWithLeadingZero(seconds) {
    return conversion(seconds, true);
}

export function secondsToTime(seconds) {
    return conversion(seconds, false);
}

function conversion(seconds, addLeadingZero) {
    let returnValue;

    if (seconds < 3600) {
        returnValue = "12:" + doubleDigit(seconds / 60) + " AM";
    } else if (seconds < 43200) {
        let tempHours = Math.trunc(seconds / 3600);
        let tempMinutes = doubleDigit(Math.trunc((seconds % 3600) / 60));
        returnValue = (addLeadingZero ? doubleDigit(tempHours) : tempHours) + ":" + tempMinutes + " AM";
    } else if (seconds < 46800) {
        returnValue = "12:" + doubleDigit((seconds - 43200) / 60) + " PM";
    } else {
        let tempHours = Math.trunc((seconds / 3600) - 12);
        let tempMinutes = doubleDigit(Math.trunc((seconds % 3600) / 60));
        returnValue = (addLeadingZero ? doubleDigit(tempHours) : tempHours) + ":" + tempMinutes + " PM";
    }
    return returnValue;
}

function doubleDigit(x) {
  return x.toString().padStart(2, "0");
}