import { makeStyles } from "@mui/styles";
import { TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { axiosURL } from '../../axiosURL_Front';
import UserContext from '../../context/UserContext';
import belongsToAppointEntity from '../../functions/belongsToAppointEntity';
import loggedIn from '../../functions/checkLoggedIn';
import { convertEntityType } from "../../functions/entityTypeHelpers";
import { getAuthHeader } from '../../functions/getToken';
import { isSuperUser } from '../../functions/UserTypeChecks';
import PlatformStyles from '../../PlatformStyles';
import Loading from '../Loading/Loading';
import AddressAutocomplete from "../misc/AddressAutoComplete";
import { Alert } from '../misc/Alert';
import { notify } from '../Notification/notify';
import Table from '../Users/Table';
import './Dashboard.Student.css';
import { AppointBlueButton, AppointGreyButton } from "../../utils/AppointButton";

// We pass in type to denote whether we are loading schools or clinical sites
// 1 is for schools, 2 if for clinical sites
export default function SuperUserEntityView({ type }) {
  const history = useHistory();
  const classes = useStyles();
  /* Sets user context */
  const {userData, setUserData} = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  let [entities, setEntities] = useState([])
  const [addEntityModal, setAddEntityModal] = useState(false)

  
  useLayoutEffect(() =>{
    // Checks that the user is logged in. If not, pushes them to the login page
    if(!loggedIn(localStorage.getItem("auth-token"))){
      history.push('/login')
      return
    }
    
    if (belongsToAppointEntity(userData.user.schoolID) && isSuperUser(userData.user.accountType)) {
      axios.post(axiosURL + '/entity/getEntities', { type }, getAuthHeader())
      .then(res => {
        if (!res.data.success) {
          notify('e', res.data.message)
          return
        }
        console.log(res.data.data)
        let returnData = []
        
        for (let entity of res.data.data ) {
          returnData.push({
            name: entity.name,
            id: entity.id,
            type: convertEntityType(entity.type),
            address: entity.address.address,
            rowClick: { name: entity.name, id: entity.id, type: entity.type }
          })
        }

        setEntities(returnData)
        setIsLoading(false)
      })
    } else {
      history.push('/dashboard')
    }
          
  }, [isLoading, userData, history])

  if(isLoading){
    return <Loading />
  }

  function AddEntityModal(props) {
    const [name, setName] = useState('')
    const [address, setAddress] = useState({})
    const [errorMessage, setErrorMessage] = useState('')

    function addEntity() {
      setErrorMessage('')

      const packagedData = {
        name,
        type, // Comes form props
        address
      }

      axios.post(axiosURL + '/entity/createEntity', packagedData, getAuthHeader())
        .then(res => {
            if (res.data.success) {
              notify('s', res.data.message)

              let returnData = []
              returnData.push({
                name: res.data.data.name,
                id: res.data.data.id,
                type: convertEntityType(res.data.data.type),
                address: res.data.data.address.address,
                rowClick: { name: res.data.data.name, id: res.data.data.id, type: res.data.data.type }
              })
              setEntities(entities.concat(returnData))
              
              setName('')
              setAddEntityModal(false)
            } else {
              setErrorMessage(res.data.message)
            }
          
        })   
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='addStudentsModalHeader'>
            <div className='addStudentsModalHeader'>
              Create New {type === 1 ? 'School' : 'Clinical Site'}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ModalBody}>
            <TextField 
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{marginTop:'10px'}}
                className={classes.Input}
                required
                id="Entity Name"
                label="Entity Name" 
              />

            <AddressAutocomplete value={address} style={{marginTop:'10px'}} onChange={setAddress} />

            {errorMessage !== '' &&
              <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>  
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
          <AppointBlueButton onClick={addEntity}>Create</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function handleRowClick(e){
    history.push(window.location.pathname + '/entity/' + e.id)
  }

  return(
    <div className='main'>
      
      <AddEntityModal
          show={addEntityModal}
          onHide={() => {setAddEntityModal(false)}}
      />

      <div className='random'>
        <Table data={entities} columns={columns} type='entity' title={type === 1 ? 'Schools' : 'Clinical Sites'} handleAddEntity={() => setAddEntityModal(true)} handleRowClick={handleRowClick} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  Input: {
      borderRadius: PlatformStyles.BorderRadius,
      border: 'none',
      width: '100%',
  },
  AddStudentMessage: {
    fontStyle: 'italic',
    marginLeft: '2px',
    marginTop: '5px'
  },
  ModalBody: {
    marginTop: '-15px',
    marginBottom: '15px'
  },
  ErrorMessage: {
    marginTop: '20px'
  }
  
}));

const columns = [
  {
      title: "Entity Name",
      field: "name"
  },
  {
    title: "Entity Type",
    field: "type"
  },
  {
      title: "Entity ID",
      field: "id"
  },
  {
    title: "Address",
    field: "address"
}
]
