import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PlatformStyles from "../../PlatformStyles";
import { conflictCheckHelperForCalendar } from "../misc/conflictCheckHelper";

const days = [
  '',
  "Sunday",
  "Monday", 
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

const hours = [...Array(24).keys()]

function convertTime(num) {
  if (num === 0 || num === 24) {
    return ''
  }
  if (num === 12) {
    return '12pm'
  }
  if (num < 12) {
    return num + 'am'
  }
  if (num > 12) {
    return (num -12) + 'pm'
  }
}

const multiplier = 4.166666666666666
const stanNum = 14

// Had to replicate a litte in calculateWidth so if you change this then change that too
const timeColumnWidth = '50px'
let columnWidth = `calc((100% - ${timeColumnWidth}) / 7)`

function convertToReadableTime(decimal) {
  decimal = decimal / multiplier
  let hours = Math.floor(decimal);
  let minutes = Math.floor((decimal - hours) * 60);
  let amOrPm = (hours < 12) ? 'am' : 'pm';

  // handle midnight (12:00am) and noon (12:00pm) specially
  if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
  }

  // format the minutes and add a leading zero if needed
  let minutesString = (minutes < 10) ? '0' + minutes : minutes;

  return `${hours}:${minutesString}${amOrPm}`;
}

function calculateWidth(events, day, start, end) {
  let count = 0
  for (let event of events) {
    if (event.day.includes(day) && conflictCheckHelperForCalendar(start, end, event.start, event.end)) {
      count ++ 
      if (count > 1) {
        return `calc(((100% - 50px) / 7) / 2)`
      }
    }
  }
  return 'calc(((100% - 50px) / 7)'
  
}

function calcOffset(events, event, day, start, end) {
  let overlap = 0;
  events.forEach(event => {
    if (event.day.includes(day) && conflictCheckHelperForCalendar(start, end, event.start, event.end)) {
      overlap += 1
    }
  })

  if (overlap > 1) {
    if (event.eventType !== 'clinical'){
      return 0.5 // overlap and type is not clinical
    }
    else{
      return 7 // overlap and type is clinical
    }
  } else {
    return 0.5   // No overlap
  }
}

function calcBoxShadow(events, event, day, start, end) {
  let count = 0
  for (let event of events) {
    if (event.day.includes(day) && conflictCheckHelperForCalendar(start, end, event.start, event.end)) {
      count ++
      if (count > 1) {
        return '0 1px 4px 1px red'
      }
    }
  }

  return PlatformStyles.BoxShadow
}

function formatTimeForDisplay(seconds) {
  return ((seconds / 3600) % 24 || 24) * multiplier;
}

function formatDaysForDisplay(inputDays) {
  console.log(inputDays)
  let returnDays = []
  for (let dayInInput of inputDays) {
    returnDays.push(days.indexOf(dayInInput) -1)
  }
  return returnDays
}

function formatEvents(classes, studentClinicals, blockedTime, fullClinicalList) {

  function getClinical(id){
      const clinical = fullClinicalList.find(c => c.clinicalObjectID === id);
      return clinical || -1;
  }

  let events = []  
  classes.map(event => {
    events.push({
      title: event.className,
      eventType: 'class',
      start: formatTimeForDisplay(event.startTime),
      end: formatTimeForDisplay(event.endTime),
      day: formatDaysForDisplay(event.weekDays),
      originalEvent: event
    })
  })
  studentClinicals.map(event => {
    const clinical = getClinical(event)
    console.log(clinical)
    events.push({
      title: clinical.type + ' ' + clinical.section,
      eventType: 'clinical',
      start: formatTimeForDisplay(clinical.startTime),
      end: formatTimeForDisplay(clinical.endTime),
      day: formatDaysForDisplay(clinical.weekDays),
      originalEvent: event
    })
  })
  blockedTime.map(event => {
    events.push({
      eventType: 'blockedTime',
      start: formatTimeForDisplay(event.startTime),
      end: formatTimeForDisplay(event.endTime),
      day: formatDaysForDisplay(event.weekDays),
      title: event.summary,
      originalEvent: event
    })
  })


  return events
}

export default function WeeklyCalendar({onEventClick, classes, clinicals, blockedTime, fileClinicalList}) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const elHeight = ref.current.offsetHeight / 2;
      ref.current.scrollTop = ref.current.scrollHeight / 2 - elHeight - 10;
    }
  }, [ref.current]);

  const [events, setEvents] = useState([])
  useLayoutEffect(() => {
    setEvents(formatEvents(classes, clinicals, blockedTime, fileClinicalList))
  }, [classes, clinicals, blockedTime, fileClinicalList])

  function handleEventClick(e) {
    onEventClick(e.eventType, e.originalEvent)
  }

  function Event({ event, day, onEventClick}) {
    function handleEventClick() {
      onEventClick(event)
    }
    const start = event.start
    const end = event.end
    const title = event.title

    const duration = end - start

    const eventWidth = calculateWidth(events, day, start, end)
    const eventOffSet = calcOffset(events, event, day, start, end)
    
    let backgroundColor = ''
    let eventZIndex = 0
    let textColor = ''
    const boxShadow = calcBoxShadow(events, event, day, start, end)
    switch (event.eventType) {
      case 'class':
        backgroundColor = '#F9F9F9'
        eventZIndex = 3
        break;
      case 'clinical': 
        backgroundColor = PlatformStyles.LogoGrayBlue
        textColor = 'white'
        eventZIndex = 5
        break;
      case 'blockedTime':
        backgroundColor = PlatformStyles.DarkGray
        textColor = 'white'
        eventZIndex = 4
        break;
      default:
        break;
    }
    return(
      <div
        style={{
          position: 'absolute',
          top: `${start}%`,
          bottom: `${100 - end}%`,
          minHeight: '20px', //// One option is to make this 40px instead of 20px, and then there will always be enough room.
          width: eventWidth,
          left: `${1.2 + (day * stanNum) + eventOffSet}%`,
          backgroundColor: backgroundColor,
          boxShadow: boxShadow,
          border: '1px solid #DFDEDE',
          cursor: 'pointer',
          fontSize: '12px',
          wordWrap: 'break-word',
          overflow: 'hidden',
          padding: '3px',
          color: textColor,
          margin: '0 2px',
          zIndex: eventZIndex,
          borderRadius: '5px'
        }}
        onClick={handleEventClick}
        key={event.title}
        >
          {title} 

          {/* This displays the time. We need to hide if the height is not enough. */}
          <br></br>
            <div>
              {duration >= 5.5 &&
                <> {convertToReadableTime(event.start)} - {convertToReadableTime(event.end)}  </>
              }
            </div>
      </div>
    )
  }

  return (
    <div 
      ref={ref}
      style={{
        height: '100%',
        overflowY: "scroll"
      }}
    >
      {/* Header with week days */}
      <div
        style={{
          position: 'sticky',
          zIndex: '20',
          backgroundColor: PlatformStyles.LogoNavyBlue,
          // backgroundColor: 'green',
          width: '100%',
          top: '0px',
          left: '-10%',
          right: '50px',
          // textAlign: 'center',
          // overflow: 'hidden',
          fontWeight: 'bold'
        }}
        >
        {days.map((day , index) => {
          let width = columnWidth
          if (index === 0) {
            width = timeColumnWidth
          }
          return (
            <div
            style={{
              position: '',
              display: 'inline-block',
              zIndex: 11,
              color: 'white',
              width,
              textAlign: 'center',
              fontWeight: 'normal'
            }}
            >
            {day}    
          </div>
          )
          
        })}
      </div>

      {/* Main Content */}
      <div
        style={{
          height: '700px',
          position: 'relative', 
          marginLeft: '40px'
        }}>
        {hours.map((hour) => {
          return (
            <div
              key={hour}
              style={{
                position: 'absolute',
                top: `${(hour - 0.5) * multiplier + .51}%`,
                bottom: `${100 - (hour + 1) * multiplier}%`,
                left: '-30px',
                right: '0%',
                fontSize: '11px',
                textAlign: 'left',
                fontWeight: 'bold',
                alignItems: 'center'
            }}>
            {convertTime(hour)}
            </div>
          )
        })}
          
        {/* Adds line in the middle of the hour */}
        {hours.map((hour) => 
          <div
            key={hour}
            style={{
              position: 'absolute',
              top: `${hour * multiplier}%`,
              bottom: `${100 - (hour + 1) * multiplier}%`,
              left: '.5%',
              right: '0%',
              marginLeft: '0px',
              borderBottom: '1px solid lightgray',
              textAlign: 'left',
              fontWeight: 'bold',
              alignItems: 'center'
            }} />
        )}
        {events.map((event, index) => 
          event.day.map((day) => 
            <Event
              id="informationIconCA"
              key={`event-${index}-${day}`}
              event={event}
              day={day}
              onEventClick={handleEventClick}
              />
          ))}
      </div>
    </div>
  )
}
