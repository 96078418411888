import { makeStyles } from '@mui/styles';
import React from "react";
import { notNullString } from '../../functions/notNullString';
import PlatformStyles from '../../PlatformStyles';


const useStyles = makeStyles(() => ({
  DirectionsComponent: {
    border: 'none',
    boxShadow: PlatformStyles.BoxShadow,
    borderRadius: PlatformStyles.BorderRadius
  }
}))

const DirectionsComponent = ({ origin, originName, destination, destinationName, style }) => {
  const classes = useStyles()
  const apiKey = 'AIzaSyA0saWSO2oTMU_8fwdyKL2ugOSwowE9enI'
  const src = 'https://www.google.com/maps/embed/v1/directions?origin=place_id:' + origin + '&destination=place_id:' + destination + '&key=' + apiKey

  const errorMessage = (name) => {
    return name + ' does not have an address on record. Have them submit one to get location related information!'
  }
  
  if (!notNullString(origin)) {
    return ( 
      <div>
        {errorMessage(originName)} 
      </div>
    )
  }

  if (!notNullString(destination)) {
    return ( 
      <div>
        {errorMessage(destinationName)}
      </div>
    )
  }

  return (
    <iframe
      title='Distance Estimation'
      style={style}
      className={classes.DirectionsComponent}
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      src={src}>
    </iframe>
  );
};

export default DirectionsComponent;
