import React, { useContext, useEffect, useState } from 'react'
import { Card } from 'antd'
import CountUp from 'react-countup'
import iconMap from '../../utils/iconMap'
import './Dashboard.Card.css'

export default function Dashboard({icon, color, title, number, countUp}) {  
  return(

    <div className={"dashboardCardNumberCard"}>
      <div className={"dashboardCardContainer"}>
        <div className={"dashboardCardTitle"}> 
          {title}
        </div>

        <div className={"content"} style={{ color }}>
          <div className='dashboardCardIcon'>
            {iconMap[icon]}
          </div>
        
          
          <p className={"dashboardCardNumber vertical-center"}>
            <CountUp
              start={0}
              end={number}
              duration={2.75}
              useEasing
              useGrouping
              separator=","
            />
          </p>
        </div>
      </div>
    </div>

  )
}
