import { makeStyles } from '@mui/styles';
import React, { Component } from 'react'
import { Link } from 'react-scroll';
import {
  BellOutlined,
  RightOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from '@ant-design/icons';

const useStyles = makeStyles(() => ({
  Container: {
   padding: '20px 150px 0px 150px',
  },
  KeyPointContainer: {
    marginTop: '20px',
    display: 'flex !important',
    flexDirection: 'row',
    height: '250px',
    // backgroundColor: 'red',
    width: '100%',
    padding: '20px',
    width: '100% !important',
    border: 'none',
    height: 'fit-content',
    '@media (max-width: 1150px)': {
      flexDirection: 'column',
    },
  }
  
  
}));

const featureButtons = [
  {icon:<BellOutlined className='home-FeatureButtonIcon' style={{color: "#24435f"}} />, title: 'Ease of Use', text: <p>We only show you the information you need when you need it with an intuitive user interface.</p>},
  {icon:<RightOutlined className='home-FeatureButtonIcon' style={{color: "#24435f"}} />, title: 'Automatic Scheduling', text: <p>Can schedule up to hundreds of students in seconds.</p> },
  {icon:<MenuFoldOutlined className='home-FeatureButtonIcon' style={{color: "#24435f"}} />, title: 'Reporting Tools', text: <p>Made for you, by you. Access the data you need at a moment's notice.</p> },
  {icon:<UserOutlined className='home-FeatureButtonIcon' style={{color: "#24435f"}} />, title: 'Constantly Evolving', text: <p>An agile, fast evolving platform with new features as needs arise.</p> }
]

export default function FeatureButtons(props) {
  const classes = useStyles()  

  function renderFeatureButton(icon, title, text, index){
    let buttonID = 'keyPoint'+index
    return(
      <Link className='home_KeyPointsButton' to={buttonID} key={title} spy={true} smooth={true} offset={-150} duration={500} onClick={()=> console.log('click') }>
          {icon}
          <div className='home_KeyPointsButtonTitle'>
            {title}
          </div>
          <div className='home_KeyPointsButtonText'>
            {text}
          </div>
      </Link>
    )
    
  }
  return(
    <div id='' className={classes.Container} style = {{marginTop:'-190px'}}>
    {/* <img alt="About us section" src={config.homePageBackgroundOne} className="AboutSectionBackground" /> */}
    {/* <h4 className='HomeTitle'>Overview</h4> */}
    {/* <div className='SectionTitleUnderline'></div> */}
      &nbsp;
     {/* KEY POINTS BUTTONS */}
      <div className={`${classes.KeyPointContainer} center`}>
          {featureButtons.map((button, index) => (
            renderFeatureButton(button.icon, button.title, button.text, index + 1)
          ))}
      </div>
  </div>
  )
}
