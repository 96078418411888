import React, { useState } from 'react'
import { Dna } from 'react-loader-spinner'
import { makeStyles } from "@mui/styles";
import appointLogoAnimation from '../../Images/High Res PP v4.gif'; 

const useStyles = makeStyles(() => ({
    SpinnerContainer: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        height: '400px',
        paddingTop: '150px',
        display: 'flex',
        zIndex: '1'
    }
  }));


const Loading = () => {
    const [load, setLoad] = useState(false)    
    const classes = useStyles();

    setTimeout(() => {
        setLoad(true)
    }, 220);

    return (
        <>
            {load ?
                <div className={classes.SpinnerContainer}>
                    <img style={{height:'350px', width:'350px'}} src={appointLogoAnimation} />
                </div>
                :
                <></>
            }
        </>
        
    );
};

export default Loading;