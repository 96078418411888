import axios from "axios";
import { axiosURL } from "../axiosURL_Front";
import { getAuthHeader } from "../functions/getToken";

class Logger {

    static log(message) {
      if (isNotProd()) {
        console.log(message);
      }
    }

    static warn(message) {
      if (isNotProd()) {
        console.log(message);
      }
    }

    static error(message) {
      if (isNotProd()) {
        console.log(message);
      }
    }

    static async systemLog(title, message) {
      this.frontEndLog(title,  message)
    }

    static async frontEndLog(title, message) {
      const packagedData = {
          title,
          message
      }
      axios.post(axiosURL + '/system/logrequest', packagedData)
        .catch(error => { console.log(error) })
    }
}

function isNotProd() {
  return axiosURL() !== 'https://appointgrp.com'
}

export default Logger