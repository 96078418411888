import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { axiosURL } from '../../../axiosURL_Front'
import { notify } from '../../Notification/notify'
import capitalize from '../../../functions/capitalize'

const SettingsSelection = ({ handleSettingsSelection}) => {
    const history = useHistory()
    // If you add a new selection, you have to add it to the switch statement in the return statement below
    const selectionList = ['students', 'clinicals', 'general']

    // Default to first item in list above
    const [selection, setSelection] = useState(selectionList[0])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      axios.post(axiosURL + '/systemSetting/getOne', {name: 'disable ca settings'})
      .then(res => {
        if (res.data.success === true && res.data.data.value == 'true') {
          handleSettingsSelection('schedule')
          notify('e', 'Settings tab is currently not available, try again later')
          return
        }
      })
    }, [])

    const Title = () => {
        return(
            <label id="studentListTitle"><strong>Selection</strong></label>
        )
    }

    const handleSelection = (newSelection) => {
        setSelection(newSelection)
        handleSettingsSelection(newSelection)
    }

    const SelectionList = (props) => {
        return(
            <div className="dataResult" style={{marginTop: '10px'}}>                
                {/* Add each selection to the list. We are resusing css from SearchBar */}
                {selectionList.slice(0, selectionList.length).map((value, key) => {
                    let SelectionBackground = ''

                    if(selection === value){
                        SelectionBackground += " SearchBarStudentBackground"
                    }
                    
                    return (
                    <a className={"dataItem" + SelectionBackground} onClick={() => {handleSelection(value)}}>
                        <li key={value} className="dataItemStyling"><span className="StudentSearchBarStudentName">{capitalize(value)}</span> </li>
                    </a>
                    );
                })}                
            </div>
        )
    }

    

    return(
        <section className='settingsSelection'>
            {/* we took the css from above and I didn't change the name */}
            <Title />
            <SelectionList />
        </section>
    )
}

export default SettingsSelection;

