import { useHistory, useLocation, withRouter } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { useContext, useEffect, useState } from "react";
import Logger from "../../utils/Logger";
import UserContext from "../../context/UserContext";

function ErrorBoundaryWithHistory(props) {
  const [hasError, setHasError] = useState(false);
  const {userData} = useContext(UserContext);
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => {
    if (hasError) {
      setHasError(false);
      Logger.frontEndLog('ERROR', 'User with Error: ' + JSON.stringify(userData.user) + ', ERROR: ' + error + ', ERROR INFO: ' + errorInfo.componentStack);
      history.push('/error');
    }
  }, [location.key, hasError]);

  return (
    <ErrorBoundary hasError={hasError} onError={(error, errorInfo) => {setHasError(true); setError(error); setErrorInfo(errorInfo)}}>
      {props.children}
    </ErrorBoundary>
  );
}

export default withRouter(ErrorBoundaryWithHistory);