import { makeStyles } from "@mui/styles";
import React, { useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    fontSize: '13px',
    fontWeight: '500',
    margin: '2px 0px'
  },
  CrumbsConatiner: {
    marginRight: '5px'
  },
  Crumbs: {
    marginRight: '5px',
    color: '#484848',
    '&:hover': {
      color: '#8da8f5',
      cursor: 'pointer'
    }
  }
}));

export default function Breadcrumbs(props){
  // Everytime we make a new page we have to add it here
  const paths = ['dashboard', 'clinicalassistant', 'entity', 'studentfile', 'accounts', 'profile', 'reporting', 'settings', 'systemsettings', 'feedback', 'clinicalsites']
  const pathWithDisplayName = ['Dashboard', 'Clinical Assistant', 'Entity', 'File', 'Users', 'Profile', 'Reporting', 'Settings', 'System Settings', 'Feedback', 'Clinical Sites']

  const history = useHistory();
  const classes = useStyles();
  const [crumbs, setCrumbs] = useState([{name: '', props: ''}])

  const url = useLocation();
  let location = url

  function filterOutNames(s) {
    s = s.slice(1).split('/')
    s.filter(s => !paths.includes(s))
    return s.join('/')
  }
  useLayoutEffect(() => {
    location = location.pathname.split('/').filter(i => i !== "")

    let allCrumbs = []
    for (let i = 0; i < location.length; i++) {
      let tempCrumb = {name: '', params: '/'}
      // Previous param
      if (allCrumbs.length > 0) {
        tempCrumb.params += filterOutNames(allCrumbs[allCrumbs.length -1].params) + '/'
      }
      
      // Find the first location and make sure it isn't a parameter
      if (paths.includes(location[i].toLowerCase())) {
        tempCrumb.name = location[i]
        tempCrumb.params += location[i]
        // Loop through until we find the second one so we know what the params are
        for (let e = i+1; e < location.length; e++) {
          if (paths.includes(location[e].toLowerCase())) break

          tempCrumb.params += '/' + location[e]
        }
        allCrumbs.push(tempCrumb)
      }
    }

    setCrumbs(allCrumbs)
  }, [url])

  function renderCrumb(crumb, index) {
    let nameIndex = paths.indexOf(crumb.name)
    let slash = '/'
    if (index === crumbs.length - 1) slash = ''
    return (
    <span className={classes.CrumbsConatiner} key={crumb.name}>
      <span 
        className={classes.Crumbs} 
        onClick={() => handleNavigation(crumb.params)}
        >
        {pathWithDisplayName[nameIndex]}
      </span>
      {slash}
    </span> )
  }

  function handleNavigation(params) {
    history.push(params)
  }

  return(
    <div className={classes.Container}>
      {crumbs.map((crumb, index) => {
        return renderCrumb(crumb, index)
      })}
    </div>
  )
}


