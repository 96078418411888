import React, { useState, useEffect, useMemo } from "react";
import { config as configRS } from "react-spring";
import { Carousel } from "react-carousel-card-3d";

export default function Carousel3d({
  cards,
  offset,
  autorotation = true,
  rotationInterval = 6000,
}) {
  const [goToSlide, setGoToSlide] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    let id = null;
    if (autorotation) {
      id = setInterval(() => {
        setGoToSlide((prevState) =>
          prevState + 1 >= cards.length ? 0 : prevState + 1
        );
      }, rotationInterval);
    }
    setIntervalId(id);

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [cards.length, autorotation, rotationInterval]);

  const slides = useMemo(
    () =>
      cards.map((element, index) => ({
        ...element,
        index,
        onClick: () => {
          setGoToSlide(index);
          if (intervalId) {
            clearInterval(intervalId);
            const newIntervalId = setInterval(() => {
              setGoToSlide((prevState) =>
                prevState + 1 >= cards.length ? 0 : prevState + 1
              );
            }, rotationInterval);
            setIntervalId(newIntervalId);
          }
        },
      })),
    [cards, intervalId, rotationInterval]
  );

  return (
    <Carousel
      slides={slides}
      goToSlide={goToSlide}
      offsetRadius={offset}
      animationConfig={configRS.gentle}
    />
  );
}
