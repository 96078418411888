export default function capitalize(s){

  const dualCapitalStarters = ['Da', 'De', 'Di', 'Du', 'La', 'Le', 'Li', 'Lo', 'Mc', 'Mo',   'da', 'de', 'di', 'du', 'la', 'le', 'li', 'lo', 'mc', 'mo']
  const tripleCapitalStarters = ['Mac','Van','mac','van']

  if (typeof s !== 'string') return ''


  try{
    s = s.trim()

    let wordsArray = s.split(' ').filter(word => word != '');
    for (let i = 0; i < wordsArray.length; i++) {
        if(wordsArray[i][0] == undefined){
          wordsArray[i] = ''
          continue
        }
        if (wordsArray[i].includes("-")){
          let dashedWord = wordsArray[i].split('-')
          for (let x = 0; x < dashedWord.length; x++) {
            if(dashedWord[x] == undefined || dashedWord[x] == ''){
              dashedWord[x] = " " /// If it is showing as not existing, then it meant it was a space on either side of the dash
              continue
            }
            if(dualCapitalStarters.includes(dashedWord[x].slice(0,2))){               /// If first two are part of dual list
              dashedWord[x] = dashedWord[x].slice(0,3) + dashedWord[x].substr(3).toLowerCase()  /// Then save first three as is, lower rest
            }
            else if(tripleCapitalStarters.includes(dashedWord[x].slice(0,3))){        /// If first three are part of triple list
              dashedWord[x] = dashedWord[x].slice(0,4) + dashedWord[x].substr(4).toLowerCase()  /// Then save first four as is, lower rest
            }
            else{
              dashedWord[x] = dashedWord[x].trim().toLowerCase()
              dashedWord[x] = dashedWord[x][0].toUpperCase() + dashedWord[x].substr(1);
            }
          }
          wordsArray[i] = dashedWord.join('-')
        }
        else{ /// No '-' involved -> If there were, then this would undo any caps after the '-'
          if(dualCapitalStarters.includes(wordsArray[i].slice(0,2))){               /// If first two are part of dual list
              wordsArray[i] = wordsArray[i].slice(0,3) + wordsArray[i].substr(3).toLowerCase()  /// Then save first three as is, lower rest
          }
          else if(tripleCapitalStarters.includes(wordsArray[i].slice(0,3))){        /// If first three are part of triple list
              wordsArray[i] = wordsArray[i].slice(0,4) + wordsArray[i].substr(4).toLowerCase()  /// Then save first four as is, lower rest
          }
          else{
              wordsArray[i] = wordsArray[i].trim().toLowerCase()
              wordsArray[i] = wordsArray[i][0].toUpperCase() + wordsArray[i].substr(1);
          }
        }
        /// Final if: checking for apostrophe - Since always caps after that, can do once at the end.
        if(wordsArray[i].includes("'")){      /// If there is an apostrophe in the name, like D'Angelo
            let myIndex = wordsArray[i].indexOf("'")
            wordsArray[i] = wordsArray[i].slice(0,myIndex+1) + wordsArray[i][myIndex+1].toUpperCase() + wordsArray[i].slice(myIndex+2)
        }
    }
    /// Above fixes each word in the words array --- Now need to combine them into one and return
    return wordsArray.join(' ')
  }
  catch (error){
    console.error(error);
    console.log(error)
    return s
  }
}