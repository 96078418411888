import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormControl, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from 'react';
import PlatformStyles from '../../../PlatformStyles';
import config from '../../../config';
import { socket } from '../../../socket';

  const useStyles = makeStyles(() => ({
    NursingTopicCard: {
        borderRadius: PlatformStyles.BorderRadius,
        boxShadow: PlatformStyles.BoxShadow,
        backgroundColor: '#ffffff',
        color: 'rgb(71, 71, 71)',
        margin: '10px 10px',
        padding: '10px 10px',
        minheight: '43px',
        minWidth: '250px',
        width: '100%',
        maxWidth: '45%',
    },
    NursingTopicCardName: {
        fontWeight: 'bold',
        wordWrap: 'break-word',
        marginBottom: '4px'
    },
    EditNursingTopicBox: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        paddingTop: '14px !important',
        flexDirection: "column",
        height: '20px !important',
        padding: 4,
        borderRadius:'4px',
        "&> div.container": { 
            gap: "6px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        "& > div.container > span": {
            padding: "1px 3px",
            borderRadius: "4px",
        },
    },
    FormInput: {
        // backgroundColor: 'red',
        width: '75%',
        float: 'left',
        height: '30px',
        marginTop: '-18px',
        marginRight: '68px'
    },
    SaveIcon: {
        // backgroundColor: 'red',
        position: 'relative',
        width: '25px',
        float: 'right',
        marginTop: '-18px',
        marginRight: '3px',
        padding: '2px',
        "&:hover": {
            filter: 'invert(32%) sepia(89%) saturate(1876%) hue-rotate(109deg) brightness(99%) contrast(105%)'
          }
    },
    CancelIcon: {
        position: 'relative',
        width: '25px',
        float: 'right',
        marginTop: '-18px',
        marginRight: '3px',
        padding: '2px',
        "&:hover": {
            filter: 'invert(19%) sepia(88%) saturate(4088%) hue-rotate(353deg) brightness(106%) contrast(103%)'
          }
    },
    EditIcon: {
        width: '35px',
        padding: '3px',
        borderRadius: PlatformStyles.BorderRadius,
        float: 'right',
        "&:hover": {
            color: 'white',
            backgroundColor: '#474f7a',
            borderRadius: PlatformStyles.BorderRadius,
            transition:' background-color .3s, color .1s',   
          }
    },
    TrashCanIcon: {
        width: '35px',
        padding: '3px',
        borderRadius: PlatformStyles.BorderRadius,
        float: 'right',
        "&:hover": {
            filter: 'invert(99%) sepia(0%) saturate(196%) hue-rotate(118deg) brightness(114%) contrast(100%)',
            // backgroundColor: '#474f7a',
            borderRadius: PlatformStyles.BorderRadius,
            transition:' background-color .3s, color .1s',   
          }
    }
  }));

export default function NursingTopicCard(props) {
    const [nursingTopic, setNursingTopic] = useState(props.nursingTopic);
    const [nursingTopicEdit, setNursingTopicEdit] = useState(props.nursingTopic);
    const [fileID, PleaseDontSetTheFileIDIBeg] = useState(props.fileID);
    const [editMode, setEditMode] = useState(false)
    const classes = useStyles();

    const editNursingTopic = (nursingTopic, editedNursingTopic) => {
        const data = {
            fileID,
            nursingTopic,
            editedNursingTopic
        }
        socket.emit('editNursingTopic', data);
        setNursingTopicEdit('')
        setEditMode(false)
    }

    const deleteNursingTopic = (nursingTopic) => {
        const data = {
            fileID,
            nursingTopic,
        }

        socket.emit('deleteNursingTopic', data);
    }

    return (
      <div className={classes.NursingTopicCard}>
        {!editMode ? (
          <div className={classes.NursingTopicCardName}>
            {nursingTopic}
            <DeleteOutlined className={classes.EditIcon} onClick={() => deleteNursingTopic(nursingTopic)} />
            <EditOutlined
              className={classes.EditIcon}
              onClick={() => {
                setNursingTopicEdit(nursingTopic)
                setEditMode(true)
              }}
            />
            {/* <img className={classes.TrashCanIcon} src={config.trashCanIcon} onClick={() => editNursingTopic(nursingTopic, nursingTopicEdit)} /> */}
          </div>
        ) : (
          <div className={classes.NursingTopicCardName}>
            <FormControl classes={{ root: classes.EditNursingTopicBox }}>
              <Input
                placeholder={nursingTopic}
                type="text"
                className={classes.FormInput}
                autoComplete="off"
                value={nursingTopicEdit}
                onChange={(e) => {
                  console.log(e)
                  setNursingTopicEdit(e.target.value)
                }}
              />
            </FormControl>

            <img alt="Save" className={classes.SaveIcon} src={config.saveIcon} onClick={() => editNursingTopic(nursingTopic, nursingTopicEdit)} />
            <img
              alt="Cancel"
              className={classes.CancelIcon}
              src={config.cancelIcon}
              onClick={() => {
                setNursingTopicEdit('')
                setEditMode(false)
              }}
            />
          </div>
        )}
      </div>
    )       
}