import config from '../../config';
import { makeStyles, withStyles } from "@mui/styles";
import { Tooltip } from '@mui/material';
import PlatformStyles from '../../PlatformStyles';

const useStyles = makeStyles(() => ({
  InfoIcon: {
    width: '20px',
    height: '20px',
    padding: '3px',
    "&:hover": {
      boxShadow: PlatformStyles.BoxShadow,
      borderRadius: '20%'
    }
  },
  tos: {
    boxShadow: PlatformStyles.BoxShadow,
    borderRadius: '5px',
    width: '130px',
    "&:hover": {
      boxShadow: PlatformStyles.BoxShadow,
      borderRadius: '5px'
    }
  }
}))

const TooltipWithStyles = withStyles({
  tooltip: {
    fontSize: '13px',
  }
})(Tooltip)

export default function InformationToolTip({ children, type }) {
  const classes = useStyles();

  let src = config.informationIcon
  if (typeof type !== "undefined" && type != null && type.toLowerCase() === 'tos') {
    type = type.toLowerCase() // This is so we can use lowercase styles
    src = config.termsOfUseIcon
  }

  return (
      <TooltipWithStyles title={children} placement={'right'} >
          <img alt={children} src={src} className={`${classes.InfoIcon} ${classes[type]}`} id="informationIconCA" />
      </TooltipWithStyles>
  );
}