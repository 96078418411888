/* eslint-disable import/no-anonymous-default-export */
import {
    PayCircleOutlined,
    ShoppingCartOutlined,
    MessageOutlined,
    TeamOutlined,
    UserOutlined,
    DashboardOutlined,
    ApiOutlined,
    CameraOutlined,
    EditOutlined,
    CodeOutlined,
    LineOutlined,
    BarChartOutlined,
    AreaChartOutlined,
    ScheduleOutlined,
    ScheduleFilled,
    ScheduleTwoTone
  } from '@ant-design/icons'
import PlatformStyles from '../PlatformStyles'
  
  export default {
    'pay-circle-o': <PayCircleOutlined />,
    'shopping-cart': <ShoppingCartOutlined />,
    'camera-o': <CameraOutlined />,
    'line-chart': <LineOutlined />,
    'code-o': <CodeOutlined />,
    'area-chart': <AreaChartOutlined />,
    'bar-chart': <BarChartOutlined />,
    message: <MessageOutlined />,
    team: <TeamOutlined />,
    inProgressSchedule: <div style={{background:PlatformStyles.inProgressBackground , maxHeight:'30px', maxWidth:'44px', 
          marginTop:'10px'}}><ScheduleOutlined style={{color: PlatformStyles.LogoNavyBlue, marginTop:'-10px'}} />  </div>,
    scheduleComplete: <ScheduleFilled />,
    dashboard: <DashboardOutlined />,
    user: <UserOutlined />,
    api: <ApiOutlined />,
    edit: <EditOutlined />,
    publishedSchedule: <ScheduleTwoTone twoToneColor={PlatformStyles.PlatformGreen} />,
    closedSchedule: <ScheduleTwoTone twoToneColor={PlatformStyles.PlatformRed} />
  }
  