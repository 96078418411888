import { makeStyles } from "@mui/styles";
import { TextField } from '@mui/material'
import axios from 'axios';
import Papa from "papaparse";
import React, { createRef, useContext, useLayoutEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { axiosURL } from '../../axiosURL_Front';
import UserContext from '../../context/UserContext';
import calculateAge from '../../functions/calculateAge';
import checkLoggedIn from '../../functions/checkLoggedIn';
import convertTime from '../../functions/convertTime';
import formatPhoneNumber from '../../functions/formatPhoneNumber';
import { getAuthHeader } from '../../functions/getToken';
import PlatformStyles from '../../PlatformStyles';
import { Alert } from '../misc/Alert';
import { notify } from '../Notification/notify';
import Table from './Table';
import './Users.css';
import capitalize from '../../functions/capitalize';
import { getNA } from '../../functions/getNA';
import { notNullString } from '../../functions/notNullString';
import { isAdministrator } from '../../functions/UserTypeChecks';
import Loading from '../Loading/Loading';
import { ProfilePicture } from '../misc/ProfilePicture';
import { isInternalUser } from "../../functions/isInternalUser";
import InformationToolTip from "../misc/InformationToolTip";
import { Switch } from "@mui/material"
import { AppointBlueButton, AppointGreyButton } from "../../utils/AppointButton";

const useStyles = makeStyles(() => ({
  Input: {
      borderRadius: PlatformStyles.BorderRadius,
      border: 'none',
      width: '100%',
  },
  AddStudentMessage: {
    fontStyle: 'italic',
    marginLeft: '2px',
    marginTop: '5px'
  },
  ModalBody: {
    // marginTop: '-15px',
    marginBottom: '15px'
  },
  ErrorMessage: {
    marginTop: '20px'
  }
  
}));

export default function Users(props) {
  const history = useHistory();
  const classes = useStyles();

  const {userData, setUserData, isMobile} = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  const [addStudentsModal, setAddStudentsModal] = useState(false)
  let [studentInfo, setStudentInfo] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  let studentUpload = createRef()

  // Add Students Manual Input

  // User for importing excel file for students
  const [importErrors, setImportErrors] = useState([])
  const [importErrorsMessageBox, setImportErrorsMessageBox] = useState(false);

  useLayoutEffect(() => {
    // Displays import errrs if there are any
    if(importErrors.length > 0){
      setImportErrorsMessageBox(true)
    }
    // Makes sure the user is loggedin
    let loggedInCheck = false
    if(checkLoggedIn(localStorage.getItem('auth-token'))){ loggedInCheck = true } else { history.push('/login') }

    if(userData.user.schoolID != undefined){
      let packagedData = {
        schoolId: userData.user.schoolID
      }
      
      axios.post(axiosURL + '/users/studentsBySchoolId', packagedData)
        .then(res => {
          let students = []
          // For each student it adds the data that is needed for the row. 
          // Each row as a UserID that is not displayed and used to open their profile
          for (var i = 0; i<res.data.data.length; i++){
            let userProfileHref = res.data.data[i]._id
            console.log(res.data.data[i])
            students.push({
              profilePicture: res.data.data[i].profilePicture,
              name: res.data.data[i].firstName + ' ' + res.data.data[i].lastName,
              age:  notNullString(res.data.data[i].studentInfo.dateOfBirth) ? calculateAge(convertTime(res.data.data[i].studentInfo.dateOfBirth)) : getNA(), 
              phone: notNullString(res.data.data[i].studentInfo.phone) ? formatPhoneNumber(res.data.data[i].studentInfo.phone): getNA(), 
              email: res.data.data[i].email, 
              address: notNullString(res.data.data[i].studentInfo.address.address) ? res.data.data[i].studentInfo.address.address : getNA(), 
              userID: res.data.data[i]._id,
              rowClick: {userID: userProfileHref}
            })
          }
          setStudentInfo(students)
          // TODO: refactor to promise.all to save time
          axios.post(axiosURL + '/systemSetting/getOne', {name: 'disable users tab'})
            .then(res => {
              if (res.data.success === true && res.data.data.value == 'true') {
                history.push('/dashboard')
                notify('e', 'Users page is currently not available, try again later')
              }
              setIsLoading(false)
            })
        })
      }
    }, [isLoading, userData])


  
  // if they are a coordinator we are going to block the dashboard temporarily
  if(isMobile && isAdministrator(userData.user.accountType)) {
    return (<div className='App_MobileMessage'>While students can access their accounts on mobile, we are currently working on adapting the coordinator view to be mobile friendly. Please access the site on a laptop / desktop to continue your scheduling.</div>)
  }

  if(isLoading){
      return <Loading />
  }

  const columns = [
    {
      title: "",
      field: "profilePicture",
      filtering: false,
      width: '50px',
      render: (rowData) => {        
        return (<ProfilePicture fileName={rowData.profilePicture} size={'small'} />)
      },

    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Age",
      field: "age",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Address",
      field: "address",
      headerStyle: { },
    },
  ];

  function handleRowClick(e){
    history.push('accounts/profile/' + e.rowClick.userID)
  }

  function AddStudentModal(props) {
    let [firstNameInput, setFirstNameInput] = useState('')
    let [lastNameInput, setLastNameInput] = useState('')
    let [emailInput, setEmailInput] = useState('')
    let [addTestingData, setAddTestingData] = useState(false)

    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="addStudentsModalHeader">
            <div className="addStudentsModalHeader">
              Create Student Account
              <input type="file" ref={studentUpload} accept=".csv" onChange={(e) => handleImport(e, addTestingData)} />
              <Button className="importStudentsButton" variant="outline-primary" onClick={() => studentUpload.current.click()}>
                Import List
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ModalBody}>
            <TextField
              value={firstNameInput}
              onChange={(e) => setFirstNameInput(e.target.value)}
              style={{ marginTop: '10px' }}
              className={classes.Input}
              id="First Name"
              label="First Name"
            />

            <TextField
              value={lastNameInput}
              onChange={(e) => setLastNameInput(e.target.value)}
              style={{ marginTop: '10px' }}
              className={classes.Input}
              id="Last Name"
              label="Last Name"
            />

            <TextField
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              style={{ marginTop: '10px' }}
              className={classes.Input}
              id="Email"
              label="Email"
            />

            {isInternalUser(userData.user.schoolID) && (
              <>
                Add Testing Data
                <Switch
                  checked={addTestingData}
                  color={'primary'}
                  thumbon
                  onChange={() => setAddTestingData(!addTestingData)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <InformationToolTip>
                  This is for internal use only, when turned on, it will add testing data to all students that are added.
                </InformationToolTip>
              </>
            )}

            <div className={classes.AddStudentMessage}>All fields are required to create a new student account</div>

            {errorMessage != '' && (
              <Alert className={classes.ErrorMessage} severity="error">
                {errorMessage}
              </Alert>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>
            Cancel
          </AppointGreyButton>
          <AppointBlueButton onClick={() => addStudentSumbit(firstNameInput, lastNameInput, emailInput, addTestingData)}>
            Add Student
          </AppointBlueButton>
        </Modal.Footer>
      </Modal>
    )
  }

  function addStudentSumbit(firstName, lastName, email, addTestingData){
    // Makes sure the fields are not blank
    if (firstName.trim() === '') {
      setErrorMessage('Enter First Name')
    } else if (lastName.trim() === '') {
      setErrorMessage('Enter Last Name')
    } else if (email.trim() === '') {
      setErrorMessage('Enter Email')
    } else if(!isInternalUser(userData.user.schoolID) && addTestingData) {
      setErrorMessage('Cannot Add Testing Data')
    } else {
      const packagedData = {
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        email: email.trim().toLowerCase(),
        schoolID: userData.user.schoolID,
        addTestingData,
        type: 'manual'
      }
  
      // Email validation is done in the backend
      axios.post(axiosURL + '/users/RegisterStudent', packagedData, getAuthHeader())
        .then(res => {
          if (res.data.success === false){
            setErrorMessage(res.data.message)
          } else {
            window.location.reload()
          }
        })
    }
  }

  function handleImport(e, addTestingData){

    if (e.target.files[0].name.split('.').pop() != 'csv') {
      notify('e', 'File format must be CSV')
      return
    }
    
    Papa.parse(e.target.files[0], {
      complete: function(results) {

        const packagedData = {
          data: results,
          schoolID: userData.user.schoolID,
          type: 'import',
          addTestingData
        }

        console.log(packagedData)
        notify('i', 'Creating Accounts - One Moment')
        axios.post(axiosURL + '/users/RegisterStudent', packagedData, getAuthHeader())
        .then(res => {
          console.log(res.data)
          // If there are errors it prompts the user what they are
          if (res.data.success == false) {
            setAddStudentsModal(false)
            setImportErrors(res.data.errors)
            setImportErrorsMessageBox(true)
            
          } else {
            console.log(res.data)
            notify('s', res.data.message)
            setTimeout(() => {
              window.location.reload()
            }, 1000);
            
            
          }
        })
        
      }}
    )

    // var excelFile = e.target.files
    // console.log('excelFile')
    // console.log(excelFile)
    // let reader = new FileReader()
    // reader.readAsDataURL(excelFile[0])
    // reader.onload = (e) => {
    //   var data = e.target.result
    //   var workbook = XLSX.read(data);            
    //   var sheet_name_list = workbook.SheetNames;
    //   var jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], {defval: ''}) // Defval allows us to import blank cells

    //   const packagedData = {
    //     data: jsonData,
    //     schoolName: userData.user.schoolName,
    //     schoolID: userData.user.schoolID,
    //     type: 'import'
    //   }

    //   console.log(packagedData)
    //   axios.post(axiosURL + '/users/RegisterStudent', packagedData, getAuthHeader())
    //     .then(res => {
    //       console.log(res.data)
    //       // If there are errors it prompts the user what they are
    //       if (res.data.errors.length != 0) {
    //         setAddStudentsModal(false)
    //         setImportErrors(res.data.errors)
    //         setImportErrorsMessageBox(true)
            
    //       } else {
    //         console.log(res.data)
    //         // window.location.reload()
    //       }
    //     })
    // }
  }

  function handleHide(){
    setAddStudentsModal(false)
    setErrorMessage('')
  }

  function ImportErrorsMessageBox(props) {
    // Creates row based on what type of error is present
    const rowCreation = (row) => {
      return (
        <div className='importUsersErrorMessageRow'>
          <span className='importUsersErrorMessage'>{row.msg}</span>
        </div>
      )}
    
    // If there are more than 20 errors found, it prompts the user to check that they imported the right file (20 is random number)
    const excessiveErrors = () =>{
      if (importErrors.length >= 10){
          return (<Alert severity='error' id='importErrorDefaultMsg'>Please ensure that the correct file was imported</Alert>)
      }
    }

    return (
      <Modal
        {...props}
        backdrop='static'
        keyboard='false'
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="UsersImportErrorMessageBox"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          Import Errors
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='importErrorModalBody'>
                <Alert severity='error' id='importErrorDefaultMsg'>Please read and correct the following errors before importing again. For assitance with importing data, please contact us or use the help button below.</Alert>
                {excessiveErrors()}
                {importErrors.map((error) => (
                    rowCreation(error)
                ))}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <AppointBlueButton onClick={props.onHelp}>Help with Importing Data</AppointBlueButton>
            <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
        </Modal.Footer>
      </Modal>
    );
  }

  return(
    <div className='main'>
      <AddStudentModal
        show={addStudentsModal}
        onHide={handleHide}
      />

      <ImportErrorsMessageBox
        show={importErrorsMessageBox}
        onHide={() => {setImportErrorsMessageBox(false)}}
        onHelp={() => {setImportErrorsMessageBox(false)}}
      />

      <Table data={studentInfo} columns={columns} type='userDetails' handleRowClick={handleRowClick} handleAddStudents={() => setAddStudentsModal(true)}/>
      
    </div>
  )
}
