import React from 'react';
import { useDropzone } from 'react-dropzone';
import config from '../../config';
import { notNullString } from '../../functions/notNullString';
import './Dropzone.css'

const Dropzone = ({ onDrop, title }) => {
    //gRP= this is for height&width of the dropzone's area
    //gIP= for click&dragging
    //iDA= to check if a file is hovering over the dropzone

    const { isDragActive, getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        
        <div className={isDragActive ? "dropzone dropzone-hover" : "dropzone"} {...getRootProps()}>
          <input className="dropzone-input" accept='.csv' {...getInputProps()} />
          <div className="text-center">
            {isDragActive ? (
              <p className="dropzone-content">&nbsp;</p>
            ) : (
              <p className="dropzone-content"> 
              <i class="upload icon"></i>
              
                {notNullString(title) ? 
                    <>{title}</>
                    :
                    <>
                    <div>
                        Drop files here, or click to select file
                    </div>
                    <img style={{height: '30px', marginTop: '20px'}} src={config.fileUploadIcon} />
                    </>
                } </p>
            )}
          </div>
        </div>
      );
};

export default Dropzone;