import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import PlatformStyles from '../../PlatformStyles'
import config from '../../config'
import { AppointYellowButton } from '../../utils/AppointButton'

const useStyles = makeStyles(() => ({
  DirectionsComponent: {
    border: 'none',
    boxShadow: PlatformStyles.BoxShadow,
    borderRadius: PlatformStyles.BorderRadius,
  },
}))

export default function DeleteConfirmation({ style, onClick }) {
  const classes = useStyles()
  const [showConfirmation, setShowConfirmation] = useState(false)

  useEffect(() => {
    if (showConfirmation) {
      const listener = (event) => {
        if (!event.target.contains(document.getElementById('yellow-confirmation-button-id')) || event.code === 'Escape') {
          setShowConfirmation(false)
        }
      }

      window.addEventListener('mousedown', listener)
      window.addEventListener('keydown', listener)
      return () => {
        window.removeEventListener('mousedown', listener)
        window.removeEventListener('keydown', listener)
      }
    }
  }, [showConfirmation])

  return (
    <span style={style}>
      {!showConfirmation ? (
        <img
          onClick={() => {
            setShowConfirmation(true)
          }}
          alt="Remove Student"
          src={config.trashCanIcon}
          className="clinicalTableIcon trashCanIcon"
        />
      ) : (
        <AppointYellowButton
          id="yellow-confirmation-button-id"
          style={{ minWidth: '0px', fontWeight: 'bold' }}
          onClick={() => {
            setShowConfirmation(false)
            onClick()
          }}
        >
          Confirm
        </AppointYellowButton>
      )}
    </span>
  )
}
