import { makeStyles } from "@mui/styles";
import { Switch, TextField } from '@mui/material';
import axios from 'axios';
import Image from 'material-ui-image';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { axiosURL } from '../../axiosURL_Front';
import config from '../../config';
import UserContext from '../../context/UserContext';
import belongsToAppointEntity from '../../functions/belongsToAppointEntity';
import calculateAge from '../../functions/calculateAge';
import loggedIn from '../../functions/checkLoggedIn';
import copyToClipboard from '../../functions/copyToClipboard';
import { convertEntityType, isSchool } from '../../functions/entityTypeHelpers';
import formatPhoneNumber from '../../functions/formatPhoneNumber';
import { getNA } from '../../functions/getNA';
import { getAuthHeader } from '../../functions/getToken';
import { notNullString } from '../../functions/notNullString';
import PlatformStyles from '../../PlatformStyles';
import { AppointBlueButton, AppointGreyButton } from '../../utils/AppointButton';
import Loading from '../Loading/Loading';
import AddressAutocomplete from '../misc/AddressAutoComplete';
import { Alert } from '../misc/Alert';
import { AdministratorFilePills, DeletedPills } from '../misc/FilePills';
import { ProfilePicture } from '../misc/ProfilePicture';
import { notify } from '../Notification/notify';
import Table from '../Users/Table';
import DeleteConfirmation from "../misc/DeleteConfirmation";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import convertTime from '../../functions/convertTime'

export default function Entity(props){
  const history = useHistory();
  /* Sets user context */
  const {userData, setUserData} = useContext(UserContext)
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [fileData, setFileData] = useState([])
  const [administratorData, setAdministratorData] = useState([])
  const [studentData, setStudentData] = useState([])
  const [entityData, setEntityData] = useState({name: '', id: ''})
  const [linkedSiteData, setLinkedSiteData] = useState([])
  const { id } = useParams() // Passed in through URL
  const [editEntityModal, setEditEntityModal] = useState(false)
  const [addAdministratorModal, setAddAdministratorModal] = useState(false)
  const [addLinkedSiteModal, setAddLinkedSiteModal] = useState(false)
  const [checklist, setChecklist] = useState([])
  const [checklistModal, setChecklistModal] = useState(false)
  const [checklistModalContext, setChecklistModalContext] = useState(null)
  
  
  // TODO: THis is rendering three times due to us using three requests 
  // in one hook. Need to refactor and chain them or make them async await
  useEffect(() =>{
    // Checks that the user is logged in. If not, pushes them to the login page
    if(!loggedIn(localStorage.getItem("auth-token"))){
      history.push('/login')
      return
    }

    // Page should only be accessible to admins
    if (!belongsToAppointEntity(userData.user.schoolID)) {
      history.push('/dashboard')
      return
    }

    const packagedData = {
      schoolId: id
    }

    // This page was originally only made for school entities. However, even if a hospital entity
    // is loaded, requests it doesn't user won't return anything and won't effect the functionality of what we are doing
    const request1 = axios.post(axiosURL + '/users/administratorsBySchoolId', packagedData)
    const request2 = axios.post(axiosURL + '/files/allFilesBySchoolId', packagedData)
    const request3 = axios.post(axiosURL + '/users/studentsBySchoolId', packagedData)
    const request4 = axios.post(axiosURL + '/entity/getEntity', packagedData, getAuthHeader())
    const request5 = axios.post(axiosURL + '/entity/getLinkedSites', packagedData, getAuthHeader())
    const request6 = axios.post(axiosURL + '/entity/getCheckList', packagedData, getAuthHeader())

    // This is a way to make multiple requests and wait for them all to come back in order to proceed
    Promise.all([request1, request2, request3, request4, request5, request6]).then((responses) => {
      const adminRequest = responses[0]
      const fileRequest = responses[1]
      const studentRequest = responses[2]
      const entityRequest = responses[3]
      const linkedSiteRequest = responses[4]
      const checklistRequest = responses[5]

      if (!adminRequest.data.success) {
        notify('e', adminRequest.data.message)
      }

      if (!fileRequest.data.success) {
        notify('e', fileRequest.data.message)
      }

      if (!studentRequest.data.success) {
        notify('e', studentRequest.data.message)
      }

      if (!linkedSiteRequest.data.success) {
        notify('e', linkedSiteRequest.data.message)
      }
      
      if (!checklistRequest.data.success) {
        notify('e', checklistRequest.data.message)
      }

      // --------- Admin Request ---------
      let administratorData = []
      for (let administrator of adminRequest.data.data ) {
        administratorData.push({
          name: administrator.firstName + ' ' + administrator.lastName,
          username: administrator.username,
          email: administrator.email,
          active: administrator.active,
          userID: administrator._id,
          rowClick: { id: administrator._id },
        })
      }

      setAdministratorData(administratorData)

      // --------- File Request ---------
      let fileData = []
      for (let file of fileRequest.data.data ) {
        fileData.push({
          fileName: file.fileName,
          fileType: file.fileType,
          status: [file.published, file.closed],
          deleted: file.deleted,
          optimizing: file.optimizing,
          createdAt: file.createdAt,
          id: file._id,
          rowClick: { id: file._id }
        })
      }
      setFileData(fileData)

      // --------- Student Request ---------
      let studentData = []
      for (let student of studentRequest.data.data){
        console.log(student)
        studentData.push({
          avatar: <div style={{height: '40px', width: '40px',  display: 'block', marginLeft: 'auto', marginRight: 'auto'}}><Image src={config.deafaultUserPath} animationDuration='0' color='transparent' imageStyle={{width: '40px', height: '40px'}} /></div>, 
          name: student.firstName + ' ' + student.lastName,
          age:  notNullString(student.studentInfo.dateOfBirth) ? calculateAge(student.studentInfo.dateOfBirth) : getNA(), 
          phone: notNullString(student.studentInfo.phone) ? formatPhoneNumber(student.studentInfo.phone): getNA(), 
          email: student.email, 
          address: notNullString(student.studentInfo.address.address) ? student.studentInfo.address.address : getNA(), 
          active: student.active,
          userID: student._id,
          profilePicture: student.profilePicture,
          rowClick: {id: student._id}
        })
        
      }
      setStudentData(studentData)

      // --------- Entity Request ---------
      setEntityData(entityRequest.data.data)

      // --------- Linked Site Request ---------
      setLinkedSiteData(linkedSiteRequest.data.data)

      // --------- Checklist Request ---------
      setChecklist(checklistRequest.data.data) 
      
      // Now load the page
      setIsLoading(false)
    })
          
  }, [isLoading, userData, history, id])

  if(isLoading){
    return <Loading />
  }
  
  function ChangeEntityNameModal(props) {
    const [name, setName] = useState(props.name)    
    const [address, setAddress] = useState(props.address)
    const [errorMessage, setErrorMessage] = useState('')

    function editEntity() {
      setErrorMessage('')

      const packagedData = {
        name,
        address,
        id: entityData.id
      }

      axios.post(axiosURL + '/entity/editEntity', packagedData, getAuthHeader())
        .then(res => {
          if (res.data.success) {
            notify(res.data.message)
            setEntityData({...entityData, name: res.data.data.name, address: res.data.data.address})
            setEditEntityModal(false)
          } else {
            setErrorMessage(res.data.message)
          }
        })   
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='addStudentsModalHeader'>
            <div className='addStudentsModalHeader'>
              Edit Entity
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ModalBody}>
            <TextField 
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{marginTop:'10px'}}
                className={classes.Input}
                required
                id="New Entity Name"
                label="New Entity Name" 
              />

            <AddressAutocomplete value={address} style={{marginTop:'10px'}} onChange={setAddress} />

            {errorMessage !== '' &&
              <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>  
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
          <AppointBlueButton onClick={editEntity}>Save</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function linkSite(schoolId, siteId, action) {
    console.log(schoolId, siteId, action)
    const packagedData = {
      schoolId,
      siteId,
      action
    }

    axios.post(axiosURL + '/entity/linkClinicalSite', packagedData, getAuthHeader())
      .then(res => {
        if (res.data.success) {
          setLinkedSiteData(res.data.data)
          setAddLinkedSiteModal(false)
          notify('s', res.data.message)
        } else {
          notify('e', res.data.message)
        }
      })   
  }

  function AddLinkedSiteModal(props) {
    const [entityId, setEntityId] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='addStudentsModalHeader'>
            <div className='addStudentsModalHeader'>
              Link Clinical Site
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ModalBody}>
            <TextField 
              value={entityId}
              onChange={(e) => setEntityId(e.target.value)}
              style={{marginTop:'10px'}}
              className={classes.Input}
              type='number'
              id="Entity ID"
              label="Entity ID" 
            />
            
            {errorMessage !== '' &&
              <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>  
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
          <AppointBlueButton onClick={() => linkSite(entityData.id, entityId, 'add')}>Link</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function deleteChecklistItem(entityId, itemId) {

    const packagedData = {
      entityId,
      itemId
    }

    axios.post(axiosURL + '/entity/DeleteChecklistItemRequest', packagedData, getAuthHeader())
      .then(res => {
        console.log(res)
        if (!res.data.success) {
          notify('e', res.data.message)
          return
        }
        setChecklist(res.data.data)
        setChecklistModal(false)
        setChecklistModalContext(null)
      })   
  }


  function ChecklistModal(props) {
    const type ={
      ADD: 'add',
      EDIT: 'edit'
    }
    const [value, setValue] = useState(props.checklistContext === null ? '': props.checklistContext.value)
    const [startDate, setStartDate] = useState(props.checklistContext === null ? null: props.checklistContext.startDate)
    const [endDate, setEndDate] = useState(props.checklistContext === null ? null: props.checklistContext.endDate)
    const [itemId] = useState(props.checklistContext === null ? null: props.checklistContext._id)
    const [modalType] = useState(props.checklistContext === null ? type.ADD: type.EDIT)
    const [errorMessage, setErrorMessage] = useState('')
    const [acknowledged, setAcknowledged] = useState(props.checklistContext === null ? true: false)

    function CreateorEdit() {
      setErrorMessage('')

      if (!acknowledged) {
        setErrorMessage('Must acknolwedge the use case for editing a checklist item')
        return
      }

      const packagedData = {
        entityId: id,
        value,
        startDate,
        endDate,
        itemId
      }

      axios.post(axiosURL + '/entity/CreateOrEditChecklistItemRequest', packagedData, getAuthHeader())
        .then(res => {
          if (!res.data.success) {
            setErrorMessage(res.data.message)
            return
          }
          setChecklist(res.data.data)
          setChecklistModal(false)
          setChecklistModalContext(null)
        })   
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='addStudentsModalHeader'>
            <div className='addStudentsModalHeader'>
              {modalType === type.ADD ?
                <>Create </>
                :
                <>Edit </>
              }
              Checklist Item
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ModalBody}>
            {modalType === type.EDIT &&
              <> 
                IMPORTANT: Editing a checklist item will edit it for historical data. This should not be used to retire an old checklist item and add a new one. PLEASE AVOID EDITING START DATE.
                <br/>
                Acknowledge
                <Switch
                  checked={acknowledged}
                  onChange={() => {setAcknowledged(!acknowledged)}}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </>
            }

            <TextField 
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{marginTop:'10px'}}
              className={classes.Input}
              id="value"
              label="Value" 
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="Start Date"
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                className={classes.Input}
                renderInput={(params) =>
                  <TextField 
                    {...params}
                    variant='outlined'
                    className={classes.Input}
                    style={{marginTop:'10px'}}
                    required
                  />
                }
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="End Date"
                label="End Date"
                disabled={props.checklistContext === null}
                value={endDate}
                onChange={setEndDate}
                className={classes.Input}
                renderInput={(params) => 
                  <TextField 
                    {...params}
                    variant='outlined'
                    className={classes.Input}
                    style={{marginTop:'10px'}}
                  />
                }
              />
            </LocalizationProvider>

            {errorMessage !== '' &&
              <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>  
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
          <AppointBlueButton onClick={CreateorEdit}>Submit</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function AddAdministratorModal(props) {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    function createAccount() {
      setErrorMessage('')

      const packagedData = {
        email,
        firstName,
        lastName,
        schoolID: entityData.id
      }

      axios.post(axiosURL + '/users/registerAdministrator', packagedData, getAuthHeader())
        .then(res => {
          if (res.data.success) {
            notify('s', res.data.message)
            const temp = {
              name: res.data.data.firstName + ' ' + res.data.data.lastName,
              username: res.data.data.username,
              email: res.data.data.email,
              active: res.data.data.active,
              userID: res.data.data._id,
              rowClick: { id: res.data.data._id },
            }
            setAdministratorData(administratorData.concat(temp))
            setAddAdministratorModal(false)
          } else {
            setErrorMessage(res.data.message)
          }
        })
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='addStudentsModalHeader'>
            <div className='addStudentsModalHeader'>
              Create administrator account
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ModalBody}>
            <TextField 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{marginTop:'10px'}}
              className={classes.Input}
              id="email"
              label="Email" 
            />
            <TextField 
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{marginTop:'10px'}}
              className={classes.Input}
              id="first name"
              label="First Name" 
            />
            <TextField 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{marginTop:'10px'}}
              className={classes.Input}
              id="last name"
              label="Last Name" 
            />
            

            {errorMessage !== '' &&
              <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>  
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
          <AppointBlueButton onClick={createAccount}>Create Account</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function handleFileClick(e){
    history.push('/dashboard/entity/' + id +'/clinicalassistant/' + e.id);   
  }

  function handleAdministratorClick(e){
    // Implement when making admin profiles
  }

  function handleStudentClick(e){
    history.push('/dashboard/entity/' + entityData.id + '/profile/' + e.userID);
  }

  function handleAddAdminstrator() {
    setAddAdministratorModal(true)
  }

  function copyPlaceId() {
    if (entityData.address.placeId) {
      copyToClipboard(entityData.address.placeId)
        .then(succ =>    {
          notify('s', 'Place ID copied')
        })
        .catch(err => {
          console.log(err)
          notify('e', 'Failed to copy Place ID')
        })
    }
  }

  function handleLinkSite() {
    setAddLinkedSiteModal(true)
  }

  function removeLinkedSite(id) {
    linkSite(entityData.id, id, 'remove')
  }

  const clinicalSiteColumns = [
    {
      title: "Entity Name",
      field: "name"
    },
    {
      title: "Entity Type",
      render: (d) => {
        return convertEntityType(d.type)
      }
    },
    {
        title: "Entity ID",
        field: "id",
    },
    {
      title: "Address",
      field: "address.address"
    },
    {
      title: "",
      render: (rowData) => (
        <img onClick={() => {removeLinkedSite(rowData.id)}} alt="Remove Linked Site" src={config.trashCanIcon} className="clinicalTableIcon trashCanIcon" />          
      ),
      width: '20%',
      filtering: false
    }
  ];

  const checkListColumns = [
    {
      title: "Value",
      field: "value"
    },
    {
      title: "Start Date",
      field: "startDate",
      render: rowData => {
        return <span>{convertTime(rowData.startDate)}</span>
      }
    },
    {
      title: "End Date",
      field: "endDate",
      render: rowData => {
        return <span>{convertTime(rowData.endDate)}</span>
      }
    },
    {
      title: "",
      render: (rowData) => (
        <img onClick={() => {setChecklistModalContext(rowData); setChecklistModal(true)}} alt='Edit Clinical' title='Edit Clinical' src={config.editIcon} className='clinicalTableIcon editIcon' />
      ),
      width: '20%',
      filtering: false
    },
  ];

  function addCheckListItem(e) {
    setChecklistModal(true)
  }

  return(
    <div className='main'>

      <ChangeEntityNameModal
          name={entityData.name}
          address={entityData.address}
          show={editEntityModal}
          onHide={() => {setEditEntityModal(false)}}
      />

      <AddAdministratorModal
          show={addAdministratorModal}
          onHide={() => {setAddAdministratorModal(false)}}
      />

      <ChecklistModal
          show={checklistModal}
          checklistContext={checklistModalContext}
          onHide={() => {
            setChecklistModalContext(null)
            setChecklistModal(false)
          }}
      />

      <AddLinkedSiteModal
          show={addLinkedSiteModal}
          onHide={() => {setAddLinkedSiteModal(false)}}
      />
     
      <div className='random' style={{padding: '10px', display: 'flex'}}>
        <div>
          <div>Entity Name: {entityData.name}</div>
          <div>Entity Id: {entityData.id}</div>
          <div>Entity Address: {entityData.address.address}</div>
        </div>
          <AppointBlueButton style={{marginLeft: 'auto'}} onClick={copyPlaceId}>
            Copy Place Id
          </AppointBlueButton>
          <AppointBlueButton style={{marginLeft: '10px'}} onClick={() => setEditEntityModal(true)}>
            Edit Entity
          </AppointBlueButton>
        <br/>
      </div>

      {isSchool(entityData.type) ?
      <>
        <Table data={fileData} columns={fileColumns} type='superUserFiles' handleRowClick={handleFileClick} />
        <Table data={administratorData} columns={administratorColumns} handleAddAdminstrator={handleAddAdminstrator} type='superUserAdministrators' handleRowClick={handleAdministratorClick} />
        &nbsp;
        <Table data={studentData} columns={studentColumns} type='userDetails' handleRowClick={handleStudentClick} />
        <Table data={linkedSiteData} columns={clinicalSiteColumns} title='Linked Clinical Sites' type='clinicalSites' handleLinkSite={handleLinkSite} />
      </>
      :
      <>
        <Table data={checklist} style={{marginTop: '10px'}} columns={checkListColumns} title='Checklist Items' addFunctionToolTip='Add Item' type='general' handleAdd={addCheckListItem} handleRowClick={() => {}} />
      </>}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  Input: {
    borderRadius: PlatformStyles.BorderRadius,
    border: 'none',
    width: '100%',
  },
  AddStudentMessage: {
    fontStyle: 'italic',
    marginLeft: '2px',
    marginTop: '5px'
  },
  ModalBody: {
    marginTop: '-15px',
    marginBottom: '15px'
  },
  ErrorMessage: {
    marginTop: '20px'
  }
}));

// Put at bottom just to make main file easier to read
const fileColumns = [
  {
    title: "File Name",
    field: "fileName",
  },
  {
    title: "File Type",
    field: "fileType",
    width: '150px'
  },
  {
    title: "Status",
    field: 'status',
    render: (rowData) => {
      return <>{AdministratorFilePills(rowData.status[0], rowData.status[1])}</>      
    },
    width: '150px',
  },
  {
    title: "Deleted",
    field: "deleted",
    width: '150px',
    render: (rowData) => {
      return <div>{DeletedPills(rowData.deleted)}</div>      
    }
  },
  {
    title: "Optimizing",
    field: "optimizing",
    width: '150px'
  },
  {
    title: "Created At",
    field: "createdAt",
    width: '150px'
  },
  {
    title: "File Id",
    field: "id",
  },
];

const administratorColumns = [
  {
    title: "Name",
    field: "name",  
  },
  {
    title: "Username",
    field: "username",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Active",
    field: "active",
  },
  {
    title: "User ID",
    field: "userID",
  },
];

const studentColumns = [
  {
    title: "",
    field: "avatar",
    filtering: false,
    width: '100px',
    render: (rowData) => {        
      return (<ProfilePicture fileName={rowData.profilePicture} size={'small'} />)
    },
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Age",
    field: "age",
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Address",
    field: "address",
    headerStyle: { },
  },
  {
    title: "Active",
    field: "active",
  },
  {
    title: "User ID",
    field: "userID",
  },
];