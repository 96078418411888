// Times have to be passed in as seconds
export function conflictCheckHelper(EventOneStart, EventOneEnd, EventTwoStart, EventTwoEnd){
  return conflictCheckHelperWithBuffer(EventOneStart, EventOneEnd, EventTwoStart, EventTwoEnd, 0)
}

export function conflictCheckHelperWithBuffer(EventOneStart, EventOneEnd, EventTwoStart, EventTwoEnd, BufferTime) {
  // Normally, we would not have to break all of the variables up like this, however I did this for a rare fringe case where the class or clinical would run past midnight. This checks for that and makes appropriate adjustments.

  if (EventOneEnd < EventOneStart){
    EventOneEnd += 86400
  }

  if (EventTwoEnd < EventTwoStart){
      EventTwoEnd += 86400
  }

  // We can just make on event larger and that will server as the buffer time and throw a conflict if that event is now within that buffer time
  EventOneStart -= parseInt(BufferTime)
  EventOneEnd += parseInt(BufferTime)

  // Explanation below. Returns true if there is a conflict and false if there is no conflict
  if ((EventOneStart <= EventTwoEnd && EventTwoEnd <= EventOneEnd) || (EventTwoStart <= EventOneEnd && EventOneEnd <= EventTwoEnd)){
    return true
  } else {
    return false
  }  
}

/*
  Outcomes of how times could work out:

    EventOneStart -> EventOneEnd -> EventTwoStart -> EventTwoEnd (No Conflict)
    EventOneStart -> EventTwoStart -> EventOneEnd -> EventTwoEnd (Conflict)
    EventTwoStart -> EventOneStart -> EventOneEnd -> EventTwoEnd (Conflict)
    EventTwoStart -> EventOneStart -> EventTwoEnd -> EventOneEnd (Conflict)
    EventTwoStart -> EventTwoEnd -> EventOneStart -> EventOneEnd (No Conflict)

  The middle three outcomes are when we have conflicts and we need to handle them.
  The two evaluations below will catch the 2nd, 3rd, and 4th outcomes (Conflict) and not the 1st and 5th (No conflict).
  They evaluate to TRUE when there is a conflict and FALSE when no conflict.

    EventOneStart <= EventTwoEnd && EventTwoEnd <= EventOneEnd (Catches 4th an 5th outcome)
    EventTwoStart <= EventOneEnd && EventOneEnd <= EventTwoEnd (Catches 2nd outcome)

*/

// Added one more conditional becuase the above return true if the start of event one equals event two
// While I think this is incorrect behavior, didn't want to modify before
// Looking at where else it could have effects
export function conflictCheckHelperForCalendar(EventOneStart, EventOneEnd, EventTwoStart, EventTwoEnd, ){
  // Normally, we would not have to break all of the variables up like this, however I did this for a rare fringe case where the class or clinical would run past midnight. This checks for that and makes appropriate adjustments.

  if (EventOneEnd < EventOneStart){
    EventOneEnd += 86400
  }

  if (EventTwoEnd < EventTwoStart){
      EventTwoEnd += 86400
  }

  // Explanation below. Returns true if there is a conflict and false if there is no conflict
  if (EventOneStart === EventTwoEnd || EventTwoStart === EventOneEnd) {
    return false
  } else if ((EventOneStart <= EventTwoEnd && EventTwoEnd <= EventOneEnd) || (EventTwoStart <= EventOneEnd && EventOneEnd <= EventTwoEnd)){
    return true
  } else {
    return false
  } 
}