import Papa from "papaparse";
import React, { useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react';
// import  { Transition, Animate } from 'react-spring/renderprops'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { makeStyles } from "@mui/styles";
import { Switch } from "@mui/material";
import { TextField } from '@mui/material';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSpring } from "react-spring";
import { useTable } from "react-table";
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import axiosHeader from '../../axiosHeader';
import { axiosURL } from '../../axiosURL_Front';
import config from '../../config';
import UserContext from '../../context/UserContext';
import calculateAge from "../../functions/calculateAge";
import getAccessLevel from "../../functions/getAccessLevel";
import { getNA } from "../../functions/getNA";
import { notNullString } from "../../functions/notNullString";
import PlatformStyles from "../../PlatformStyles";
import { socket } from '../../socket';
import Loading from "../Loading/Loading";
import { conflictCheckHelper, conflictCheckHelperWithBuffer } from '../misc/conflictCheckHelper';
import { AdministratorFilePills, DeletedPills } from '../misc/FilePills';
import { ProfilePicture } from "../misc/ProfilePicture";
import { secondsToTime } from "../misc/secondsToTime";
import { notify } from "../Notification/notify";
import './ClinicalAssistant.css';
import SearchBar from './SearchBar/SearchBar';
import "./SearchBar/SearchBar.css";
import "../misc/ModalStyles.css";
import SettingsArea from './Settings/SettingsArea';
import SettingsSelection from './Settings/SettingsSelection';
import belongsToAppointEntity from "../../functions/belongsToAppointEntity";
import WeeklyCalendar from "./WeeklyCalendar";
import { listToString } from "../misc/listToString";
import InformationToolTip from "../misc/InformationToolTip";
import { normalizeTime } from "../../functions/normalizeTime";
import DirectionsComponent from "../misc/DirectionsComponent";
import { getAuthHeader } from "../../functions/getToken";
import { AppointBlueButton, AppointGreyButton, AppointRedButton } from "../../utils/AppointButton";
import openInGoogleMaps from "../../functions/openInGoogleMaps";
import { Alert } from "../misc/Alert";
import isNullorUndefined from "../../functions/isNullOrUndefined";


// Saves all data
function saveData(fileID, fileName, student, clinical) {
    socket.emit('saveData', fileID, fileName, student, clinical)
}

function handleRemove(e) {
    socket.emit('RemoveStudentRequest', e)
}

const ClinicalAssistant = (props) => {
    const useStyles = makeStyles(() => ({
        ClearAllButton: {
            backgroundColor: 'white !important',
            border: 'none',
            borderRadius: '5px !important',
            boxShadow: PlatformStyles.BoxShadow,
            fontSize: '10px',
            height: '20px',
            position: 'absolute',
            float: 'right',
            right: '0',
            bottom: '0',
            padding: '0px 15px',
            marginBottom: '5px',
            "&:hover": {
                backgroundColor: '#f7f7f7 !important',
            }
        },
        FileNameBox: {
            margin: '0px 0px 30px 0px !important',
            width: '100%',
            height: '100%'
        },
        LockIcon: {
          padding: '5px',
          borderRadius: PlatformStyles.BorderRadius,
          '&:hover': {
            boxShadow: PlatformStyles.BoxShadow,
          }
        }
      }));

    // A lot of these state variable have defaults that seem random
    // The reason for this is that on initial render, variables that
    // are not set will throw undefined errors. I give 
    // them a dummy variable to stop everything from crashing
    
    // User Data that is Global
    const {userData, setUserData} = useContext(UserContext)

    const { id } = useParams() // Passed in through URL
    let [fileIdFromUrl] = useState(id)
    const history = useHistory();
    const classes = useStyles();

    // Used to make sure things load correctly
    const [isLoading, setLoading] = useState(true);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0)

    // Data retrieved by fileID
    const [file, setFile] = useState({students: [], optimizing: false, importErrors: [], published: false});

    const [schoolID, setSchoolID] = useState('')

    // Data that clinicals table takes in
    const [highlightRow, setHighLightRow] = useState({original: {remainingSeats: undefined}})

    // Add Clinical Message Box
    const [addClinicalMessageBox, setAddClinicalMessageBox] = useState(false);
    
    // Optimization
    const [optimizationMessageBox, setOptimizationMessageBox] = useState(false);
    const [confirmOptimizationMessageBox, setConfirmOptimizationMessageBox] = useState(false);
    
    // Import Errors
    const [importErrorsMessageBox, setImportErrorsMessageBox] = useState(false);

    const [showStudentInfoMessageBox, setShowStudentInfoMessageBox] = useState(false);
    
    // General Error Message Box
    const [generalErrorMessageBox, setGeneralErrorMessageBox] = useState(false);
    const [generalError, setGeneralError] = useState({title: '', body: ''});
    const [showOverride, setShowOverride] = useState(false);

    // Export Data Message Box
    const [exportDataMessageBox, setExportDataMessageBox] = useState(false);
    const [editWindow, setEditWindow] = useState(false);

    // Time Conflict Message Box
    const [timeConflictMessageBox, setTimeConflictMessageBox] = useState(false);
    const [timeConflict, setTimeConflict] = useState({title: '', body: ''});

    // Clinical Mesasge Box
    const [openBlockedTimeInfoMessageBox, setOpenBlockedTimeInfoMessageBox] = useState(false);
    const [clinicalInfo, setClinicalInfo] = useState(null);

    const defaultLinkedSite = new Map()
    defaultLinkedSite.set('', {checklist: [], address: null, placeId: null})
    const [linkedClinicalSiteEntities, setLinkedClinicalSiteEntities] = useState(defaultLinkedSite)
  
    // General
    const selectedStudentDefaultValue = {firstName: '', lastName: '', email: '', classYear: '', studentObjectID: '', classesList: [], nursingTopics: [], studentClinicalList: [], blockedTimeList: [], circumstance: '', userID: '', address: {address: null, placeId: null}}
    let [selectedStudent, setSelectedStudent] = useState(selectedStudentDefaultValue);
    let [selectedBlockedTime, setSelectedBlockedTime] = useState({summary: '', startTime: 0, endTime: 0, weekDays: [], reason: ''});
    let [selectedClinical, setSelectedClinical] = useState('');

    let [filterWeekDayValue, setFilterWeekDayValue] = useState([]);
    let [filterClinicalTypeValue, setFilterClinicalTypeValue] = useState([]);
    let [filterLocationValue, setFilterLocationValue] = useState([]);
    const [showFullClinicals, setShowFullClinicals] = useState(true);
    const animatedComponents = makeAnimated(); // Needed for dropdowns

    const[scheduleBoolean, setScheduleBoolean] = useState(true)
    const[settingsBoolean, setSettingsBoolean] = useState(false)

    const bottomBorderColor = 'solid 2px #474f7a'
    const[scheduleBorder, setScheduleBorder] = useState(bottomBorderColor)
    const[settingsBorder, setSettingsBorder] = useState('')

    // THIS IS THE FIRST SELECTION IN THE SETTINGS SELECTION FILE AND SHOULDN'T BE CHANGED
    let settingsSelectionDefault = 'students'
    const[settingsSelection, setSettingsSelection] = useState(settingsSelectionDefault)

    // Animation Instructions for edit window
    let editWindowAnimation = useSpring({
        opacity: editWindow ? 1 : 0,
        zIndex: 2000,
        config: {duration: 150}
      });    

    // Prevents user from hitting escape to close to optimization message box
    document.onkeydown = function (evt) {
        if (evt.keyCode === 27 && optimizationMessageBox) {
            evt.preventDefault()
            return
        }
    };

    // Checks for optomization and import errors
    useEffect(() => {
        // Calls helper function every five seconds to check if optimization is finished.
        // Once finished, reloads page
        if (file.optimizing){
            setOptimizationMessageBox(true)
            var checkOptimization = setInterval(checkOptimizationProcessHelper, 2000)
        }
        return(() => { clearInterval(checkOptimization) }) // Unmounts timer when navigating away from page (most likely to dashboard)
        
    }, [file.optimizing]) 

    useEffect(() => {
        if (file.importErrors.length !== undefined & file.importErrors.length > 0) {
            setImportErrorsMessageBox(true)
        }        
    }, [file]) 
        

    useLayoutEffect(() =>{
    // Loads the data in and then sets the state variables and setsLoading to false
    if (isLoading && typeof fileIdFromUrl !== 'undefined') {
        const fileIdentification = {
            fileID: fileIdFromUrl
        }

        const request1 = axios.post(axiosURL + '/files/fileID', fileIdentification)
        const request2 = axios.post(axiosURL + '/systemSetting/getOne', {name: 'disable clinical assistant'})
        const request3 = axios.post(axiosURL + '/entity/getClinicalSiteInfo', fileIdentification, getAuthHeader())
        Promise.allSettled([request1, request2, request3]).then((responses) => {
            const fileRequest = responses[0]
            const accessRequest = responses[1]
            const linkedSitesRequest = responses[2]

            // Handle Access Return
            if (accessRequest.status !== 'rejected') {
                if (accessRequest.value.data.success === true && accessRequest.value.data.data.value == 'true' ) {
                    history.push('/dashboard')
                    notify('e', 'Clinical Assistant is currently not available, try again later')
                    return
                }
            }

            // Handle file return
            if (fileRequest.status === 'rejected') {
                history.push('/dashboard')
                notify('e', 'There was an error, please try again')
                return
            }
            if (fileRequest.value.data.data.deleted && !belongsToAppointEntity(userData.user.schoolID)) {
                notify('e', 'File was previously deleted')
                history.push('/dashboard')
                return
            }
            if (linkedSitesRequest.status === 'rejected') {
              history.push('/dashboard')
              notify('e', 'There was an error, please try again')
              return
            }

            setSchoolID(fileRequest.value.data.data.schoolID)
            setFile(fileRequest.value.data.data)
            setLinkedClinicalSiteEntities(new Map(linkedSitesRequest.value.data.data.map(obj => [obj.id, obj])))
            setLoading(false)
          })
        }   
    }, [])

    // How the table rows stay highlighted when you click them
    useEffect(() =>{
        if (isLoading === false){
            highlightRowFunction()
        }
    }, [highlightRow])   

    // This is a custom console log ------NOT GOING TO PRODUCTION SERVERS------
    useEffect(() =>{
        console.log("SELECTED STUDENT ", selectedStudent.studentObjectID)
        console.log(selectedStudent)
    }, [selectedStudent])
    

    // This is a custom console log ------NOT GOING TO PRODUCTION SERVERS------
    useEffect(() =>{
        if (selectedClinical) {
            console.log("Selected CLINICAL ", selectedClinical)
            console.log(file.clinicals[indexClinical(selectedClinical)])
        }
    }, [selectedClinical])
    
    useEffect(() => {           
        // Tells the server that you are joining that room
        // socket.connect()
        if(typeof fileIdFromUrl !== 'undefined'){
            socket.emit('joinRoom', fileIdFromUrl);
            
            // Leaves room
            return () => {
                socket.emit('leaveRoom', fileIdFromUrl)            
            }
        }
        
    }, [])

    useEffect(() => {     
        // Receives socket.io transmission whenever someone else on the file changes data
        // Should refactor later and break out the parts
        socket.removeListener('newData')
        socket.on('newData', (data) => {
            console.log('---------- NEW DATA ----------')
            console.log(data)
            // file.students.findIndex(e => e.studentObjectID == data.studentObjectID)

            if (notNullString(data.fileName) && data.fileName != file.fileName) {
                file.fileName = data.fileName
            }

            for(var i=0; i<data.students.length; i++){
                let index = file.students.findIndex(e => e.studentObjectID == data.students[i].studentObjectID)
                if(index != -1){
                    file.students[index] = data.students[i]
                } else {
                    file.students.push(data.students[i])
                }
                
                if(selectedStudent.studentObjectID == data.students[i].studentObjectID){ 
                    setSelectedStudent(data.students[i])
                }
            }

            for(var e=0; e<data.clinicals.length; e++){

                //TODO: Bakcend now isn't assigning clinicalObjectID for some reason (probably race condition?) so we are assigning the clinicalOPbjectID here
                // Now working in edit clinical function. Have to stringify the object. Leaving here to check in other places
                data.clinicals[e].clinicalObjectID = data.clinicals[e]._id

                let index = file.clinicals.findIndex(clin => clin.clinicalObjectID === data.clinicals[e].clinicalObjectID)
                if(index !== -1){
                    file.clinicals[index] = data.clinicals[e]
                } else {
                    file.clinicals.push(data.clinicals[e])
                }
            }
            // Updates the clinical table to show new data
            forceUpdate()
        })   

        socket.removeListener('EditStudentMessageEvent')
        socket.on('EditStudentMessageEvent', (data) => {
            file.studentMessage = data.studentMessage
            forceUpdate()
        })
        
        socket.removeListener('publishFile')
        socket.on('publishFile', (data) => {
            file.published = data.status
            forceUpdate()
        })

        socket.removeListener('changeInSharing')
        socket.on('changeInSharing', (data) => {
            if (data.success) {
                if (doesUserHaveProperAccess(data.sharedWith, file.owner, userData.user.id)) {
                    file.sharedWith = data.sharedWith
                    forceUpdate()
                } else {
                    history.push('/dashboard')
                    notify('i', 'File is no longer being shared with you')
                }
                
            }
        })
            
        socket.removeListener('startOptimization')
        socket.on('startOptimization', (data) => {
            if (data.success) {
                file.optimizing = true
                forceUpdate()
            }
        })

        socket.removeListener('bufferTimeChange')
        socket.on('bufferTimeChange', (data) => {
            file.settings[0].bufferTime = data.bufferTime
            forceUpdate()
        })
        
        socket.removeListener('ChangeScheduleViewEvent')
        socket.on('ChangeScheduleViewEvent', (data) => {
            file.settings[0].compactView =  data.compactView
            forceUpdate()
        })

        socket.removeListener('ConflictOverrideChange')
        socket.on('ConflictOverrideChange', (data) => {
            file.settings[0].conflictOverride = data.conflictOverride
            forceUpdate()
        })

        socket.removeListener('deleteFile')
        socket.on('deleteFile', (data) => {
            if (data.success && data.deleted) {
                history.push('/dashboard')
            } else {
                file.deleted = data.deleted
                forceUpdate()
            }
            
        })
        
        socket.removeListener('RemoveStudentEvent')
        socket.on('RemoveStudentEvent', (data) => {
            file.students.splice(file.students.findIndex(e => e.studentObjectID == data.studentObjectID), 1);

            if(selectedStudent.studentObjectID == data.studentObjectID){
                setSelectedStudent(selectedStudentDefaultValue)
            }
            
            for (let i = 0; i < data.clinicals.length; i++) {
                let index = file.clinicals.findIndex(e => e.clinicalObjectID == data.clinicals[i]._id)
                file.clinicals[index] = data.clinicals[i]
            }
            forceUpdate()
        })
        
        socket.removeListener('changeNursingTopics')
        socket.on('changeNursingTopics', (data) => {
            console.log('-------------- CHANGE NURSING TOPICS (Now called "Clinical Types") --------------------')
            console.log(data)
            file.nursingTopics = data.nursingTopics
            forceUpdate()
        })   

        socket.removeListener('RemoveClinicalsEvent')
        socket.on('RemoveClinicalsEvent', (data) => {
            // Removes clinical from file
            for (let clinicalObjectID of data.clinicals) {
              file.clinicals.splice(file.clinicals.findIndex(e => e.clinicalObjectID == clinicalObjectID), 1);
            }

            for (let student of data.students) {
              let index = file.students.findIndex(e => e.studentObjectID == student.studentObjectID)
              file.students[index].studentClinicalList = student.studentClinicalList
              if(selectedStudent.studentObjectID ==  student.studentObjectID){ 
                  setSelectedStudent(file.students[index])
              }
            }
            
            // Updates the clinical table to show new data
            forceUpdate()
        })   

        socket.removeListener('importErrors')
        socket.on('importErrors', (data) => {
            file.importErrors = data.errorList
            forceUpdate()

        })   

        socket.removeListener('addStudentsToFile')
        socket.on('addStudentsToFile', (data) => {          
            if(data.success == true){
                for (let i = 0; i < data.students.length; i++) {
                    file.students.push(data.students[i])
                }
                file.students = sortList(file.students, 'student')                
            }

            forceUpdate()
        })

        socket.removeListener('notify')
        socket.on('notify', (data) => {
            notify(data.type, data.message)
        })

    }, [file, selectedStudent])

    useEffect(() => {     
        if (file.clinicals && file.clinicals.length > 0) {
            forceUpdate()    
        }
        
        if (selectedStudent.studentObjectID !== '') {
            setSelectedStudent(file.students[indexStudent(selectedStudent.studentObjectID)])           
        }
    }, [file])

    // Called when 'filterWeekDayValue' or 'filterClinicalTypeValue' is set. Skips when loading is not complete
    useEffect(() =>{
        if (isLoading === false){
            forceUpdate()
        }
    }, [showFullClinicals, filterWeekDayValue, filterClinicalTypeValue, filterLocationValue])

    // If no props were passed we push to dashboard
    if(typeof fileIdFromUrl === 'undefined'){
        history.push('/dashboard')
        return <div className="App" style={{height: "100vh"}}></div>;
    }

    if(isLoading) {
        return <Loading />
    }

    // Has to be after this last loading thing
    if (!doesUserHaveProperAccess(file.sharedWith, file.owner, userData.user.id)) {
      console.log('test')
      history.push('/dashboard')
    }

    // Clears import errors on close
    function closeImportErrorFunction(){
        file.importErrors = []

        const fileIdentification = {
            fileID: fileIdFromUrl
        }
        
        axios.post(axiosURL + '/files/clearImportErrors', fileIdentification)
        .then(res => {console.log(res.data)})   
    }

    function handleClearImportErrors(){
        console.log('second')
        file.importErrors = []
        forceUpdate()

    }

    function doesUserHaveProperAccess(sharedWith, owner, userId){
        let isFileSharedWithUser = sharedWith.filter(object => {return object.id === userId}).length > 0
        // Superuser check
        if (belongsToAppointEntity(userData.user.schoolID)) {
            return true
        }
        
        if (isFileSharedWithUser || owner === userId) {
            return true
        } else {
            return false
        }
    }

    function isViewer(sharedWith, userId){
        for (var i=0; i< sharedWith.length; i++){
            if (sharedWith[i].access === 'view' && sharedWith[i].id === userId){
                return true
            }
        }
        return false
    }

    function hasEditAccess () {
        if (getAccessLevel(file.sharedWith, userData.user.id) === 'view') {
            return false
        }
        return true
    }

    function handleViewChange(view){
        if (view === 'schedule') {
          setSelectedStudent(selectedStudentDefaultValue)  
          setScheduleBoolean(true)
          setSettingsBoolean(false)
          
          setScheduleBorder(bottomBorderColor)
          setSettingsBorder('')
        } else if (view === 'settings') {
          setScheduleBoolean(false)
          setSettingsBoolean(true)
          
          // So that it opens up to the students everytime
          setSettingsSelection(settingsSelectionDefault)
          
          setScheduleBorder('')
          setSettingsBorder(bottomBorderColor)
        }
      }
    
    function sortList(list, type){
        if (type == 'clinical') {
            // Sorts clinicals first based on type and then on section #
            return ([].slice.call(list).sort(function(a, b) {
                return a.type.localeCompare(b.type) || a.section - b.section
            }))   
        } else {
            return ([].slice.call(list).sort(function(a, b) {
                return a.lastName.localeCompare(b.lastName) || a.firstName - b.firstName
            }))      
        } 
    }

    // Function is called every 5 seconds and if optimization is done, reloads page which will reload data.
    function checkOptimizationProcessHelper(){
        const packagedData = {
            fileID: fileIdFromUrl
        }
        axios.post(axiosURL + '/files/checkOptimizing', packagedData)
                .then(res => {console.log(res.data); if(res.data === false){window.location.reload()}})
    }

    // Invokes the optimization function and reloads the page. 
    function scheduleForMeFunction(){
      socket.emit('startOptimization', fileIdFromUrl)

      // Backend will set optimization to true on the file. This will cause the popup 
      // Once the optimization is done, the backend will set optimizing to false. 
      // The front end checks every few seconds to see if it is finished which is how 
      // it knows when to reload the page
    }

    function ConfirmOptimizationMessageBox(props){
      return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="messageBox"
          centered>

          <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Schedule Optimization
              </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{width: '500px'}} >
            <div style={{textAlign: "center"}}>
              <p>
                Hit confirm to schedule students!
              </p>
              <p>
                This will only take a few moments.
              </p>
              <p>
                Any unlocked students will likely be rescheduled. 
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <AppointGreyButton onClick={props.onHide} >Cancel</AppointGreyButton>
            <AppointBlueButton onClick={props.onSubmit} >Confirm</AppointBlueButton>
          </Modal.Footer>
        </Modal>
      )
    }
    
    function OptimizationMessageBox(props) {
        return (
          <Modal
          onRequestClose={(event) => {
            console.log(event)
            console.log('requestClose')
          }}
            {...props}
            size="md"
            backdrop='static'
            keyboard="false"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
              Optimization in progress
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{textAlign: "center"}}>This process is estimated to take only a minute. Closing or navigating away from this page will not effect the results.</p>
            </Modal.Body>
            <Modal.Footer>
              <AppointBlueButton onClick={() => history.push('/dashboard')}>Navigate to Dashboard</AppointBlueButton>
            </Modal.Footer>
          </Modal>
        );
    }

    function ShowStudentInfoMessageBox(props) {
        function DistanceToClinicals({ origin, linkedClinicalSiteEntities }) {

          const [data, setData] = useState([]);

          useLayoutEffect(() => {
           
            async function fetchData() {
              if (!isNullorUndefined(origin)) {
                const promises = Array.from(linkedClinicalSiteEntities.entries()).map(async (site) => {
                  const packagedData = {
                    origin,
                    destination: site[1].address.placeId,
                    name: site[1].name
                  }

                  // Leave in here. Important to know when these requests are being sent since 
                  // If we send too many google is going to charge us
                  console.log('[Info] - Getting Distance between ' + origin + ' and ' + site[1].address.placeId)
                  return axios.post(axiosURL + '/files/DistanceBetweenRequest', packagedData);
                });
                const results = await Promise.all(promises);
                setData(results);
              }
            }

            fetchData();
          }, [origin, linkedClinicalSiteEntities]);

          if (isNullorUndefined(origin)) {
            return (
              <div>Student does not provided an address</div>
            )
          }

          return (
            <>
              {data.map(t => {
                return (
                  <div>
                    <span className='userProfileInformationDescription'>{t.data.data.name}</span>
                    <span className='userProfileInformation'>{t.data.data.distance}, {t.data.data.time}</span>
                  </div>
                )
              })}
            </>
          )
        }

        return (
          <Modal
            {...props}
            dialogClassName="Appoint_Modal_Medium"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter" style={{width: '100%'}} >
                <div style={{ display: 'flex' }} >
                    <span id='showStudentInfoStudentName'>
                        {selectedStudent.firstName + " " + selectedStudent.lastName} 
                    </span>
                    <span style={{marginLeft: '10px'}}>
                        <ProfilePicture fileName={selectedStudent.profilePicture} size={'small'} />
                    </span>
                    <span style={{marginLeft: '10px'}}>
                      <Lock disabled={true} />
                    </span>
                    
                    {!isChecklistComplete(selectedStudent) && file.published &&
                      <Alert severity='error' style={{marginLeft: 'auto'}}>Student has not completed all their clinical checklist items</Alert>
                    }
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='userProfileTitle'>
                        Information
                    </div>
                    <div className='userProfileInformationCard'>
                        <span className='userProfileInformationDescription'>Name</span>
                        <span className='userProfileInformation'>{selectedStudent.firstName + " " + selectedStudent.lastName}</span>
                        <span className='userProfileInformationDescription'>Email</span>
                        <span className='userProfileInformation'>{selectedStudent.email}</span>
                        <span className='userProfileInformationDescription'>Class Year</span>
                        <span className='userProfileInformation'>{notNullString(selectedStudent.schoolYear) ? selectedStudent.schoolYear : getNA()}</span>
                        <span className='userProfileInformationDescription'>Student ID</span>
                        <span className='userProfileInformation'>{notNullString(selectedStudent.studentID) ? selectedStudent.studentID : getNA()}</span>
                        <span className='userProfileInformationDescription'>Address</span>
                        <span className='userProfileInformation'>{notNullString(selectedStudent.address.address) ? selectedStudent.address.address : getNA()}</span>
                        <span className='userProfileInformationDescription'>Age</span>
                        <span className='userProfileInformation'>{notNullString(selectedStudent.dateOfBirth) ? calculateAge(selectedStudent.dateOfBirth) : getNA()}</span>
                    </div>
                    <br></br>
                    &nbsp;
                    <div className='userProfileTitle'>
                      Distance to Clinical Sites
                    </div>
                    <div className='userProfileInformationCard'>
                      <DistanceToClinicals origin={selectedStudent.address.placeId} linkedClinicalSiteEntities={linkedClinicalSiteEntities} />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
              <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            </Modal.Footer>
          </Modal>
        );
    }

    function GeneralErrorMessageBox(props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="messageBox"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
              {generalError.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{textAlign: "center"}}> {generalError.body} </p>
            </Modal.Body>
            <Modal.Footer>
              {showOverride ? 
                <AppointBlueButton onClick={props.onOverride}>Override</AppointBlueButton>
              :<></>}
              <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            </Modal.Footer>
          </Modal>
        );
    }

    function getPlaceIdForEntity(siteId) {
        if (notNullString(siteId)) {
          try {
            return linkedClinicalSiteEntities.get(siteId).address.placeId
          } catch {
            // do nothing
          }
          
        }
        return null
    }

    function getAddressForEntity(siteId) {
        if (!isNullorUndefined(siteId) && !isNaN(siteId)) {
          try {
            return linkedClinicalSiteEntities.get(siteId).address.address
          } catch {
            // do nothing
          }
          
        }
        return null
    }

    function getNameForEntity(siteId) {
        if (notNullString(siteId)) {
          try {
            return linkedClinicalSiteEntities.get(siteId).name
          } catch {
            // do nothing
          }
          
        }
        return null
    }

    function ExportDataMessageBox(props) {
        const [fileName, setFileName] = useState('')
        const [includeClinical, setIncludeClinical] = useState(false)
        
        return (
          <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="messageBox"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Export Data
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <input placeholder='File Name' type="text" id='exportFileNameInput' className='newFileInput'/> */}
              <TextField 
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                style={{marginTop:'10px'}}
                className={classes.FileNameBox}
                id="File Name"
                label="File Name" 
                />

                Include Original Clinical Data
                <Switch
                    checked={includeClinical}
                    onChange={() => setIncludeClinical(!includeClinical)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <InformationToolTip>When turned on, this will also download a CSV file of your clinical data that can be reimported into a different file, or serve as a backup</InformationToolTip>
            </Modal.Body>
            <Modal.Footer>
              <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
              <AppointBlueButton id='exportSubmitButton' onClick={() => props.onSubmit(fileName, includeClinical) }>Export</AppointBlueButton>
            </Modal.Footer>
          </Modal>
        );
    }

    function ImportErrorsMessageBox(props) {
        // Creates row based on what type of error is present
        const rowCreation = (row) => {
                if (row.row !== null){
                    return (
                        <div id='importErrorRow'>
                            <span id='row'>Row: <strong>{row.row}</strong></span>
                            <span id='column'>Column: <strong>{row.column}</strong></span>
                            <span id='message'>{row.message}</span>
                        </div>
                        )
                } else {
                    return (
                        <div id='importErrorRow'>
                            <span id='messageFull'>{row.message}</span>
                        </div>
                        )}}
        
        // If there are more than 20 errors found, it prompts the user to check that they imported the right file (20 is random number)
        const excessiveErrors = () =>{
            if (file.importErrors.length > 20){
                return (<div id='excessiveErrors'>A large amount of errors were found, please ensure that the right file was imported.</div>)
            }
        }
        return (
          <Modal
            {...props}
            backdrop='static'
            keyboard='false'
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="messageBox"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
              Import Errors
              </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className='importErrorModalBody'>
                    <div id='importErrorDefaultMsg'>Please read and correct the following errors before importing again. For assitance with importing data, please contact us or use the help button below.</div>
                    {excessiveErrors()}
                    {file.importErrors.map((error) => (
                        rowCreation(error)
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <AppointBlueButton onClick={props.onHelp}>Help with Importing Data</AppointBlueButton>
                <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            </Modal.Footer>
          </Modal>
        );
    }

    function TimeConflictMessageBox(props) {
        return (
            <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="messageBox"
            centered
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                {timeConflict.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{textAlign: "center"}}>{timeConflict.body}</p>
                <br/>
                <p style={{textAlign: "center"}}>You have the option to override this conflict</p>
            </Modal.Body>
            <Modal.Footer>
                <AppointBlueButton onClick={props.onOverride}>Override</AppointBlueButton>
                <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            </Modal.Footer>
            </Modal>
        );
    }

    function openClinicalInfoMessageBox(e){                
        setClinicalInfo(e.target.type)
    }

    function openBlockedTimeMessageBox(e){   
        let name = e.target.type
        setSelectedBlockedTime(selectedStudent.blockedTimeList[indexBlockedTime(name)])

        setOpenBlockedTimeInfoMessageBox(true)
    }
    
    function ClinicalInfoMessageBox(props) {

        var clinical = props.clinical
        if (isNullorUndefined(clinical)) return <></>
        
        var studentList = []
        if(clinical.students.length !== 0){
            for (var i=0; i<clinical.students.length; i++){
                let student = file.students[indexStudent(clinical.students[i])]
                let studentName = student.firstName + ' ' + student.lastName
                if (i !== clinical.students.length-1){ // Checks if it is last or not
                    studentList.push(studentName + ',')
                    continue
                }
                studentList.push(studentName)
            }
        }

        let enrolledStudents =
                <OverlayTrigger
                    key={"left"}
                    placement={"top"}
                    overlay={ // This put every different student on its own line
                    <Tooltip id={`tooltip-${"left"}`}>  
                        {
                            studentList.length != 0 ?
                                studentList.map((student) => (
                                <div><a className="enrolledStudentsHover">{student}</a><br/></div>
                                ))
                            :
                                <div><a className="enrolledStudentsHover"><i>No Other Students</i></a><br/></div>

                        }
                    </Tooltip>
                    }
                >
                  <AppointBlueButton id='enrolledStudents'>Enrolled Students</AppointBlueButton>
                </OverlayTrigger>

        function RenderInfo({source, children}) {
          return (
            <span style={{display: 'flex', margin: '10px'}}>
              <img style={{width: '22px', height: '22px', marginRight: '10px'}} alt='Hospital Icon' src={source} />
              {children}
            </span>
          )
        }

        return (
          <Modal
            {...props}
            dialogClassName="Appoint_Modal_Large" 
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {clinical.type} {clinical.section}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', width: '80vw', maxWidth: '1000px', marginRight: '20px' }} >
              <div style={{ width: '50%' }}>
                <RenderInfo source={config.locationIcon}>{getNameForEntity(clinical.location)},<> </>{getAddressForEntity(clinical.location)}</RenderInfo>
                <RenderInfo source={config.calendarIcon}>{normalizeTime(clinical.startDate)} - {normalizeTime(clinical.endDate)}</RenderInfo>
                <RenderInfo source={config.weekdayIcon}>{listToString(clinical.weekDays)}</RenderInfo>
                <RenderInfo source={config.clockIcon}>{secondsToTime(clinical.startTime)} - {secondsToTime(clinical.endTime)}</RenderInfo>
                <RenderInfo source={config.professorIcon}>Professor {clinical.professor}</RenderInfo>
                Clinical Days:<br />
                {console.log(clinical)}
                {clinical.inpatientDays.length === 0 ? <>All Days</> : <>{listToString(clinical.inpatientDays)}</>}
                <li style={{"list-style-type": "none", textAlign: 'center', margin: '15px 0'}}>{enrolledStudents}</li>
              </div>
              <div style={{ width: '50%' }}>
                {/* Change the clinical site thing at the end of this later */}
                <DirectionsComponent style={{minHeight: '400px', height: '90%', width: '100%', float: 'right'}} origin={selectedStudent.address.placeId} destination={getPlaceIdForEntity(clinical.location)} originName={selectedStudent.firstName + ' ' + selectedStudent.lastName} destinationName={'Clinical Site'} />
                
                {selectedStudent.address.placeId && getPlaceIdForEntity(clinical.location) &&
                  <>
                    <i style={{fontSize: '14px'}}>Distance between student and clinical</i>
                    <span style={{fontSize: '14px', float: 'right', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => openInGoogleMaps(selectedStudent.address.address, getAddressForEntity(clinical.location))}>Open in Google Maps</span>
                  </>
                }
                
              </div>
              
            </Modal.Body>
            <Modal.Footer>
              <AppointGreyButton disabled={!hasEditAccess()} onClick={props.onRemove}>Remove</AppointGreyButton>
              <AppointBlueButton onClick={props.onHide}>Close</AppointBlueButton>
            </Modal.Footer>
          </Modal>
        );
    }

    function BlockedTimeInfoMessageBox(props) {
        

        return (
          <Modal
            {...props}
            size=""
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="messageBox" 
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {selectedBlockedTime.summary}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <br/>
              <ul  id='clinicalInfoLeftSide'>
                  <li> <strong>Start time:</strong> <br/> &nbsp;&nbsp;&nbsp;&nbsp; {secondsToTime(selectedBlockedTime.startTime)}</li><br/>
                  <li> <strong>End time:</strong> <br/> &nbsp;&nbsp;&nbsp;&nbsp; {secondsToTime(selectedBlockedTime.endTime)}</li><br/>
                  <li> <strong>Week Days:</strong> <br/> &nbsp;&nbsp;&nbsp;&nbsp; {listToString(selectedBlockedTime.weekDays)}</li><br/>
              </ul>

              <div  id='clinicalInfoRightSide'>
                <strong>Reason:</strong>
                <div className="clinicalAssistantBlockedTimeReason"> 
                    {selectedBlockedTime.reason} 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <AppointBlueButton onClick={props.onHide}>Close</AppointBlueButton>
            </Modal.Footer>
          </Modal>
        );
    }

  // TODO: Refactor this trashcan. Do it in backend
  function removeClinical(){   
      setClinicalInfo(null)
      socket.emit('UnassignClinicalRequest', fileIdFromUrl, selectedStudent.studentObjectID, clinicalInfo)
    }

    function openAddClinicalMessageBox(clinical, row){        
        // First we display the pop up to confirm that they want to add that clinical
        if(selectedStudent.studentObjectID !== ''){
            setSelectedClinical(clinical.clinicalObjectID)
            setAddClinicalMessageBox(true)
        }
    }

    function AddClinicalMessageBox(props) {
      const clinical = props?.clinical
      if (isNullorUndefined(clinical)) return <></>
      return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
            {clinical.type} {clinical.section}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{textAlign: "center"}}>
              Would you like to add this clinical to {selectedStudent.firstName} {selectedStudent.lastName}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
            <AppointBlueButton onClick={props.onAdd}>Add</AppointBlueButton>
          </Modal.Footer>
        </Modal>
      );
    }

    function conflictCheck(clinicalTypeOverride = false){ // default value
        // Makes sure that we get the most up to date information

        const student = file.students[indexStudent(selectedStudent.studentObjectID)]
        const clinical = file.clinicals[indexClinical(selectedClinical)]

        let skip = false
        let hasType = false

        // Checks that a student was selected
        // if (selectedStudent.userID === '' || selectedStudent.userID === undefined){ 
        if (student.studentObjectID === '' || student.studentObjectID === undefined){     
            setGeneralError({title: 'Error', body: 'Please select a student before trying to add a clinical.'})
            setGeneralErrorMessageBox(true)
            skip = true
        } 

        // Confirm student requires this type of clinical
        if (skip === false && clinicalTypeOverride === false){
            for (var i=0; i< student.nursingTopics.length; i++){                        // for each nursing topic...
                if (student.nursingTopics[i].toString() === clinical.type.toString()){  // ...compare to the clinical type
                    hasType = true
                }

            }

            if (!hasType){
                const errorMsg = student.firstName + ' ' + student.lastName + ' does not require a ' + clinical.type.toString() + ' clinical rotation.'
                setGeneralError({title: 'Error', body: errorMsg})
                setShowOverride(true)
                setGeneralErrorMessageBox(true)
                skip = true
            }
        }
        
        // Checks that they are not already scheduled for that clinical
        if (skip === false){
            for (var i=0; i< student.studentClinicalList.length; i++){
                // if (currentStudent_ConflictCheck.studentClinicalList[i].type === currentClinical_ConflictCheck.type && currentStudent_ConflictCheck.studentClinicalList[i].section === currentClinical_ConflictCheck.section){
                if (student.studentClinicalList[i] === clinical.clinicalObjectID){
                    setGeneralError({title: 'Error', body: 'Student is already scheduled for this clinical.'})
                    setGeneralErrorMessageBox(true)
                    skip = true
                }
            }
        }

        // Checks that there are remaining seats left
        if (skip === false && clinical.remainingSeats === 0){
            
            const errorMsg = 'There are no seats left in ' + clinical.type.toString() + " " + clinical.section.toString()
            setGeneralError({title: 'Error', body: errorMsg})
            setGeneralErrorMessageBox(true)
            skip = true
        }

        // Time Conflict => CLASSES
        if (skip === false){
            for (let i=0; i<student.classesList.length; i++){
                if (skip === true){continue}
                for (let e=0; e<student.classesList[i].weekDays.length; e++){
                    if (skip === true){continue}
                    for (let t=0; t<clinical.weekDays.length; t++){
                        if (skip === true){continue}
                        if (student.classesList[i].weekDays[e] === clinical.weekDays[t]){
                            
                            let [classStart, classEnd] = [student.classesList[i].startTime, student.classesList[i].endTime]
                            let [clinicalStart, clinicalEnd] = [clinical.startTime, clinical.endTime]
                            if (conflictCheckHelperWithBuffer(classStart, classEnd, clinicalStart, clinicalEnd, file.settings[0].bufferTime)){
                                if(!file.settings[0].conflictOverride){
                                    const errorMsg = 'Conflict with ' + clinical.type.toString() + " " + clinical.section.toString() + ' and ' + student.firstName + "'s " + student.classesList[i].className + " class on " + student.classesList[i].weekDays[e]
                                    
                                    setTimeConflict({title: 'Time Conflict', body: errorMsg})
                                    setTimeConflictMessageBox(true)

                                    skip = true
                                }
                            }
                        }
                    }
                }
            }
        }

        // Time Conflict => BLOCKED TIME
        if (skip === false){
            for (let i=0; i<student.blockedTimeList.length; i++){
                if (skip === true){continue}
                for (let e=0; e<student.blockedTimeList[i].weekDays.length; e++){
                    if (skip === true){continue}
                    for (let t=0; t<clinical.weekDays.length; t++){
                        if (skip === true){continue}
                        if (student.blockedTimeList[i].weekDays[e] === clinical.weekDays[t]){
                            
                            let [classStart, classEnd] = [student.blockedTimeList[i].startTime, student.blockedTimeList[i].endTime]
                            let [clinicalStart, clinicalEnd] = [clinical.startTime, clinical.endTime]
                            
                            if (conflictCheckHelperWithBuffer(classStart, classEnd, clinicalStart, clinicalEnd, file.settings[0].bufferTime)){
                                if(!file.settings[0].conflictOverride){
                                    const errorMsg = 'Conflict with ' + clinical.type.toString() + " " + clinical.section.toString() + ' and ' + student.firstName + "'s " + student.blockedTimeList[i].summary + " blocked time on " + student.blockedTimeList[i].weekDays[e]
                                    
                                    setTimeConflict({title: 'Time Conflict', body: errorMsg})
                                    setTimeConflictMessageBox(true)

                                    skip = true
                                }
                            }
                        }
                    }
                }
            }
        }

        // Time Conflict => CLINICALS
        if (skip === false){
            for (let i=0; i<student.studentClinicalList.length; i++){

                // let cIndex = indexClinical(currentStudent_ConflictCheck.studentClinicalList[i].type, currentStudent_ConflictCheck.studentClinicalList[i].section)
                let cIndex = indexClinical(student.studentClinicalList[i])
                if (skip === true){continue}
                for (let e=0; e<file.clinicals[cIndex].weekDays.length; e++){
                    if (skip === true){continue}
                    for (let t=0; t<clinical.weekDays.length; t++){
                        if (skip === true){continue}
                        if (file.clinicals[cIndex].weekDays[e] === clinical.weekDays[t]){    

                            let [classStart, classEnd] = [file.clinicals[cIndex].startTime, file.clinicals[cIndex].endTime]
                            let [clinicalStart, clinicalEnd] = [clinical.startTime, clinical.endTime]
                            
                            if (conflictCheckHelperWithBuffer(classStart, classEnd, clinicalStart, clinicalEnd, file.settings[0].bufferTime)){
                                const errorMsg = 'Conflict with ' + clinical.type.toString() + " " + clinical.section.toString() + ' and ' + file.clinicals[cIndex].type + ' ' + file.clinicals[cIndex].section  + " on " + file.clinicals[cIndex].weekDays[e]
                                setGeneralError({title: 'Time Conflict', body: errorMsg})
                                
                                setGeneralErrorMessageBox(true)

                                skip = true
                            }
                        }
                    }
                }
            }
        }
        
        if (skip === false){
            addClinical()
        }
    }

    // Adds Clinical to student (Used for overriding as well)
    function addClinical(){
        const student = JSON.parse(JSON.stringify(file.students[indexStudent(selectedStudent.studentObjectID)]))
        const clinical =  JSON.parse(JSON.stringify(file.clinicals[indexClinical(selectedClinical)]))

        console.log('1327', clinical)

        // student.studentClinicalList.push({type: clinical.type, section: clinical.section}) // Adds clinical to student
        student.studentClinicalList.push(clinical.clinicalObjectID) // Adds clinical to student
        clinical.remainingSeats -= 1  // Removes one seat from clinical
        clinical.students.push(student.studentObjectID) // Adds student name into students list in that clinical

        if (selectedClinical.remainingSeats === 0){ // If show full clinicals toggle is on and last seat removed, this hides it
            forceUpdate()
        }
        // Resets selectedClinical after to avoid any bugs in future
        setSelectedClinical({type: '', section: '', clinicalObjectID: ''})

        saveData(fileIdFromUrl, null, student, clinical)

    }

    function indexStudent(studentObjectID){
        for (var i=0; i < file.students.length; i++){
            if (file.students[i].studentObjectID === studentObjectID){
                return i
            }
        }
        return -1 // -1 means there was an error
    }

    function indexBlockedTime(summary){
        for (var i=0; i < selectedStudent.blockedTimeList.length; i++){
            if (selectedStudent.blockedTimeList[i].summary === summary){
                return i
            }
        }
        return -1 // -1 means there was an error
    }

    function indexClinical(id){
        for (var i=0; i < file.clinicals.length; i++){
            if (file.clinicals[i].clinicalObjectID === id){
                return i
            }
        }
        return -1 // -1 means there was an error
    }

    function doubleDigit(x){
        // if num between 1 - 9, converts to two digit format
        if (x.toString().length === 1){
            return "0" + x.toString()
        } else {
            return x.toString()
        }
    }

    function saveEditInfo(fileName, students, clinicals){
        file.fileName = fileName
        file.students = students
        file.clinicals = clinicals

        saveData(fileIdFromUrl, file.fileName, file.students, file.clinicals)
        
        return('Saved Edit')
    }

    // Updates the selected student based on the one elected (React rerenders when state variables are set which is what causes the student schedule to be updated everytime)
    function studentListChange(e){
        for (let i=0 ; i < file.students.length ; i++){
            if (file.students[i].studentObjectID === e){
                setSelectedStudent(file.students[i])
            }
        }
    }

    // NOT MY FUNCTION (https://www.golangprograms.com/javascript-sort-multi-dimensional-array-on-specific-columns.html)
    function multiSort(array, sortObject = {}) { 
        const sortKeys = Object.keys(sortObject);
        // Return array if no sort object is supplied.
        if (!sortKeys.length) {
            return array;
        }
        // Change the values of the sortObject keys to -1, 0, or 1.
        for (let key in sortObject) {
            sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
        }
        const keySort = (a, b, direction) => {
            direction = direction !== null ? direction : 1;
            if (a === b) { // If the values are the same, do not switch positions.
                return 0;
            }
            // If b > a, multiply by -1 to get the reverse direction.
            return a > b ? direction : -1 * direction;
        };
        return array.sort((a, b) => {
            let sorted = 0;
            let index = 0;
            // Loop until sorted (-1 or 1) or until the sort keys have been processed.
            while (sorted === 0 && index < sortKeys.length) {
                const key = sortKeys[index];
                if (key) {
                    const direction = sortObject[key];

                    sorted = keySort(a[0][key], b[0][key], direction);
                    index++;
                }
            }
            return sorted;
        });
    }

    function StudentScheduleWeekdays({weekDay}){
        let items = []
        let tempMasterList = []

        // CLASSES Loop
        for (let i = 0; i < selectedStudent.classesList.length; i++) {
            
            let conflict = false;
            // loops through each weekday
            for (let e = 0; e < selectedStudent.classesList[i].weekDays.length; e++) {
                if(selectedStudent.classesList[i].weekDays[e].toLowerCase() === weekDay){

                    for (let k = 0; k < selectedStudent.studentClinicalList.length; k++) {
                        let x = indexClinical(selectedStudent.studentClinicalList[k])
                        for (let z = 0; z < file.clinicals[x].weekDays.length; z++) {

                            if (selectedStudent.classesList[i].weekDays[e] === file.clinicals[x].weekDays[z]){                                  
                                let [clinicalStart, clinicalEnd] = [file.clinicals[x].startTime, file.clinicals[x].endTime]
                                let [classStart, classEnd] = [selectedStudent.classesList[i].startTime, selectedStudent.classesList[i].endTime]                                
                                
                                // Time has to be passed in as seconds
                                if (conflictCheckHelper(classStart, classEnd, clinicalStart, clinicalEnd)){
                                   conflict = true
                                }
                            }   
                        } 
                    }
                    tempMasterList.push([selectedStudent.classesList[i], conflict])
                }   
            }  
        }

        // Blocked Time Loop
        for (let i = 0; i < selectedStudent.blockedTimeList.length; i++) {
            
            let conflict = false;
            // loops through each weekday
            for (let e = 0; e < selectedStudent.blockedTimeList[i].weekDays.length; e++) {
                if(selectedStudent.blockedTimeList[i].weekDays[e].toLowerCase() === weekDay){

                    for (let k = 0; k < selectedStudent.studentClinicalList.length; k++) {
                        let x = indexClinical(selectedStudent.studentClinicalList[k])

                        for (let z = 0; z < file.clinicals[x].weekDays.length; z++) {

                            if (selectedStudent.blockedTimeList[i].weekDays[e] === file.clinicals[x].weekDays[z]){                                  
                                let [clinicalStart, clinicalEnd] = [file.clinicals[x].startTime, file.clinicals[x].endTime]
                                let [classStart, classEnd] = [selectedStudent.blockedTimeList[i].startTime, selectedStudent.blockedTimeList[i].endTime]                                
                                
                                // Time has to be passed in as seconds
                                if (conflictCheckHelper(classStart, classEnd, clinicalStart, clinicalEnd)){
                                   conflict = true
                                }
                            }   
                        } 
                    }
                    tempMasterList.push([selectedStudent.blockedTimeList[i], conflict])
                }   
            }  
        }

        // CLINICALS Loop
        for (let i = 0; i < selectedStudent.studentClinicalList.length; i++) {
            // Used to visually simplify for loop below. From Unique ID, finds clinical in master list
            const currentClinical = file.clinicals[indexClinical(selectedStudent.studentClinicalList[i])]
            // loops through each weekday
            for (let e = 0; e < currentClinical.weekDays.length ; e++) {
                if(currentClinical.weekDays[e].toLowerCase() === weekDay){
                    tempMasterList.push([currentClinical, false])
                }   
            }
        }

        // Sorting based on Start Time after it combines both classes and clinicals list (Do no have to consider End Time since we check for conflicts when you add clinicals and it would cause issues with stuff that runs past midnight)            
        tempMasterList = multiSort(tempMasterList, {startTime: 'asc'})
        
        // Adds each item based on whether it is a Clinical or not (allows us to better control styling)
        for (let i=0; i<tempMasterList.length; i++){
            // Checks if there was a conflict detected, it makes the class red
            // This does not make clinicals red since those take priority over everything else
            let className = ''
            if (tempMasterList[i][1] === true) {
                className = 'conflict'
            }

            if (tempMasterList[i][0].section !== undefined) { // If it is a clinical
                items.push(<li type={tempMasterList[i][0].clinicalObjectID} disabled={!hasEditAccess()} onClick={e => openClinicalInfoMessageBox(e)} id='studentSchedulesClinicalItem'><strong>{tempMasterList[i][0].type} {tempMasterList[i][0].section}</strong>:<br/>{secondsToTime(tempMasterList[i][0].startTime)} - {secondsToTime(tempMasterList[i][0].endTime)}</li>)
            } else if (tempMasterList[i][0].summary !== undefined) { // If it is a blocked time
                items.push(<li type={tempMasterList[i][0].summary} onClick={e => openBlockedTimeMessageBox(e)} className={className} id='studentSchedulesBlockedTimeItem'><strong>{tempMasterList[i][0].summary}</strong>:<br/>{secondsToTime(tempMasterList[i][0].startTime)} - {secondsToTime(tempMasterList[i][0].endTime)}</li>)
            } else { // If it is a class
                items.push(<li className={className} id='studentSchedulesClassItem'><strong>{tempMasterList[i][0].className}</strong>:<br/>{secondsToTime(tempMasterList[i][0].startTime)} - {secondsToTime(tempMasterList[i][0].endTime)}</li>)
            }
        }

        if (items.length !== 0){
            if (weekDay.toLowerCase() === 'saturday'){
                return <div>{items}</div>
            }
            return <div style={{borderRight: '.13em solid #575757', height: '90%'}}>{items}</div>
        } else {
            if (weekDay.toLowerCase() === 'saturday'){
                return <div style={{marginTop: "5px"}}>&nbsp;</div>
            }
            return <div style={{borderRight: '.13em solid #575757', height: '90%', marginTop: "5px"}}>&nbsp;</div>
        }
    }

    const PopulateFilters = ({type}) =>{
        if (type == "weekDay"){
            return(
                <Select
                    id="filterWeekdayID"
                    onChange={(e) => {setFilterWeekDayValue(e)}}
                    onSelectResetsInput ={false}
                    closeMenuOnSelect = {false}
                    value={filterWeekDayValue}
                    isMulti
                    name="weekDays"
                    options={[
                        { value: 'sunday', label: 'Sunday'},
                        { value: 'monday', label: 'Monday'},
                        { value: 'tuesday', label: 'Tuesday'},
                        { value: 'wednesday', label: 'Wednesday'},
                        { value: 'thursday', label: 'Thursday'},
                        { value: 'friday', label: 'Friday'},
                        { value: 'saturday', label: 'Saturday'},
                    ]}
                />
            )
        } else if (type == "clinicals") {
            
            let uniqueTopics = []

            for (const topic of file.nursingTopics) {
                uniqueTopics.push({value: topic.toString(), label: topic.toString()})
            }

            return(
                <Select
                    onChange={(e) => setFilterClinicalTypeValue(e)}
                    id="filterWeekdayID"
                    closeMenuOnSelect={true}
                    blurInputOnSelect= {true}
                    maxMenuHeight={120}
                    isMulti
                    value={filterClinicalTypeValue}
                    components={animatedComponents}
                    options={uniqueTopics}
                />
            )
        } else {
            
            // Better to use the clinicals here rather than the linked sites
            // Since you might not be using all the sites and the list would get really long
            const uniqueLocations =  [...new Set(file.clinicals
              .map(clinical => clinical.location))]
              .map(location => { 
                if(!isNullorUndefined(location)) {
                  return {value: getNameForEntity(location), label: getNameForEntity(location)}
                }
                return null
              }).filter(value => value != null)
            
            return(
                <Select
                    //defaultValue={{ value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true }}
                    onChange={(e) => {setFilterLocationValue(e)}}
                    id="filterWeekdayID"
                    closeMenuOnSelect={true}
                    blurInputOnSelect= {true}
                    maxMenuHeight={120}
                    isMulti
                    value={filterLocationValue}
                    components={animatedComponents}
                    options={uniqueLocations}
                />
            )
            
        }
    }

    function stringToList(receivedString, delimeter){
        const capitalize = (s) => {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
          }
        if (receivedString !== undefined){
            receivedString = receivedString.split(delimeter)
            for (let i = 0; i < receivedString.length; i++) {
                receivedString[i] = capitalize(receivedString[i].trim())
            }
            return receivedString
        } else {
            return []
        }
    }

    function VerticalBar(){
        return <div className='verticalBar'>|</div>
    }

    function importFile(e, type){  // HAS TO BE A CSV FILE
        var excelFile = e.target.files

        Papa.parse(excelFile[0], {
            complete: function(results) {
                const packagedData = {
                    fileID: fileIdFromUrl,
                    data: results.data,
                    existingData: {students: file.students, clinicals: file.clinicals},
                    type: type
                }
                axios.post(axiosURL + '/files/importData', packagedData, axiosHeader)
                    .then(res => {
                        window.location.reload()
                    }
                )
            }}
        )
    }

    function exportFile(fileName, includeOriginalClinicalFile){
        
        const packagedData = {
            fileID: fileIdFromUrl,
            includeOriginalClinicalFile
        }

        axios.post(axiosURL + '/files/exportData', packagedData)
            .then(res => {
                /// Adjusts the width of each column based on maximum width of content
                function fitToColumn(arrayOfArray) {
                    // get maximum character of each column
                    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0))+1 }));
                }

                // Extracts Data from return
                const masterData = res.data

                // Not too sure what the rest of this does (https://blog.bitsrc.io/exporting-data-to-excel-with-react-6943d7775a92)
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let fileExtension = '.xlsx';

                // Student Sheet
                const WSstudent = XLSX.utils.json_to_sheet(masterData[0], {skipHeader:true}); // SkipHeaders is needed (https://github.com/SheetJS/sheetjs/issues/1253 - User 164000215 comment)
                WSstudent['!cols'] = fitToColumn(masterData[0]); /// Adjusts width for columns within this sheet
                // Clinical Sheet
                const WSclinical = XLSX.utils.json_to_sheet(masterData[1], {skipHeader:true});
                WSclinical['!cols'] = fitToColumn(masterData[1]);
                // Professor Sheet
                const WSprofessor = XLSX.utils.json_to_sheet(masterData[2], {skipHeader:true});
                WSprofessor['!cols'] = fitToColumn(masterData[2]);
                
                // Creates actual excel file. Important that the names of sheets are the same.
                const wb = { Sheets: { 'Student Data': WSstudent, 'Clinical Data': WSclinical, 'Professor Data': WSprofessor }, SheetNames: ['Student Data', 'Clinical Data', 'Professor Data'] }; // HAVE TO CHANGE BOTH 'Sheet 1's FOR EVERYTHING TOP WORK (MAKE IDENTICAL)
                // No idea what this does...
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
                const data = new Blob([excelBuffer], {type: fileType});
                
                // Downloads file to machine in downloads folder
                FileSaver.saveAs(data, fileName + fileExtension);
                // Hides export data message box

                if (includeOriginalClinicalFile) {
                    fileExtension = '.csv'
                    const WSOGClinical = XLSX.utils.json_to_sheet(masterData[3], {skipHeader:true}); // SkipHeaders is needed (https://github.com/SheetJS/sheetjs/issues/1253 - User 164000215 comment)
                    
                    // Creates actual excel file. Important that the names of sheets are the same.
                    const wb = { Sheets: { 'Clinical Data': WSOGClinical}, SheetNames: ['Clinical Data'] }; // HAVE TO CHANGE BOTH 'Sheet 1's FOR EVERYTHING TOP WORK (MAKE IDENTICAL)
                    // No idea what this does...
                    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array'});
                    const data = new Blob([excelBuffer], {type: fileType});
                    
                    // Downloads file to machine in downloads folder
                    FileSaver.saveAs(data, fileName + ' - Original Clinical Data' + fileExtension);
                }

                setExportDataMessageBox(false)
            })
            .catch((err) => {
                console.log(err)
                setExportDataMessageBox(false)
                notify('e', 'Error exporting file, please contact support')
            })
    }
    
    const columns = [{
            accessor: 'type',
            Header: 'Type'
        }, {
            accessor: 'section',
            Header: 'Section'
        }, {
            accessor: 'weekDays',
            Header: 'Week Days',
            Cell: (props) => { // Allows me pass value of cell to function
                return listToString(props.cell.value)
            }
        }, {
            accessor: 'startTime',
            Header: 'Start Time',
            Cell: (props) => { // Allows me pass value of cell to function
                return secondsToTime(props.cell.value)
            }
        }, {
            accessor: 'endTime',
            Header: 'End Time',
            Cell: (props) => { // Allows me pass value of cell to function
                return secondsToTime(props.cell.value)
            }
        }, {
            accessor: 'professor',
            Header: 'Professor'
        }, {
            accessor: 'location',
            Header: 'Location',
            Cell: (props) => { // Allows me pass value of cell to function
              return getNameForEntity(props.cell.value)
          }
        }, {
            accessor: 'remainingSeats',
            Header: 'Seats Remaining',
        }];
      
    const Styles = styled.div`
        margin-left: -1px
        height: 100%;

        table {
        width: 99.999%;
        text-align: center;
        position: relative;
        border-collapse: collapse; 

        tr {
            :last-child {
            td {
                border-bottom: 0;
            }
            }
        }
        tbody tr:focus {
            outline: 0;            
        }
        tr:hover {
            background-color: #6E7794;
            color: white;
            :last-child {
            td {
                border-bottom: 0;
            }
            }
        }
        th{
            letter-spacing: px;
            color: white;
            font-weight: normal;
            background-color: #24435f;
            border-bottom: 1px solid black;
            font-size: 15px;
            position:sticky;
            top:0;
            
        },
        td {
            margin: 0;
            padding: 0.5rem;
            border-right: 1px solid  rgb(211, 210, 210);
            border-bottom: 1px solid black;
            font-size: 12px;
            :last-child {
            
            }
        }
        }
        `

    function highlightRowFunction(){
        // Gets table, then gets row by index, then sets background and text color
        // If statement keeps color red if there are no remaining seats
        
        if (highlightRow.original.remainingSeats === 0) {
            document.getElementById('clinicalsTable').getElementsByTagName('tr')[parseInt(highlightRow.id)+1].style.backgroundColor = "#6E7794"
        } else if (document.getElementById('clinicalsTable').getElementsByTagName('tr')[parseInt(highlightRow.id)+1] !== undefined){
            document.getElementById('clinicalsTable').getElementsByTagName('tr')[parseInt(highlightRow.id)+1].style.backgroundColor = "#6E7794"
            document.getElementById('clinicalsTable').getElementsByTagName('tr')[parseInt(highlightRow.id)+1].style.color = "white"
        }
        
        
    }

    // If no student selected, box doesn't pop up and row does not need to be selected
    function setHighLightRowHelper(row){
        if (selectedStudent.studentObjectID != '') {
            setHighLightRow(row)
        }
    }

    function ClinicalsTable ({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
            })

        return (
            <Styles>
                <table {...getTableProps()} id='clinicalsTable'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} >
                        {rows.map((row, i) => {
                        prepareRow(row)
                        // If Remaning Seats are empty, return a row that is red, if not, continues to return the below
                        if (row.original.remainingSeats === 0) {
                            return (
                                <tr {...row.getRowProps()} 
                                    style={{color: "red"}} 
                                    onClick={() => {
                                        if (hasEditAccess()) {
                                            setHighLightRowHelper(row)
                                            openAddClinicalMessageBox(row.original)
                                        }
                                    }}>
                                
                                {/* THIS IS WHERE ON ROW SELECTION WORKS "onClick={() => console.log(row.original)}" */}
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                                </tr>
                            )
                        }
                        return (
                            <tr {...row.getRowProps()} 
                                onClick={() => {
                                    if (hasEditAccess()) {
                                        setHighLightRowHelper(row)
                                        openAddClinicalMessageBox(row.original)
                                    }
                                }
                                
                            }> {/* THIS IS WHERE ON ROW SELECTION WORKS "onClick={() => console.log(row.original)}" */}
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                        })}
                    </tbody>
                </table>
            </Styles>
    )}

    function getClinicalData(){
        

        // Clinical Type Filter
        let clinicalTypeList = []
        if (filterClinicalTypeValue.length !== 0){
            for (let i=0; i < file.clinicals.length; i++){
                for (let e=0; e<filterClinicalTypeValue.length; e++){
                    if (filterClinicalTypeValue[e].value.toLowerCase() === file.clinicals[i].type.toLowerCase()){
                        clinicalTypeList.push(file.clinicals[i])
                    }
                }
            }
        } else {
            clinicalTypeList = file.clinicals
        }

        // Localtion Filter
        let locationList = []
        if (filterLocationValue.length !== 0){
            for (let i=0; i < clinicalTypeList.length; i++){
                for (let e=0; e<filterLocationValue.length; e++){
                    if (!isNullorUndefined(getNameForEntity(clinicalTypeList[i].location)) && filterLocationValue[e].value.toLowerCase() === getNameForEntity(clinicalTypeList[i].location).toLowerCase()){
                        locationList.push(clinicalTypeList[i])
                    }
                }
            }
        } else {
            locationList = clinicalTypeList
        }

        // Week Day Filter
        let masterList = []
        if (filterWeekDayValue.length !== 0){
            for (let i=0; i < locationList.length; i++){
                for (let e=0; e < locationList[i].weekDays.length; e++){
                    for (let t=0; t < filterWeekDayValue.length; t++){
                        if (filterWeekDayValue[t].value.toLowerCase() === locationList[i].weekDays[e].toLowerCase()){
                            masterList.push(locationList[i])
                        }
                    }
                }
            }
        } else {
            masterList = locationList
        }            

        if (!showFullClinicals) {
          masterList = masterList.filter(item => {
            return parseInt(item.remainingSeats) !== 0;
          });
        }

        return sortList(masterList, 'clinical')
    }

    function deleteFile(){
        const packagedData = {
            fileID: fileIdFromUrl
        }

        axios.post(axiosURL + '/files/deleteFile', packagedData)
                .then(res => {history.push('/dashboard')})
    }

    function Circumstance (){
        const buttonText = "Exception"
        if (selectedStudent.circumstance === ''){
            return (<AppointGreyButton style={{height: '30px', lineHeight: '10px', marginTop: '-2.5px'}}>{buttonText}</AppointGreyButton>)
        } else{
            return (<OverlayTrigger
                    key={"left"}
                    placement={"left"}
                    // Max amount of writing before overflow is about 1 page in word (single space; 11pt; calibri(body))
                    overlay={<span className='circumstanceHover'>
                                {selectedStudent.circumstance}
                            </span>}
                    >
                    <AppointRedButton style={{height: '30px', lineHeight: '10px', marginTop: '-2.5px'}}><strong>{buttonText}</strong></AppointRedButton>
                </OverlayTrigger>)
        }
    }

    function handleLockChange(){
        if (!hasEditAccess()) return
        if(selectedStudent.userID != ''){
            var student = JSON.parse(JSON.stringify(file.students[indexStudent(selectedStudent.studentObjectID)]))
            student.locked = !student.locked
            saveData(fileIdFromUrl, null, student, null)
        }
        
    }

    function Lock({ disabled }){
        if (selectedStudent.locked){
            return (<LockOutlined className={classes.LockIcon} disabled={disabled} onClick={() => {if(!disabled) handleLockChange()}} />)
        } else{
            return (<UnlockOutlined className={classes.LockIcon} disabled={disabled} onClick={() => {if(!disabled) handleLockChange()}}/>)
        }
    }
   
    // REACT MEMO TESTING
    const ShowStudentName = React.memo(function ShowStudentName(props){
            return (<strong><span className="ShowStudentNameStudentName">{props.firstName} {props.lastName}</span></strong>)   
    })

    function ShowStudentClassYear(){
        return (<span>{selectedStudent.classYear}</span>)
    }

    function ShowStudentNursingTopics(){
        return (<strong><div>{listToString(selectedStudent.nursingTopics)}</div></strong>)
    }

    const FunctionBar = () => {
        return(
            <section className='functionBar'>
                <span id='functionBarName'>
                    <strong>{file.fileName}&nbsp;&nbsp;<br></br></strong>
                </span>
                <span id='functionBarPillsSection'>
                {AdministratorFilePills(file.published, file.closed)}
                    {file.deleted &&
                        DeletedPills(file.deleted)
                    }
                </span>
                <span id="functionBarButtonsSpan">
                    <button className='functionBarViewButton' style={{fontStyle: 'italic', width: 'fit-content', fontSize: '15px', marginRight: '20px'}} onClick={() => {setScheduleBoolean(true); setExportDataMessageBox(true)}}>Export</button>
                    <button className='functionBarViewButton' onClick={() => handleViewChange('schedule')} style={{borderBottom: scheduleBorder}}>Schedule</button>
                    <button className='functionBarViewButton' onClick={() => handleViewChange('settings')} style={{borderBottom: settingsBorder}}>Settings</button>
                </span>
            </section>
        )
    }

    const handleleSettingsSelection = (selection) => {
        setSettingsSelection(selection)
    }

    const handleUpload = (e, addTestingData) => {
        let file = e.data
        let fileID = fileIdFromUrl
        
        socket.emit('addStudentsToFile', file, fileID, addTestingData)
    }

    function clearFilters() {
        setFilterClinicalTypeValue([])
        setFilterLocationValue([])
        setFilterWeekDayValue([])
    }

    function isChecklistComplete(student) {
      for (let clinical of student.studentClinicalList) {
        let workingClin = file.clinicals.filter(i => i.clinicalObjectID === clinical)[0]
        if (isNaN(workingClin.location)) continue
        if (isNullorUndefined(linkedClinicalSiteEntities.get(workingClin.location))) continue
        let checklist = linkedClinicalSiteEntities.get(workingClin.location).checklist
        if (!isNullorUndefined(student.checklists) && 
            !isNullorUndefined(student.checklists[workingClin.location]) &&
            student.checklists[workingClin.location].length < checklist.length) {
              return false
        }
      }
      return true
    }

    function handleEventClick(eventType, event) {
      switch (eventType) {
        case 'clinical':
          setClinicalInfo(event)
          break;
        case 'blockedTime':
          setSelectedBlockedTime(selectedStudent.blockedTimeList[indexBlockedTime(event.summary)])
          setOpenBlockedTimeInfoMessageBox(true)
          break;
        default:
      }
    }

    // This is where we are rendering the main content
    if(scheduleBoolean){
        return(
            <div className='scheduleGrid clinicalAssistantMain'>
                                                   
                <OptimizationMessageBox
                    show={optimizationMessageBox}
                    // onHide={(event) => {setOptimizationMessageBox(false)}}
                    disableEscapeKeyDown = {true}
                />
    
                <ShowStudentInfoMessageBox
                    show={showStudentInfoMessageBox}
                    onHide={() => {setShowStudentInfoMessageBox(false)}}
                />
    
                <ImportErrorsMessageBox
                    show={importErrorsMessageBox}
                    onHide={() => {setImportErrorsMessageBox(false); closeImportErrorFunction()}}
                    onHelp={() => {setImportErrorsMessageBox(false); closeImportErrorFunction(); history.push('/dashboard')}}
                />
    
                <GeneralErrorMessageBox
                    show={generalErrorMessageBox}
                    onHide={() => {setGeneralErrorMessageBox(false)}}
                    onOverride={() => {setGeneralErrorMessageBox(false); setShowOverride(false); conflictCheck(true)}} // true here forces ignore of clinical type matching student needs
                    errormessage={generalError}
                />
    
                <ExportDataMessageBox
                    show={exportDataMessageBox}
                    onHide={() => {setExportDataMessageBox(false)}}
                    onSubmit={exportFile}
                    errormessage={generalError}
                />
    
                <TimeConflictMessageBox
                    show={timeConflictMessageBox}
                    onHide={() => {setTimeConflictMessageBox(false)}}
                    onOverride={() => {setTimeConflictMessageBox(false); addClinical()}}
                    errormessage={generalError}
                />
                
                <ClinicalInfoMessageBox
                    show={clinicalInfo}
                    clinical={file.clinicals[indexClinical(clinicalInfo)]}
                    onHide={() => {setClinicalInfo(null)}}
                    onRemove={() => {removeClinical()}}
                    className='centerInfoBox'
                />
    
                <BlockedTimeInfoMessageBox
                    show={openBlockedTimeInfoMessageBox}
                    onHide={() => {setOpenBlockedTimeInfoMessageBox(false)}}
                    className='centerInfoBox'
                />
                
                <AddClinicalMessageBox
                    show={addClinicalMessageBox}
                    clinical={file.clinicals[indexClinical(selectedClinical)]}
                    onHide={() => {setAddClinicalMessageBox(false); setSelectedClinical('')}}
                    onAdd={() => {setAddClinicalMessageBox(false); conflictCheck()}}
                />

                <ConfirmOptimizationMessageBox
                    show={confirmOptimizationMessageBox}
                    onHide={() => {setConfirmOptimizationMessageBox(false)}}
                    onSubmit={() => {setConfirmOptimizationMessageBox(false); scheduleForMeFunction()}}
                />
    
                <FunctionBar />
                
                <section className='studentList'>                   
                  <SearchBar published={file.published} students={file.students} clinicals={file.clinicals} selectedStudent={selectedStudent} studentListChange={(e) => studentListChange(e)} />
                </section>
    
                {/* <StudentInfoBar selectedStudent={selectedStudent}  setShowStudentInfoMessageBox={(boolean) => setShowStudentInfoMessageBox(boolean)} /> */}
                
                <section className='studentInfo'>
                        <span id='verticalBar'>
                            <VerticalBar />
                        </span>
                        <span id='studentInfoName'>
                            <button id="showStudentInfoButton" onClick={
                                    (() => {if(selectedStudent.studentObjectID !== ''){setShowStudentInfoMessageBox(true)}})
                                    }>
                                <ShowStudentName firstName={selectedStudent.firstName} lastName={selectedStudent.lastName}/>
                                {selectedStudent.firstName !== '' && selectedStudent.lastName !== '' ? (
                                  <>
                                  <img alt='information icon' src={config.informationIcon} className="informationIconCA" id="informationIconCA" />
                                    {!isChecklistComplete(selectedStudent) && file.published &&
                                      <img alt='Red checklist icon' style={{width: '16px', height: '16px', marginBottom: '14px'}} src={config.redChecklistIcon}/>
                                    }
                                  </>
                                  ) : (
                                    <span></span>
                                  )
                                }
                                
                            </button>
                        </span>
                        <span id='verticalBar'>
                            <VerticalBar />
                        </span>
                        <span id='studentInfoNursingTopics'>
                            <ShowStudentNursingTopics />
                        </span>
                        <span id='verticalBar'>
                            <VerticalBar />
                        </span>
                        <span id='studentInfoCircumstances'>
                            <Circumstance />
                        </span>
                        <span id='verticalBar'>
                            <VerticalBar />
                        </span>
                        <span id='studentInfoCircumstances'>
                            <Lock />
                        </span>
                        <span id='verticalBar'>
                            <VerticalBar />
                        </span>
                </section>
    
    
    
                <section className='studentSchedules' >
                    {file.settings[0].compactView ? 
                      <section className='studentSchedulesCompactView' >    
                        <span id='studentSchedulesBlock'>
                            <p id='studentSchedulesWeekDayTitle'>Sunday</p>
                            <StudentScheduleWeekdays weekDay={"sunday"}/>
                        </span>
                        <span id='studentSchedulesBlock'>
                            <p id='studentSchedulesWeekDayTitle'>Monday</p>
                            <StudentScheduleWeekdays weekDay={"monday"}/>
                        </span>
                        <span id='studentSchedulesBlock'>
                          <p id='studentSchedulesWeekDayTitle'>Tuesday</p>
                            <StudentScheduleWeekdays weekDay={"tuesday"}/>
                        </span >
                        <span id='studentSchedulesBlock'>
                            <p id='studentSchedulesWeekDayTitle'>Wednesday</p>
                            <StudentScheduleWeekdays weekDay={"wednesday"}/>
                        </span>
                        <span id='studentSchedulesBlock'>
                            <p id='studentSchedulesWeekDayTitle'>Thursday</p>
                            <StudentScheduleWeekdays weekDay={"thursday"}/>                    
                        </span>
                        <span id='studentSchedulesBlock'>
                            <p id='studentSchedulesWeekDayTitle'>Friday</p>
                            <StudentScheduleWeekdays weekDay={"friday"}/>
                        </span>
                        <span id='studentSchedulesBlockLAST'>
                            <p id='studentSchedulesWeekDayTitle'>Saturday</p>
                            <StudentScheduleWeekdays weekDay={"saturday"}/>
                        </span>
                      </section>
                    :
                    <span className='studentSchedulesWeekDayView'>
                      <WeeklyCalendar onEventClick={handleEventClick} classes={selectedStudent.classesList} clinicals={selectedStudent.studentClinicalList} blockedTime={selectedStudent.blockedTimeList} fileClinicalList={file.clinicals} />
                    </span>
                    
                    }
                </section>
            
                <section className='filterSection'>
                    <label id="studentListTitle">
                        <strong>Filters</strong>
                        {filterWeekDayValue.length != 0 || filterClinicalTypeValue.length != 0 || filterLocationValue.length != 0 ?
                            <Button class={classes.ClearAllButton} onClick={clearFilters}variant="contained">Clear</Button>
                            :
                            <></>
                        }
                        
                    </label>
                    
                    <span id='filterSectionClinicalType'>
                        <p id='filterTitle'>Clinical Type</p>
                        <PopulateFilters type={"clinicals"} />
                    </span>
                    <br/>
                    <span id='filterSectionWeekDays'>
                        <p id='filterTitle'>Week Day</p>
                        <PopulateFilters type={"weekDay"} />
                    </span>
                    <br/>
                    <span id='filterSectionClinicalType'>
                        <p id='filterTitle'>Location</p>
                        <PopulateFilters type={"location"} />
                    </span>
                    <div className='filterButtonSection' >
                        {showFullClinicals ?
                            <AppointGreyButton className='filterSectionFullClinicalsButton' onClick={() => {setShowFullClinicals(false)}}>Hide Full Clinicals</AppointGreyButton>
                             :
                            <AppointRedButton className='filterSectionFullClinicalsButton' onClick={() => {setShowFullClinicals(true)}}>Show Full Clinicals</AppointRedButton>
                        }
                        <AppointBlueButton className='filterSectionScheduleForMeButton' disabled={!hasEditAccess()} onClick={() => {setConfirmOptimizationMessageBox(true)}}><strong>Schedule For Me!</strong></AppointBlueButton>
                    </div>
                </section>
                
                <section className='clinicalSection flex justify-center mt-8'>
                    <span>
                        <ClinicalsTable columns={ columns } data={ getClinicalData() } />
                    </span>
                </section>
                
            </div>
        )
    } else if(settingsBoolean){
        return(
            <div className='settingsGrid clinicalAssistantMain'>
                <FunctionBar />
                <SettingsSelection handleSettingsSelection={handleleSettingsSelection}/>
                <SettingsArea props={{schoolID: file.schoolID, deleted: file.deleted, settingsSelection, 
                    sharedWith: file.sharedWith, fileSettings: file.settings, fileName: file.fileName, fileID: fileIdFromUrl, 
                    students: file.students, clinicals: file.clinicals, nursingTopics: file.nursingTopics, 
                    published:file.published, clinicalImportErrors: file.importErrors, studentMessage: file.studentMessage}} 
                    handleRemove={e => handleRemove(e)} handleUpload={(e, addTestingData) => handleUpload(e, addTestingData)} 
                    handleClearImportErrors={e => handleClearImportErrors(e)}/>
            </div>
        )
    }
    
}
export default ClinicalAssistant;