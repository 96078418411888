export function convertEntityType(type) {
  switch (type) {
    case 0:
      return 'Internal'
    case 1:
      return 'School'
    case 2:
      return 'Clinical Site'
    default:
      return null
  }
}

export function isSchool(type) {
  return type === 1
}

export function isClinicalSite(type) {
  return type === 2
}