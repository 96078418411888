export function isStudent(s) {
  return s.toString().trim().toLowerCase() === 'student'
}

export function isAdministrator(s) {
  return s.toString().trim().toLowerCase() === 'administrator'
}

export function isSuperUser(s) {
  return s.toString().trim().toLowerCase() === 'superuser'
}