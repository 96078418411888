// Does what it sounds like
export function getToken(){    
    let token = window.localStorage.getItem("auth-token");
    if(token == undefined || token == 'undefined' || token == null || token == 'null' ||token == ''){
      return null;      
    } else {
      return token;
    }
}

export function getAuthHeader() {
    return {headers: {'x-auth-token': getToken()}};
}