import { makeStyles } from '@mui/styles';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-scroll';
import PlatformStyles from '../../../PlatformStyles';

const useStyles = makeStyles(() => ({
  Container: {
    opacity: '1',
    background: 'linear-gradient( rgba(15, 12, 12, 0.5), rgba(0, 0, 0, 0.4) ), url(../HomePageHeader.jpg)',
    backgroundPosition: '100% 100%',
    backgroundSize: 'cover',
    height: '700px',
    width: '100%',
    paddingLeft:'15px', // for mobile, but no effect on desktop site since length of text displayed
    paddingRight:'15px' // for mobile, but no effect on desktop site since length of text displayed
  },
  HeaderText: {
    fontFamily: 'Glac',
    color: 'white',
    paddingTop: '250px', 
    fontSize: '65px',
    zIndex: '100',
  },
  HeaderSubText: {
    fontFamily: 'Glac',
    color: 'rgb(196, 196, 196)',
    fontSize: '23px',
    maxWidth: '480px',
    fontWeight: '0',
    zIndex: '100',
  },
  ContactUsButton: {
    marginTop: '20px !important',
    border: 'none',
    padding: '10px 70px',
    backgroundColor: '#364A5E',
    width: '300px',
    borderRadius: PlatformStyles.BorderRadius,
    color: 'white !important',
    '&:hover': {
      boxShadow: PlatformStyles.BoxShadow,
      backgroundColor: '#364A5E',
    }
  }
  
}));
export default function Header() {
    const classes = useStyles()  
    let mobileAdjustedPadding = classes.HeaderText.paddingTop

    if (isMobile){
      mobileAdjustedPadding = '150px'
    }
  
  return(
    <div className={classes.Container}>
      <h1 className={`${classes.HeaderText} center`} style={{paddingTop: mobileAdjustedPadding}}>
          Optimize <i><b>your</b></i> work flow
      </h1>
      <p className={`${classes.HeaderSubText} center`}>
          with an efficient, intuitive scheduling application featuring all of the information nursing schools need, and nothing they don't
      </p>
      <Link className={`${classes.ContactUsButton} center`} to='contact_us_position' spy={true} smooth={true} duration={1000} offset={-50} onClick={()=> console.log('click') }>Contact Us</Link>
    </div>
  )
}
