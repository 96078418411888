import { EditOutlined } from '@ant-design/icons'
import { makeStyles } from '@mui/styles';
import axios from 'axios'
import Collapse from 'rc-collapse'
import React, { useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import { axiosURL } from '../../axiosURL_Front'
import config from '../../config'
import UserContext from '../../context/UserContext'
import calculateAge from '../../functions/calculateAge'
import checkLoggedIn from '../../functions/checkLoggedIn'
import convertTime from '../../functions/convertTime'
import formatPhoneNumber from '../../functions/formatPhoneNumber'
import { getNA } from '../../functions/getNA'
import { getAuthHeader } from '../../functions/getToken'
import { notNullString } from '../../functions/notNullString'
import { isStudent } from '../../functions/UserTypeChecks'
import PlatformStyles from '../../PlatformStyles'
import { AppointTransparentButton } from '../../utils/AppointButton'
import { AppointTileWrapper } from '../../utils/AppointTileWrapper'
import CropEasy from '../ImageSelector.js/CropEasy'
import Loading from '../Loading/Loading'
import { StudentFilePills } from '../misc/FilePills'
import { ProfilePicture } from '../misc/ProfilePicture'
import { notify } from '../Notification/notify'
import StudentFile from '../StudentFile/StudentFile'
import Table from '../Users/Table';
import './UserProfile.css'

const useStyles = makeStyles(() => ({
  LegalNotice: {
    fontSize: '12px',
    width: '95%',
    margin: 'auto',
  },
  EditIconWrapper: {
    height: '30px',
    width: '30px',
    fontSize: '20px',
    borderRadius: '100% !important',
  },
  EditIcon: {
    width: '30px',
    height: '30px',
    backgroundColor: PlatformStyles.LogoLightBlue,
    color: 'black',
    alignItems: 'center',
    borderRadius: '100px',
    lineHeight: '25px',
    fontSize: '17px',
    position: 'relative',
    // left: '100px'
    paddingLeft: '5.5px',
    right: '17px',
    top: '-70px',
    '&:hover': {
      backgroundColor: PlatformStyles.LogoLightBlueHover,
      boxShadow: PlatformStyles.BoxShadowDark,
    },
  },
}))

export default function Users({ adjustNavProfilePhoto }) {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const { userData, setUserData } = useContext(UserContext)
  const classes = useStyles()

  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams() // Passed in through URL
  let [userID, setUserID] = useState(id)
  let [user, setUser] = useState()

  const [aboutBoolean, setAboutBoolean] = useState(true)
  const [scheduleBoolean, setScheduleBoolean] = useState(false)
  const [fileId, setFileId] = useState(null)

  const [studentSchedules, setStudentSchedules] = useState([])
  const bottomBorderColor = 'solid 2px #474f7a'
  const [aboutBorder, setAboutBorder] = useState(bottomBorderColor)
  const [schedulBorder, setScheduleBorder] = useState('')
  const [openProfilePhotoSelector, setOpenProfilePhotoSelector] = useState(false)

  const defaultProfilePhoto = config.deafaultUserPath
  const [profilePictureName, setProfilePictureName] = useState(null) // Show the default user while the profile photo is loading
  const [showEditIcon, setShowEditIcon] = useState(false)

  // var Collapse = require('rc-collapse');
  // var Panel = Collapse.Panel;
  // // var React = require('react');
  // var ReactDOM = require('react-dom');
  // require('rc-collapse/assets/index.css');

  // var collapse = (
  //   <Collapse accordion={true}>
  //     <Panel header="hello" headerClass="my-header-class">this is panel content</Panel>
  //     <Panel header="title2">this is panel content2 or other</Panel>
  //   </Collapse>
  // );
  // // ReactDOM.render(collapse);

  useLayoutEffect(() => {
    // Makes sure the user is loggedin
    let loggedInCheck = false
    if (checkLoggedIn(localStorage.getItem('auth-token'))) {
      loggedInCheck = true
    } else {
      history.push('/login')
    }

    // Student's can't have access to other student's pages
    if (isStudent(userData.user.accountType) && userID.toString() !== userData.user.id.toString()) {
      history.push('/dashboard')
      return
    }

    // Once checks are passed
    if (loggedInCheck) {
      let packagedData = {
        userID,
      }

      const requests = [
        axios.post(axiosURL + '/systemSetting/getOne', { name: 'disable profile page' }),
        axios.post(axiosURL + '/users/userbyid', { userID }),
        axios.post(axiosURL + '/files/studentUserID', { userObjectID: id })
      ]

      Promise.allSettled(requests).then((responses) => {
        const systemSettingRequest = responses[0]
        const userInformationRequest = responses[1]
        const schedulesRequest = responses[2]

        if (
          systemSettingRequest.status === 'rejected' ||
          userInformationRequest.status === 'rejected' ||
          schedulesRequest.status === 'rejected'
        ) {
          history.push('/dashboard')
          notify('e', 'There was an error, please try again')
          return
        }

        // System Setting Request
        if (systemSettingRequest.value.data.success === true && systemSettingRequest.value.data.data.value === 'true') {
          history.push('/accounts')
          notify('e', 'Profile pages are currently not available, try again later')
          return
        }

        // User Information Request
        if (userInformationRequest.value.data._id === userData.user.id) {
          setShowEditIcon(true)
        }
        // *** LOOK HERE: This enables it for coordinator. decide what to do before merging
        setShowEditIcon(true)
        setUser(userInformationRequest.value.data)
        setProfilePictureName(userInformationRequest.value.data.profilePicture)
        
        // Student Schedule Request
        if (schedulesRequest.value.data.success) {
          let returnData = []
          for (let i = 0; i < schedulesRequest.value.data.data.length; i++) {
            returnData.push({
              scheduleName: schedulesRequest.value.data.data[i].fileName,
              owner: schedulesRequest.value.data.data[i].owner,
              status: [schedulesRequest.value.data.data[i].published, schedulesRequest.value.data.data[i].closed],
              created: schedulesRequest.value.data.data[i].createdAt,
              fileID:  schedulesRequest.value.data.data[i]._id,
              rowClick: {fileID: schedulesRequest.value.data.data[i]._id, fileName: schedulesRequest.value.data.data[i].fileName}
            })
          }
          setStudentSchedules(returnData)            
        } else {
          notify('e', 'Error getting student files')
          return
        }

        setIsLoading(false)
      })

      // axios.post(axiosURL + '/users/userbyid', packagedData)
      //   .then((res) => {
      //   if (res.data._id === userData.user.id) {
      //     setShowEditIcon(true)
      //   }

      //   // LOOK HERE **************************
      //   // This enables it for coordinator. decide what to do before merging
      //   setShowEditIcon(true)
      //   setUser(res.data)
      //   setProfilePictureName(res.data.profilePicture)
      //   axios.post(axiosURL + '/systemSetting/getOne', { name: 'disable profile page' }).then((res) => {
      //     if (res.data.success === true && res.data.data.value === 'true') {
      //       history.push('/accounts')
      //       notify('e', 'Profile pages are currently not available, try again later')
      //     }
          
      //   })
      // })

      // axios.post(axiosURL + '/files/studentUserID', { userObjectID: id })
      //   .then(res => {      
      //     if (res.data.success) {
      //       let returnData = []
      //       for (let i = 0; i < res.data.data.length; i++) {
      //         returnData.push({
      //           scheduleName: res.data.data[i].fileName,
      //           owner: res.data.data[i].owner,
      //           status: [res.data.data[i].published, res.data.data[i].closed],
      //           created: res.data.data[i].createdAt,
      //           fileID:  res.data.data[i]._id,
      //           rowClick: {fileID: res.data.data[i]._id, fileName: res.data.data[i].fileName}
      //         })
      //       }
      //       setStudentSchedules(returnData)            
      //     }
      //   })
    }
  }, [history, id, userData.user.accountType, userData.user.id, userID])

  if (isLoading) {
    return <Loading />
  }

  function handleViewChange(view) {
    if (view === 'about') {
      setAboutBoolean(true)
      setScheduleBoolean(false)

      setAboutBorder(bottomBorderColor)
      setScheduleBorder('')
    } else if (view === 'schedule') {   
      resetFileId()
      setScheduleBoolean(true) // Has to come before next line
      setAboutBoolean(false)

      setAboutBorder('')
      setScheduleBorder(bottomBorderColor)
    }
  }

  function resetFileId() {
    setFileId(null)
  }

  function MainContent() {
    if (aboutBoolean === true) {
      if (!isMobile) {
        return (
          <div className="userProfileAboutSection">
            <div className="userProfileBasicInformation">
              <div className="userProfileTitle">Basic Information</div>
              <div className="userProfileInformationCard">
                <div>
                  <span className="userProfileInformationDescription">Name</span>
                  <span className="userProfileInformation">
                    {user.firstName} {user.lastName}
                  </span>
                </div>
                <span className="userProfileInformationDescription">Student ID</span>
                <span className="userProfileInformation">
                  {console.log(user)}
                  {notNullString(user.studentInfo.studentID) ? user.studentInfo.studentID : getNA()}
                </span>
                <span className="userProfileInformationDescription">Age</span>
                <span className="userProfileInformation">
                  {notNullString(user.studentInfo.dateOfBirth) ? calculateAge(convertTime(user.studentInfo.dateOfBirth)) : getNA()}{' '}
                </span>
                <span className="userProfileInformationDescription">Date of Birth</span>
                <span className="userProfileInformation">
                  {notNullString(user.studentInfo.dateOfBirth) ? convertTime(user.studentInfo.dateOfBirth) : getNA()}{' '}
                </span>
                {/* <span className="userProfileInformationDescription">License Plate</span>
                <span className="userProfileInformation">
                  {notNullString(user.studentInfo.licensePlate) ? user.studentInfo.licensePlate : getNA()}{' '}
                </span> */}
              </div>
            </div>

            <div className="userProfileContactInformation">
              <div className="userProfileTitle">Contact Information</div>
              <div className="userProfileInformationCard">
                <span className="userProfileInformationDescription">Email</span>
                <span className="userProfileInformation">{user.email}</span>
                <span className="userProfileInformationDescription">Phone</span>
                <span className="userProfileInformation">
                  {notNullString(user.studentInfo.phone) ? formatPhoneNumber(user.studentInfo.phone) : getNA()}
                </span>
                <span className="userProfileInformationDescription">Address</span>
                <span className="userProfileInformation">
                  {notNullString(user.studentInfo.address.address) ? user.studentInfo.address.address : getNA()}
                </span>
              </div>
            </div>
          </div>
        )
      } else {
        // mobile:

        return (
          <div>
            <div>
              <div className="userProfileTitle">Basic Information</div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Name
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {user.firstName} {user.lastName}
                  </div>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                 Student ID
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.studentID) ? user.studentInfo.studentID : getNA()}
                  </div>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Age
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.dateOfBirth) ? calculateAge(convertTime(user.studentInfo.dateOfBirth)) : getNA()}{' '}
                  </div>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Date of Birth
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.dateOfBirth) ? convertTime(user.studentInfo.dateOfBirth) : getNA()}{' '}
                  </div>
                </div>
              </div>

              {/* <div className="mobile_rows">
                <div className="mobile_Columns">
                  <div>
                    <b>License Plate</b>
                  </div>
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.licensePlate) ? user.studentInfo.licensePlate : getNA()}{' '}
                  </div>
                </div>
              </div> */}
            </div>

            <div>
              <div className="userProfileTitle">Contact Information</div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Phone
                </div>
                <div className="mobile_Columns">
                  <span className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.phone) ? formatPhoneNumber(user.studentInfo.phone) : getNA()}{' '}
                  </span>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Address
                </div>
                <div className="mobile_Columns">
                  <span className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.address.address) ? user.studentInfo.address.address : getNA()}{' '}
                  </span>
                </div>
              </div>

              <div className="mobile_rows">
                  Email
                <div className="mobile_emailText">{user.email}</div>
              </div>
            </div>
          </div>
        )
      }
    } else if (scheduleBoolean === true) {

      function handleRowClick(e) {
        setFileId(e.rowClick.fileID)
      }
      
      return (
        <>
          {!fileId ?
            <Table title={'Schedules'} type='general' columns={scheduleColumns} data={studentSchedules} handleRowClick={handleRowClick} />
          :
            <>
              <AppointTransparentButton style={{marginBottom: '10px'}} onClick={resetFileId}>
                <img src={config.backButton} alt='Back Button' style={{width: '20px', height: '20px'}} /> Back to Schedules
              </AppointTransparentButton>
              <StudentFile fileIdOverride= {fileId} studentIdOverride={id} />
            </>
          }
        </>
        
      )
    }
  }

  function ProfilePhotoUploader(props) {
    const [file, setFile] = useState(null)
    const [photoURL, setPhotoURL] = useState(null)
    const [isTosCollapsed, setIsTosCollpased] = useState([])

    const handleChange = (e) => {
      const file = e.target.files[0]
      if (file) {
        setFile(file)
        setPhotoURL(URL.createObjectURL(file))
      }
    }

    const handleCancel = () => {
      setFile(null)
      setPhotoURL(null)
    }

    const handleSubmit = async (submitFile, url) => {
      let newFile = await new File([submitFile], 'test.jpeg', { type: 'image/jpeg', encoding: '7bit' })
      newFile.originalName = 'file.jpeg'

      // https://stackoverflow.com/questions/57265654/how-to-convert-image-data-to-multipart-form-data
      let formData = await new FormData()
      await formData.append('file', newFile)
      await formData.append('userId', userID)

      axios
        .post(axiosURL + '/photoUpload/upload', formData, { headers: { 'content-type': 'multipart/form-data;boundary=${submitFile.boundary}' } })
        .then((res) => {
          if (res.data.success) {
            setProfilePictureName(res.data.file.filename)
            // If current user, we are going to adjust the application nav
            if (userData.user.id == userID) {
              adjustNavProfilePhoto(res.data.file.filename)
            }
            notify('s', 'Photo Uploaded')
          } else {
            notify('e', 'There was an error, refresh page and try again')
          }
          setFile(null)
          setPhotoURL(null)
          props.onHide()
        })
    }

    const handleDelete = () => {
      if (profilePictureName == null) return

      const packagedData = {
        userID,
        fileName: profilePictureName,
      }
      axios.post(axiosURL + '/photoUpload/delete', packagedData).then((res) => {
        if (res.data.success) {
          setProfilePictureName(null)
          // If current user, we are going to adjust the application nav
          if (userData.user.id == userID) {
            adjustNavProfilePhoto(null)
          }

          setFile(null)
          setPhotoURL(null)
          props.onHide()
          notify('s', 'Photo Deleted')
        } else {
          props.onHide()
          notify('e', 'There was an error, refresh page and try again')
        }
      })
    }

    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName={!isMobile ? '' : 'modalForMobile'}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Photo Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center' }}>
            {!file ? (
              <>
                <div>
                  <label htmlFor="profilePhoto">
                    <input accept="image/*" id="profilePhoto" type="file" style={{ display: 'none' }} onChange={handleChange} />
                    <span class="deletebuttongreen noselect">
                      <span class="text">Upload Photo</span>
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path
                            class="checkmark__check"
                            d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </label>
                </div>
                &nbsp;
                <div>
                  <label>
                    <span class="deletebutton noselect" onClick={handleDelete}>
                      <span class="text">Delete Photo</span>
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                        </svg>
                      </span>
                    </span>
                  </label>
                </div>
                &nbsp;
                <p className={classes.LegalNotice}>By uploading an image, you acknowledge and agree to our terms of use:</p>
              </>
            ) : (
              <CropEasy {...{ photoURL, setPhotoURL, setFile, handleSubmit, handleCancel }} />
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Collapse accordion={true} activeKey={isTosCollapsed} onChange={setIsTosCollpased}>
            <Collapse.Panel header={<div className="viewTOSbutton">View Terms of Service</div>} key="1">
              <div>
                <p>
                  1. By uploading a photo to your profile, you confirm that you own the rights to the photo or have obtained permission from the
                  copyright holder to use it.
                  <br></br>
                  <br></br>
                  2. You agree that the photo will not contain any offensive or illegal content, including but not limited to hate speech, sexually
                  explicit material, or violence.
                  <br></br>
                  <br></br>
                  3. You grant the website the right to use, display, and distribute the photo in connection with your profile and the website's
                  services.
                  <br></br>
                  <br></br>
                  4. The website reserves the right to remove or disable access to any photo that violates these terms and conditions or is otherwise
                  deemed inappropriate.
                  <br></br>
                  <br></br>
                  5. You agree to indemnify and hold harmless the website and its affiliates from any claims arising out of or related to your use of
                  the photo.
                  <br></br>
                  <br></br>
                  6. These terms and conditions may be updated from time to time, and your continued use of the website after any changes indicates
                  your acceptance of the new terms.
                  <br></br>
                  <br></br>
                  7. You are responsible for ensuring that your photo does not infringe any third-party rights, including but not limited to
                  intellectual property rights, privacy rights, and personality rights.
                  <br></br>
                  <br></br>
                  8. Any disputes arising out of or related to this agreement or your use of the website will be resolved in accordance with the laws
                  of the jurisdiction in which the website is headquartered.
                  <br></br>
                  <br></br>
                  9. By uploading a photo, you confirm that you have read and understand these terms and conditions and agree to be bound by them.
                </p>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <ProfilePhotoUploader
        show={openProfilePhotoSelector}
        onHide={() => {
          setOpenProfilePhotoSelector(false)
        }}
      />

      <AppointTileWrapper className="userProfile">
        <div className={isMobile ? 'Mobile_userProfilePictureSection' : 'userProfilePictureSection'}>
          <div className={isMobile ? 'center' : ''}>
            <ProfilePicture fileName={profilePictureName} size={'xlarge'} boxShadow={PlatformStyles.BoxShadow} />

            {showEditIcon && <EditOutlined onClick={() => setOpenProfilePhotoSelector(true)} className={classes.EditIcon} />}
          </div>
        </div>

        <div className={'userProfilePictureSection'}>
          <div>
            <div className={isMobile ? 'center' : 'userProfileNameAddress'}>
              <div className="userProfileName">
                {user.firstName} {user.lastName}
              </div>
              <div className="userProfileYear">{user.studentInfo.classYear}</div>
            </div>
          </div>
        </div>

        <div className="userProfileViewSelector">
          <button className="userProfileButton" onClick={() => handleViewChange('about')} style={{ borderBottom: aboutBorder }}>
            About
          </button>

          {userData.user.accountType !== 'student' && (
            <button className="userProfileButton" onClick={() => handleViewChange('schedule')} style={{ borderBottom: schedulBorder }}>
              Schedules
            </button>
          )}
        </div>

        <div className="userProfileMainContent">
          <MainContent />
        </div>
      </AppointTileWrapper>
    </>
  )
}

const scheduleColumns = [
  {
      title: "Schedule Name",
      field: "scheduleName",
      headerStyle: {paddingLeft: '30px'},
      cellStyle: {paddingLeft: '30px'}
  },
  {
      title: "Status",
      field: "status",
      render: (rowData) => <div>{StudentFilePills(rowData.status[0], rowData.status[1])}</div>,
  },
  {
      title: "Created",
      field: "created",
  },
  {
    title: "Owner",
    field: "owner",
  }
];
