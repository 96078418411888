import React, { Component } from 'react'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import { useLayoutEffect, useState, useEffect } from 'react'
// https://codesandbox.io/s/react-editext-template-5twrh?fontsize=14&hidenavigation=1&theme=dark&file=/src/index.js:156-202
import {
    EditOutlined
  } from '@ant-design/icons';
import { secondsToTime } from '../misc/secondsToTime'
import './StudentFile.css'
import {isMobile} from 'react-device-detect';
import { normalizeTime } from '../../functions/normalizeTime';
import { AppointTileWrapper } from '../../utils/AppointTileWrapper';
import openInGoogleMaps from '../../functions/openInGoogleMaps';


export default function StudentFileCard(props) {
    const [editing, setEditing] = useState(false);
    const [clinicalData, setClinicalData] = useState({type: '', section: '', startTime: 0, endTime: 0, startDate: '', endDate: '', inpatientDays: [], location: {name: '', address: ''}})
    const [isLoading, setIsLoading] = useState(true)

    const event = props.data
    // event.weekDays = event.weekDays.join(', ')

    /// This function solves an issue where clinicals that had a single weekday were causing the page not to load
        /// But without using a .join() function, the weekdays are displayed without any space between them.
    function clinicalDaysString(e){ 
        let tmpString = ''
    
        if (typeof e !== 'undefined'){
            for (var i = 0; i < e.length -1; i++){
                tmpString += e[i]
                tmpString += ", "
            }
            tmpString += e[e.length-1]
            
            return tmpString
        }       
        else{
            return 'Error'
        }
    }

    function renderClinicalDays(inpatientDays) {
      if (inpatientDays.length === 0) {
        return 'All Days'
      } else {
        return clinicalData.inpatientDays.join(', ')
      }
    }

    useEffect(() => {
      console.log('props.type', props.type)
        if(props.type === 'clinical'){
            // Takes the info passed and gets the rest of the information for that clinical
            // For Classes, all the info we need is passed` so we do nothing.
            const packagedData = {
                fileObjectID: props.fileObjectID,
                clinicalObjectID: props.data._id,
            }
            console.log('here')
            axios.post(axiosURL + '/files/GetClinicalRequest', packagedData)
            .then(res => {
                console.log(res)
                setClinicalData(res.data.data)
                setIsLoading(false)
            })  
        } else {
          setIsLoading(false)
        }
    }, [])

    function renderClinicalDays(inpatientDays) {
      if (inpatientDays.length === 0) {
        return 'All Days'
      } else {
        return clinicalData.inpatientDays.join(', ')
      }
    }

    if (isLoading) {
      return <></>
    }
    
    if (props.type === 'class') {
        return(
            <div className='studentFileClassCard'>
                <div className='studentFileCardClassName'>
                    {event.className}
                    <EditOutlined className='studentCardIcon' onClick={() => props.handleEdit(props.index, event)} />
                </div>
                <div className='lineAcross'></div>
                <div className='label'>Location:</div>
                <div className='studentFileCardInfo'>
                    {event.location}
                </div>
                <div className='label'>Class Time:</div>
                <div className='studentFileCardInfo'>
                    {secondsToTime(event.startTime)} - {secondsToTime(event.endTime)}
                </div>
                <div className='label'>Week Days:</div>
                <div className='studentFileCardInfo'>
                    {event.weekDays.join(', ')}
                </div> 
            </div>
            ) 
        
    } else if(props.type === 'clinical'){
        return(
            <AppointTileWrapper
              style={{
                backgroundColor: '#c4d4dc',
                margin: '10px',
                padding: '10px',
                minWidth: '300px'
              }}>
                <div className='cardClinicalType'>{clinicalData.type} {clinicalData.section}</div>
                <div className='lineAcross'></div>
                <div className='label'>Clinical Time: </div>
                <div className='cardClinicalData'>
                    {secondsToTime(clinicalData.startTime)} - {secondsToTime(clinicalData.endTime)}</div>
                <div className='label'>Week Days: </div>
                <div className='cardClinicalData'>{clinicalDaysString(clinicalData.weekDays)}</div>
                <div className='label'>Start - End Date:</div>
                <div className='studentFileCardInfo'>
                    {normalizeTime(clinicalData.startDate)} - {normalizeTime(clinicalData.endDate)}
                </div>
                <div className='label'>Clinical Days:</div>
                <div className='studentFileCardInfo'>
                    {renderClinicalDays(clinicalData.inpatientDays)}
                </div>   
                <div className='label'>Location:</div>
                <div className='studentFileCardInfo'>
                    {clinicalData.location.name}
                    <br></br>
                    {clinicalData.location.address}
                </div>  
                <span style={{fontSize: '14px', float: 'right', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => openInGoogleMaps(clinicalData.location.address, null)}>Open in Google Maps</span>
            </AppointTileWrapper>
            ) 
    } else if(props.type === 'blockedTime'){
        return(
            <div className='studentFileClassCard'>
                <div className='studentFileCardClassName'>
                    {event.summary}
                    <EditOutlined className='studentCardIcon' onClick={() => props.handleEdit(props.index, event)} />
                </div>
                <div className='lineAcross'></div>
                <div className='label'>Commitment Time:</div>
                <div className='studentFileCardInfo'>
                    {secondsToTime(event.startTime)} - {secondsToTime(event.endTime)}
                </div>
                <div className='label'>Week Days:</div>
                <div className='studentFileCardInfo'>
                    {event.weekDays.join(', ')}
                </div>  
                <div className='label'>Reason:</div>
                <div className='studentFileCardInfo'>
                    <p>
                        <textarea id="reason" name="reason" placeholder='Detailed Explanation' rows='3' value={event.reason} type="text" className='@@'/>
                    </p>
                </div>  
            </div>
            ) 
    }
  
}