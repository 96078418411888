import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import UserContext from '../../context/UserContext'
// import { Button, Container, Col, Row, OverlayTrigger, Jumbotron, Modal } from  'react-bootstrap'
import { useHistory, Link } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Table from '../Users/Table'
import loggedIn from '../../functions/checkLoggedIn'
import './Dashboard.Student.css'
import Loading from '../Loading/Loading';
import { StudentFilePills } from '../misc/FilePills';

import '../StudentFile/StudentFile.css'

export default function StudentDashboard() {
  const history = useHistory();
  /* Sets user context */
  const {userData } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  let [files, setFiles] = useState([])

  useLayoutEffect(() =>{
    // Checks that the user is logged in. If not, pushes them to the login page
    if(!loggedIn(localStorage.getItem("auth-token"))){history.push('/login')}
    if (userData.user.id != undefined) {
      
      const packagedData = {
        userObjectID: userData.user.id
      }

      axios.post(axiosURL + '/files/studentUserID', packagedData)
      .then(res => {
        if (res.data.success) {
          // Filters the files to only the ones that the student is in
          let returnData = []
          // Collects all the information for the student so that we do not have to when student open file
          for (let i = 0; i < res.data.data.length; i++) {
            // Adds each file to data variable used to create table
            // Also adds a link to view the student file and passes the props needed as well
            returnData.push({
              scheduleName: res.data.data[i].fileName,
              owner: res.data.data[i].owner,
              status: [res.data.data[i].published, res.data.data[i].closed],
              created: res.data.data[i].createdAt,
              fileID:  res.data.data[i]._id,
              rowClick: {fileID: res.data.data[i]._id, fileName: res.data.data[i].fileName}
            })
          }
          setFiles(returnData)
          setIsLoading(false)
        }
      })
    }
          
  }, [isLoading, userData])

  if(isLoading){
    return <Loading />
  }

  const columns = [
    {
        title: "Schedule Name",
        field: "scheduleName",
        headerStyle: {paddingLeft: '30px'},
        cellStyle: {paddingLeft: '30px'}
    },
    {
        title: "Status",
        field: "status",
        render: (rowData) => <div>{StudentFilePills(rowData.status[0], rowData.status[1])}</div>,
    },
    {
        title: "Created",
        field: "created",
    },
    {
      title: "Owner",
      field: "owner",
    }
  ];

  function handleRowClick(e){
    history.push('/dashboard/studentfile/' + e.rowClick.fileID + '/' + userData.user.id);
  }

  return(
    <div className=''>
        <Table data={files} columns={columns} type='studentDashboard' handleRowClick={handleRowClick} />
    </div>
  )
}
