import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuItem } from "@mui/material";
import { Popper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { axiosURL } from '../../axiosURL_Front';
import PlatformStyles from '../../PlatformStyles';
import isNullorUndefined from '../../functions/isNullOrUndefined';


const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 9999, // Increase the z-index so that the popper appears on top of other elements
    borderRadius: PlatformStyles.BorderRadius,
    boxShadow: PlatformStyles.BoxShadowDark
  },
  menuItem: {
    fontSize: '13.5px', // Set the font size to 12px
    minWidth: '300px',
    backgroundColor: 'white', // Set the background color to white
    "&:hover": {
      backgroundColor: 'lightgray', // Set the background color to light grey on hover
    },
  },
}))

const AddressAutocomplete = ({ value, onChange, style, isExpired }) => {
  const classes = useStyles()
  const [options, setOptions] = useState([]);
  const [focused, setFocused] = useState(false);
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (value?.address === '' || isNullorUndefined(value)) {
      setOptions([])
    } else {
      // We want to wait until the user stops typing so that we don't make a ton of requests
      const delayDebounceFn = setTimeout(() => {      
        getOptions(value?.address)
      }, 300)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [value?.address])

  const getOptions = (address) => {
    axios.post(axiosURL + "/files/AddressAutoCompleteRequest", { address })
    .then (res => {
      setOptions(res.data.data);
    }).catch(err => {
      console.log(err)
    })     
  }

  const handleAddressChange = (address, placeId) => {
    onChange({
      address,
      placeId
    })
  }

  const handleChange = (event) => {
    handleAddressChange(event.target.value, null);
  };

  const handleSelect = (option) => {
    handleAddressChange(option.address, option.placeId)
    setOptions([])
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    // Makes it so the popper doesn't close too quick for a user to click something
    setTimeout(() => {
      setFocused(false);
    }, 200)
  };

  const isError = () => {
    if ((value?.address !== '' && typeof value?.address !== 'undefined' && value?.address !== null) && !value?.placeId) {
      return true
    }
    return false
  }
  
  return (
    <div style={style}>
      <TextField
        disabled={isExpired}
        label="Address"
        value={value?.address}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        required
        fullWidth
        error={isError()}
        inputRef={textFieldRef}
        inputProps={{
          autoComplete: 'off',
        }}
      />
      <Popper className={classes.popper} open={focused} anchorEl={textFieldRef.current} placement="bottom">
          {options.map((option) => (
            <MenuItem className={classes.menuItem} key={option.placeId} onClick={() => handleSelect(option)}>
              {option.address}
            </MenuItem>
          ))}
          {options.length === 0 && focused === true  &&
            <MenuItem className={classes.menuItem}>
              {isNullorUndefined(value) || value?.address === '' ?
                <i>Start typing</i>
                :
                <i>Address not found</i>
              }
            </MenuItem>
          }
      </Popper>
    </div>
  );
};

export default AddressAutocomplete;
