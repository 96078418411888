import { makeStyles } from '@mui/styles';
import axios from "axios"
import { useEffect, useState } from "react"
import { axiosURL } from "../../axiosURL_Front"
import config from "../../config"
import PlatformStyles from "../../PlatformStyles"

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: PlatformStyles.BorderRadius
  },
  small: {
    width: '40px',
    height: '40px',
  },
  medium: {
    width: '80px',
    height: '80px',
  },
  large: {
    width: '100px',
    height: '100px',
  },
  xlarge: {
    width: '200px',
    height: '200px',
  }
}));

// TODO: implement react use memo eventually
export function ProfilePicture ({ fileName, size, boxShadow }) {
  const classes = useStyles();

  const [imageSrc, setImageSrc] = useState(config.deafaultUserPath)

  useEffect(() => {
    // eslint-disable-next-line no-self-compare
    if (typeof fileName != 'undefined' && fileName != null && fileName !== '') {
      setImageSrc(config.spinnerIcon)
      axios.get(axiosURL + '/photoUpload/image/' + fileName)
      .then(res => {
        setImageSrc(res.data)
      })
    } else {
      setImageSrc(config.deafaultUserPath)
    }

  }, [fileName])
  
  return (
    <img className={`${classes.wrapper} ${classes[size]}`} style={{boxShadow: boxShadow}} alt='User' src={imageSrc} />
  )
}