import { notNullString } from "./notNullString"

// Does what it sounds like
export default function convertTime(time){   
    // Handles default values 
    if (!notNullString(time)) {
        return('')
    }
    
    var d = new Date(time) // Passed in
    var t = new Date() // Today
    var returnDate
    
    if (d.getDate() === t.getDate() && d.getMonth() === t.getMonth() && d.getFullYear() === t.getFullYear()){
        var seconds = (d.getHours() * 3600) + (d.getMinutes() * 60)
        return(secondsToTime(seconds))
    } else if (d.getFullYear() === t.getFullYear()){
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        returnDate = months[d.getMonth()] + " " + d.getDate()
    } else {
        returnDate = d.getMonth()+1 + '/' + d.getDate() + '/' + d.getFullYear().toString().substring(2)
    }
    return(returnDate)
}

function secondsToTime(seconds){
    var returnValue
  
    if (0 <= seconds && seconds < 3600){
        returnValue = '12:' + doubleDigit(seconds/60) + ' AM'
    } else if(3600 <= seconds  && seconds < 43200){
        let tempHours = Math.trunc(seconds/3600)
        let tempMinutes = doubleDigit(Math.trunc((seconds%3600)/60))
        returnValue = tempHours.toString() + ':' + tempMinutes + ' AM'
    } else if (43200 <= seconds && seconds < 46800){
        returnValue = '12:' + doubleDigit(((seconds-43200)/60)) + ' PM'    
    } else {
        let tempHours = Math.trunc((seconds/3600) - 12)
        let tempMinutes = doubleDigit(Math.trunc((seconds%3600)/60))
        returnValue = tempHours.toString() + ':' + tempMinutes + ' PM'
    }        
    return returnValue
  }

function doubleDigit(x){
    // if num between 1 - 9, converts to two digit format
    if (x.toString().length == 1){
        return "0" + x.toString()
    } else {
        return x.toString()
    }
}