
// Depends on toast being present in App.js\

import { toast } from 'react-toastify';

export function notify(type, s) {

    type = type.toLowerCase()
    switch(type){
        case 's':
        case 'success':
            toast.success(s)
            break

        case 'e':
        case 'error':
            toast.error(s)
            break

        case 'w':
        case 'warn':
            toast.warn(s)
            break

        case 'i':
        case 'info':
            toast.info(s)
            break
    }
}