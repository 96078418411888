import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { isMobile } from 'react-device-detect';
import PlatformStyles from "../../../PlatformStyles";
import '../Content/Content.css'

import config from "../../../config";

function renderTeamMembers(personal_name, position, text){
  return (
    <div className='OurTeam_KeyPointsButton' style={{marginBottom: isMobile ? '200px' : ''}}>
      <div className='OurTeam_KeyPointsButtonName'>
        {personal_name}
      </div>
      <div className='OurTeam_KeyPointsButtonTitle'>
        {position}
      </div>
      <div className='OurTeam_KeyPointsButtonText'>
        {text}
      </div>
    </div>
    )
}

function Card({title, image, currentSlide, currentIndex, position, text}) {

  const [show, setShown] = useState(false);

  const styles = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div
      style={{ ...styles, height: "fit-content" }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)} 
    >
      <div
        style={{
          background: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(22px)",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", width: "100%", height: "100%" }}>
          <img
            src={config.SquareBorder1}
            alt="SquareBorder1"
            style={{ objectFit: "fill", width: "100%", height: isMobile ? "75%" : "100%" }}
          />
        </div>

        <div
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 300,
              height: 300,
              overflow: "hidden",
              position: "relative",
              borderRadius: PlatformStyles.BorderRadiusWoImportant,
            }}
          >
            <img
              src={image}
              alt="C SUITE MEMBER"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
            
          </div>

          {renderTeamMembers(title, position, text)}

          {/* {title && (
            <div
              style={{
                marginTop: 24,
                fontWeight: 600,
                fontSize: 28,
                lineHeight: "150%",
                color: "#FDFDFD",
              }}
            >
              {title}
            </div>
          )} */}
        </div>
      </div>
    </animated.div>
  );
}

export default Card;
