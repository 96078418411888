import {
  CommentOutlined, InsertRowAboveOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ToolOutlined, UserOutlined
} from '@ant-design/icons';
import { Menu as MuiMenu, List, ListItem, ListItemText, MenuItem } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Divider, Drawer, ListItemButton, ListItemIcon } from '@mui/material';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.min.css';
import { Fragment, React, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import config from '../../config';
import UserContext from '../../context/UserContext';
import checkLoggedIn from '../../functions/checkLoggedIn';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ClinicalAssistant from '../ClinicalAssistant/ClinicalAssistant';
import Dashboard from '../Dashboard/Dashboard';
import SuperUserEntityView from '../Dashboard/Dashboard.SuperUser';
import Feedback from '../Feedback/Feedback';
import { AppointLogo } from '../misc/AppointLogo';
import { ProfilePicture } from '../misc/ProfilePicture';
import Reporting from '../Reporting/reporting';
import Settings from '../Settings/settings';
import StudentFile from '../StudentFile/StudentFile';
import Entity from '../SuperUserComponents/Entity';
import SystemSettings from '../SuperUserComponents/SystemSettings';
import UserProfile from '../Users/UserProfile';
import Users from '../Users/Users';
import './ApplicationNav.css';
import { isAdministrator, isSuperUser } from '../../functions/UserTypeChecks';
import ClinicalSites from '../ClinicalSites/ClinicalSites';
import ProgressBar from '../misc/ProgressBar';
import Logger from '../../utils/Logger';


// import styles from './random.less'

// BUG - refreshing page on Clinical Assistant resets sidenav collpse
// Leaving for now since I do not view this as worth my time right now

// Animations and other links
// https://antd-admin.zuiidea.com/dashboard
// https://ant.design/components/form/
// https://codesandbox.io/s/cz78z
// https://www.freecodecamp.org/news/how-to-create-a-reusable-fade-in-animation-component-in-react-with-gsap/
// https://codesandbox.io/s/react-gsap-fadein-effect-z8xqd?file=/src/App.js

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu

export default function ApplicationNav(props) {
  // Needed if first time loading the page the sidebar wont have a setting
  const [collapsed, setCollapsed] = useState(true)
  const {userData, setUserData, isMobile} = useContext(UserContext)
  const [currentPage, setCurrentPage] = useState("1")
  const [width, setWidth] = useState('0px')
  const [profileMenuOpen, setProfileMenuOpen] = useState(null)
  let [profilePicture, setProfilePicture] = useState(userData.user && userData.user.profilePicture ? userData.user.profilePicture : null)
  const history = useHistory();

  function toggleSider(){
    setCollapsed(!collapsed)
  };

  // Selects the current page. Important for when reloading. UseLayoutEffect takes place before component render and updates things visually
  useLayoutEffect(()=> {
    setCurrentPage(window.location.pathname.split('/')[1])
  }, [])

  // Changes margins when collapsed is changed
  useEffect(()=> {
    if (!isMobile) {
      if (collapsed === false){
        setWidth("275px")
      } else {
        setWidth('80px')
      }
    }  
    
  }, [collapsed])

  const signout = () => {
    localStorage.setItem("auth-token", ""); /* Removes token from website cache*/
    setUserData({ /* resets user data in program */
        token:undefined,
        user: undefined
    })
    history.push('/login')
  }

  // Where we checked if the user is logged in. Any page using application nav should require the user to be logged in.
  if(!checkLoggedIn(localStorage.getItem('auth-token'))){history.push('/login') ;return(<div></div>)}

  function handleSwitch(destination){
    let url
    if (destination === 'profile') { // student is the only one that uses this right now so don't need to add /accounts
      url = '/profile/' + userData.user.id;
    } else {
      url = '/' + destination;
    }
    Logger.systemLog('User Navigate', userData.user.username + ', School Id: ' + userData.user.schoolID + ', navigated to ' + url)
    history.push(url);
    return;
  };
 

  // Username and profile photo - Used in Header
  const rightContent = [
      <>
        <List
            component="nav"
            aria-label="Device settings"
            style={{marginRight: '15px'}}
          >
          <ListItem
            button
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-label="when device is lockeds"
            aria-expanded={profileMenuOpen ? 'true' : undefined}
            onClick={(e) => setProfileMenuOpen(e.currentTarget)}
          >
            <ListItemText
              primary={
                <Fragment>
                  <span style={{marginRight: '10px'}}>{userData.user.username}</span>
                  {/* <Avatar style={{ marginLeft: 8 }} src={config.deafaultUserPath} /> */}
                  <ProfilePicture fileName={profilePicture} size={'small'} />
                </Fragment>
              }
              // secondary={<span style={{display: 'flex'}}>{capitalize(userData.user.accountType)}</span>} // Leaving here to know you can add subtext to this
            />
          </ListItem>
        </List>
          <MuiMenu
            disableScrollLock={true}
            style={{marginTop: '50px'}}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            anchorEl={profileMenuOpen}
            open={Boolean(profileMenuOpen)}
            onClose={() => setProfileMenuOpen(null)}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
            }}
          >
            <MenuItem
              style={{width: '200px'}}
              onClick={signout}
            >
              Sign Out
            </MenuItem>
          </MuiMenu>
      </>
  ]

  // rightContent.unshift(
  //   <Popover
  //     placement="bottomRight"
  //     trigger="click"
  //     key="notifications"
  //     overlayClassName='notificationPopover'
  //     // getPopupContainer={}
  //     content={
  //       <div className=''>
  //         <List
  //           itemLayout="horizontal"
  //           dataSource={notifications}
  //           locale={{
  //             emptyText: "You have viewed all notifications.",
  //           }}
  //           renderItem={item => (
  //             <List.Item className=''>
  //               <List.Item.Meta
  //                 title={
  //                   item.title
  //                 }
  //                 description={moment(item.date).fromNow()}
  //               />
  //               <RightOutlined style={{ fontSize: 10, color: '#ccc' }} />
  //             </List.Item>
  //           )}
  //         />
  //         {notifications.length ? (
  //           <div
  //             // onClick={}
  //             // className={}
  //           >
  //             Clear notifications
  //           </div>
  //         ) : null}
  //       </div>
  //     }
  //   >
  //     <Badge
  //       count={notifications.length}
  //       dot
  //       offset={[-10, 10]}
  //       className='iconButton'
  //     >
  //       <BellOutlined className='iconFont' />
  //     </Badge>
  //   </Popover>
  // )

  function adjustNavProfilePhoto (newValue) {
    userData.user.profilePicture = newValue
    setProfilePicture(newValue)
  }
  const MobileList = (anchor) => (
    <Box
      sx={{ width: '80vw', maxWidth: '400px'}}
      role="presentation"
    >
       <List>
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <AppointLogo blueVarient={true} />
        </div>
        <Divider />

        {(() => {
          switch (userData.user.accountType) {
            case "student":   return (
              <>
                <ListItem key={"dashboard"} disablePadding onClick={() => {toggleSider(); handleSwitch('dashboard')} }>
                  <ListItemButton>
                    <ListItemIcon>
                      <InsertRowAboveOutlined className='menuItemIcon' />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={"profile"} disablePadding onClick={() => {toggleSider(); handleSwitch('profile')} }>
                  <ListItemButton>
                    <ListItemIcon>
                      <UserOutlined className='menuItemIcon' />
                    </ListItemIcon>
                    <ListItemText primary={"Profile"} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={"feedback"} disablePadding onClick={() => {toggleSider(); handleSwitch('feedback')} }>
                  <ListItemButton>
                    <ListItemIcon>
                      <CommentOutlined className='menuItemIcon' />
                    </ListItemIcon>
                    <ListItemText primary={"Feedback"} />
                  </ListItemButton>
                </ListItem>
              </>
              );
            case 'administrator': return(
              <>
                <ListItem key={"dashboard"} disablePadding onClick={() => {toggleSider(); handleSwitch('dashboard')} }>
                  <ListItemButton>
                    <ListItemIcon>
                      <InsertRowAboveOutlined className='menuItemIcon' />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={"users"} disablePadding onClick={() => {toggleSider(); handleSwitch('accounts')} }>
                  <ListItemButton>
                    <ListItemIcon>
                      <UserOutlined className='menuItemIcon' />
                    </ListItemIcon>
                    <ListItemText primary={"Users"} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={"feedback"} disablePadding onClick={() => {toggleSider(); handleSwitch('feedback')} }>
                  <ListItemButton>
                    <ListItemIcon>
                      <CommentOutlined className='menuItemIcon' />
                    </ListItemIcon>
                    <ListItemText primary={"Feedback"} />
                  </ListItemButton>
                </ListItem>
              </>
              )
            default:
          }
        })()}

        <Divider />
        <ListItem key={'Close'} disablePadding onClick={toggleSider}>
            <ListItemButton>
              <ListItemIcon>
                <HighlightOffIcon />
              </ListItemIcon>
              <ListItemText primary={'Close'} />
            </ListItemButton>
          </ListItem>
        </List>
      {/* Leaving this here since the divider is a good visual that we can use. Uncomment to see */}
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
     
    </Box>
  );

  return (
    <Layout>
      {isMobile ?
      <div>
        <Drawer
          anchor={'left'}
          open={!collapsed}
          onClose={() => setCollapsed(true)} // Closes the drawer if you click outside the window
        >
          {MobileList('left')}
        </Drawer>
    </div>
      :
      <Sider trigger={null} collapsible collapsed={collapsed} width={width} className='sider' >
        
        <div className='brand' onClick={() => {history.push('/')}}>
            {collapsed? 
              <AppointLogo compact={true} />
            :
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <AppointLogo height={'70px'} blueVarient={true} />
            </div>
            }
        </div>
        <Menu theme='light' mode="inline" defaultSelectedKeys={currentPage} key={currentPage} className='menu'>
            <Menu.Item key="dashboard" icon={<InsertRowAboveOutlined className='menuItemIcon' />} className='menuItem' onClick={() => { handleSwitch('dashboard') }}>
              Dashboard
            </Menu.Item>
            {/* Changes options depending on account type */}
            {(() => {
              switch (userData.user.accountType) {
                case "student": return (
                  <Menu.Item key="profile" icon={<UserOutlined className='menuItemIcon' />} className='menuItem' onClick={e => { handleSwitch('profile') }}>
                    Profile
                  </Menu.Item>);
                case 'administrator': return (
                  <Fragment>
                    <Menu.Item key="accounts" icon={<UserOutlined className='menuItemIcon' />} className='menuItem' onClick={e => { handleSwitch('accounts') }}>
                      Users
                    </Menu.Item>
                    <Menu.Item key="clinicalsites" icon={<img alt='Clinical Site' style={{width: '19px'}} src={config.hospitalIcon} />} className='menuItem' onClick={e => { handleSwitch('clinicalsites') }}>
                      Clinical Sites 
                    </Menu.Item>
                  </Fragment>);
                case 'superUser': return (
                  <Fragment>
                    <Menu.Item key="clinicalsites" icon={<img alt='Clinical Site' style={{width: '19px'}} src={config.hospitalIcon} />} className='menuItem' onClick={e => { handleSwitch('clinicalsites') }}>
                      Clinical Sites 
                    </Menu.Item>
                    <Menu.Item key="systemsettings" icon={<ToolOutlined className='menuItemIcon' />} className='menuItem' onClick={e => { handleSwitch('systemsettings') }}>
                      System Settings
                    </Menu.Item>
                  </Fragment>);
                default: return (
                    <></>
                  )
              }
            })()}
            <Menu.Item key="feedback" icon={<CommentOutlined className='menuItemIcon' />} className='menuItem' onClick={() => { handleSwitch('feedback') }}>
              Feedback
            </Menu.Item>
        </Menu>
      </Sider>
      }
      <Layout className="site-layout layout">
        <Header className="navHeader" style={{marginLeft: width, transition: 'margin-left .2s', padding: isMobile ? '7px': '15px'}}>
            <div className='trigger' onClick={e => toggleSider()}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <div className="rightContainer">{rightContent}</div>
        </Header>

        {/* Where the page content is */}
        <Content className="site-layout-background mainContent" style={{marginLeft: width, transition: 'margin-left .2s'}}>
          <Breadcrumbs />
          <Switch>
            <Route exact path='/dashboard' component={Dashboard}/>
            <Route exact path='/dashboard/clinicalassistant/:id' render={props => <ClinicalAssistant />} />
            <Route exact path='/dashboard/entity/:id/clinicalassistant/:id' render={props => <ClinicalAssistant />} />
            <Route exact path='/dashboard/studentfile/:fileid/:studentid' component={StudentFile}/>
            <Route exact path='/dashboard/clinicalassistant/:id/studentfile/:fileid/:studentid' component={StudentFile}/>

            <Route exact path='/accounts' component={Users}/>
            <Route exact path='/profile/:id' render={props => <UserProfile adjustNavProfilePhoto={adjustNavProfilePhoto} />}/>
            <Route exact path='/accounts/profile/:id' render={props => <UserProfile adjustNavProfilePhoto={adjustNavProfilePhoto} />}/>
            <Route exact path='/dashboard/entity/:id/profile/:id' render={props => <UserProfile adjustNavProfilePhoto={adjustNavProfilePhoto} />}/>
            
            <Route exact path='/reporting' component={Reporting}/>
            <Route exact path='/settings' component={Settings}/>
            <Route exact path='/dashboard/entity/:id' render={(props) => <Entity {...props} />}/>
            <Route exact path='/systemsettings' component={SystemSettings}/>
            
            <Route exact path='/clinicalsites' render={(props) => {
                if(isAdministrator(userData.user.accountType)) {
                  return <ClinicalSites />
                } else if (isSuperUser(userData.user.accountType)) {
                  return <SuperUserEntityView type={2} />
                }
              }}/>
            <Route exact path='/clinicalsites/entity/:id' render={(props) => <Entity {...props} />}/>
            <Route exact path='/feedback' component={Feedback}/>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
