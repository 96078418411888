import React, { useEffect, useLayoutEffect, useState } from 'react'
import ContactUs from './Content/ContactUs'
import FeatureButtons from './Content/FeatureButtons'
import Features from './Content/Features'
import Footer from './Content/Footer'
import Header from './Content/Header'
import OurGoal from './Content/OurGoal'
// import OurTeam from './Content/OurTeam'
import OurTeamCarousel from './Content/OurTeam'
import './Home.css'

export default function Home(props) {

  const [renderButtonDivWidth, setRenderButtonDivWidth] = useState('100%')

  useEffect(() => {
    window.addEventListener("resize", handleRenderButtonResize, false);
  }, [])

  const handleRenderButtonResize = () => {
    if(window.innerWidth > 1500){
      setRenderButtonDivWidth("100%")
    } else if(1000 <= window.innerWidth && window.innerWidth < 1500){
      setRenderButtonDivWidth("1000px")
    } else if(0 <= window.innerWidth && window.innerWidth < 1000){
      setRenderButtonDivWidth("500px")
    }
  }

  useLayoutEffect(() => {
    const handleScroll = (e) => {
      // Finds how far the user has scrolled down and if it is passed the height of the image (700px), changes the color of the navbar
      const cutoff = 450
      if(window.scrollY > cutoff){
        props.handleNavColor(true)
      } else if(window.scrollY < cutoff){
        props.handleNavColor(false)
      }

      // Find the bottom of the page
      // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return(
    <div className='HomePage_MainDiv'>
      <Header />
      <FeatureButtons />
      <Features />
      <OurTeamCarousel/>
      {/* <OurTeam/> */}
      <div id="contact_us_position"></div>
      <ContactUs/>
      <OurGoal/>
      <Footer />
      {/* <img id="home_logoSpinner" src={appointLogoAnimation} className="home_logoSpinner" alt="" /> */}
    </div>
  )
}