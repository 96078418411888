import React, { Component } from 'react'

export default class Products extends Component {
  constructor(props){
    super(props)
  };
  
  render(){
    return(
      <div style ={{backgroundColor:'gray', width:'100%', textAlign:'center'}}>
      </div>
    )
  }
}