import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {
  UpOutlined, DownOutlined
} from '@ant-design/icons';

const useStyles = makeStyles(() => ({
  Header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    cursor: 'pointer',
    backgroundColor: '#blue',
  },
  Section: {
    width: '100%',
    border: '1px solid #ccc',
    marginBottom: '1rem',
  },
  Content: {
    padding: '1rem',
  },
}))

function CustomToggle({ eventKey }) {
  const [open, setOpen] = useState(false)
  const toggle = useAccordionButton(eventKey, () =>
    setOpen(!open)
  );

  return (
    <div
      onClick={toggle}
      style={{
        position: 'absolute',
        top: '0',
        left: '22px',
        width: '100%',
        height: '46px',
      }}
    >
      {open ? (
        <UpOutlined style={{ 
          position: 'absolute',
          top: '20px',
          left: '0%',
          transform: 'translate(-50%, -50%)'
         }} />
      ) : (
        <DownOutlined style={{ 
          position: 'absolute',
          top: '20px',
          left: '0%',
          transform: 'translate(-50%, -50%)'
         }} />
      )}
    </div>
  )
}

// https://react-bootstrap.github.io/components/accordion/#flush
export default function Accordian ({ header, children, style }) {
  // Add defaultActiveKey="0" to the first thing under 
  // return to make it Expanded by default
  return (
    <Accordion style={style}>
    <Card>
      <Card.Header style={{display: 'flex', alignItems: 'center', height: '100%'}}> 
        <CustomToggle eventKey="0" />
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '25px'
          }}>
          {header}
        </span>

      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body style={{ padding: '0' }}>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
  )
};
