import React, { Component } from 'react'
import './NotFoundPage.css'
import { useHistory } from 'react-router-dom';


export default function NotFoundPage(props) {
  // Sets which navigation menus show
  const history = useHistory();

  function handleClick(){
    history.push('/home');

  }
  return(
    <div className='NotFoundPage_Container center'>
      
        <h1 className='NotFoundPage_Text'>
          404 Not Found
        </h1>
        <p className='NotFoundPage_Text'>This page couldn't be found! Visit our homepage by clicking the button below.</p>
        <br/>
        <button className='NotFoundPageButton' onClick={() => handleClick()}>Home Page</button>
      
    </div>
  )
}