import React, {  } from 'react'

export default function Login(props) {
  // Sets which navigation menus show

  return(
    <div>
      <h1>
        Contact Us
      </h1>
    </div>
  )
}