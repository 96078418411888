import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
import React, { useContext, useLayoutEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { axiosURL } from '../../axiosURL_Front'
import UserContext from '../../context/UserContext'
import { AppointTileWrapper } from '../../utils/AppointTileWrapper'
import { secondsToTimeWithLeadingZero } from '../misc/secondsToTime'
import { timeToSeconds } from '../misc/timeToSeconds'
import StudentFileCard from './StudentFile.ClassCard'
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material'
import { isMobile } from 'react-device-detect'
import capitalize from '../../functions/capitalize'
import { getAuthHeader } from '../../functions/getToken'
import isNullorUndefined from '../../functions/isNullOrUndefined'
import PlatformStyles from '../../PlatformStyles'
import { AppointBlueButton, AppointGreyButton, AppointRedButton } from "../../utils/AppointButton"
import Loading from '../Loading/Loading'
import Accordian from '../misc/Accordian'
import { Alert } from '../misc/Alert'
import Checkbox from '../misc/Checkbox'
import secondsToMilitaryTime from '../misc/secondsToMilitaryTime'
import { notify } from '../Notification/notify'
import './StudentFile.css'

const useStyles = makeStyles(() => ({
  DropDownButton: {
    margin: '12px 0px 0px 7px',
    fontSize: '1rem',
    width: '320px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none !important',
    borderRadius: PlatformStyles.BorderRadius,
    boxShadow: PlatformStyles.BoxShadow + ' !important',

    backgroundColor: '#424c75 !important',
    color: 'whtie !important',
    cursor: 'pointer',
    padding: '0px 20px',
    '&:hover': {
      backgroundColor: '#394165 !important',
      color: 'white',
    },
  },
  DropDownButtonMobile: {
    margin: '12px 0px 0px 7px',
    fontSize: '1rem',
    width: 'auto',
    minWidth: '150px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none !important',
    borderRadius: PlatformStyles.BorderRadius,
    boxShadow: PlatformStyles.BoxShadow + ' !important',

    backgroundColor: '#424c75 !important',
    color: 'whtie !important',
    cursor: 'pointer',
    padding: '0px 20px',
    '&:hover': {
      backgroundColor: '#394165 !important',
      color: 'white',
    },
  },
}))

export default function StudentFile(props) {
  const history = useHistory()
  const { userData, setUserData } = useContext(UserContext)

  let { fileid, studentid } = useParams() // Passed in through URL
  if (typeof props.fileIdOverride != 'undefined') {
    fileid = props.fileIdOverride
  }
  if (typeof props.studentIdOverride != 'undefined') {
    studentid = props.studentIdOverride
  }

  const [fileID, setFileID] = useState('')
  const [fileName, setFileName] = useState('')

  const [owner, setOwner] = useState('')

  const [situation, setSituation] = useState('')
  const [siteChecklists, setSiteChecklists] = useState(new Map())
  const [studentChecklists, setStudentChecklists] = useState(new Map())

  const [classData, setClassData] = useState([])

  const [blockedTimeData, setBlockedTimeData] = useState([])

  const [clinicalData, setClinicalData] = useState([])
  const [fileNursingTopics, setFileNursingTopics] = useState([])
  const [studentNursingTopics, setStudentNursingTopics] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [newClassMessageBox, setNewClassMessageBox] = useState(false)

  const [newSituationMessageBox, setNewSituationMessageBox] = useState(false)
  const [situationMessageBoxType, setSituationMessageBoxType] = useState('')
  const [tempSituation, setTempSituation] = useState('')
  const [situationMessageBoxError, setSituationMessageBoxError] = useState('')

  // Used for add situation message box
  const [situationMessage, setSituationMessage] = useState('')

  const [editClassMessageBox, setEditClassMessageBox] = useState(false)
  const [blockedTimeMessageBox, setBlockedTimeMessageBox] = useState(false)
  const [editBlockedTimeMessageBox, setEditBlockedTimeMessageBox] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [editIndex, setEditIndex] = useState()

  // Used for add class message box
  const [className, setClassName] = useState('')
  const [location, setLocation] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  const [blockedTimeReason, setBlockedTimeReason] = useState('')

  // Used for add class message box and add blocked time message box
  const [sundayBoolean, setSundayBoolean] = useState(false)
  const [mondayBoolean, setMondayBoolean] = useState(false)
  const [tuesdayBoolean, setTuesdayBoolean] = useState(false)
  const [wednesdayBoolean, setWednesdayBoolean] = useState(false)
  const [thursdayBoolean, setThursdayBoolean] = useState(false)
  const [fridayBoolean, setFridayBoolean] = useState(false)
  const [saturdayBoolean, setSaturdayBoolean] = useState(false)

  const classes = useStyles()
  const [missingNursingTopicsModal, setMissingNursingTopicsModal] = useState(false)

  // Used to hide clinicals if schedule is unpublished
  const [published, setPublished] = useState(false)

  // Used to display the custom message to students from the coordinator
  const [studentMessage, setStudentMessage] = useState('')

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  function submitEntries() {
    if (fileNursingTopics.length == undefined || fileNursingTopics.length == 0) {
      history.push('/dashboard') // If no topics exist to choose from then let them go back to dashboard
    } else if (studentNursingTopics.length == undefined || studentNursingTopics.length == 0) {
      setMissingNursingTopicsModal(true) // Topics exist, but none selected
    } else {
      history.push('/dashboard') // All good. Go back to dashboard
    }
  }

  useLayoutEffect(() => {
    // If no props are passed we are going to let ApplicationNav handle pushing them back to the dashboard but props being undefined would throw an error before that happened
    if (isLoading && typeof fileid !== 'undefined') {
      // These will fail if the props are not passed which
      // will trigger the catch statement and push the user back to the dashboard
      setFileID(fileid)

      // setFileName(props.location.state.data.fileName)

      var packagedData = {
        studentObjectID: studentid,
        fileObjectID: fileid,
      }

      const promises = [
        axios.post(axiosURL + '/users/StudentRecordByFileID', packagedData, getAuthHeader()),
        axios.post(axiosURL + '/files/studentFileExtraInfo', packagedData, getAuthHeader()),
        axios.post(axiosURL + '/files/getClinicalInfo', packagedData, getAuthHeader()),
        axios.post(axiosURL + '/files/getChecklistInfo', packagedData, getAuthHeader()),
      ]

      Promise.allSettled(promises).then((responses) => {
        const studentRecordRequest = responses[0]
        const userInfoRequest = responses[1]
        const clinicalsRequest = responses[2]
        const siteChecklistRequest = responses[3]

        if (
          studentRecordRequest.status === 'rejected' ||
          userInfoRequest.status === 'rejected' ||
          clinicalsRequest.status === 'rejected' ||
          siteChecklistRequest.status === 'rejected'
        ) {
          history.push('/dashboard')
          notify('e', 'There was an error, please try again')
          return
        }

        // Student Record Request
        setStudentChecklists(studentRecordRequest.value.data.data.checklists)
        setSituation(studentRecordRequest.value.data.data.circumstance)
        setStudentNursingTopics(studentRecordRequest.value.data.data.nursingTopics)
        setBlockedTimeData(studentRecordRequest.value.data.data.blockedTimeList)
        for (let i = 0; i < studentRecordRequest.value.data.data.classesList.length; i++) {
          studentRecordRequest.value.data.data.classesList[i].fileID = fileid
          for (let e = 0; e < studentRecordRequest.value.data.data.classesList[i].weekDays.length; e++) {
            studentRecordRequest.value.data.data.classesList[i].weekDays[e] = capitalizeFirstLetter(
              studentRecordRequest.value.data.data.classesList[i].weekDays[e]
            )
          }
        }
        setClassData(studentRecordRequest.value.data.data.classesList)

        // User Extra info request
        setFileNursingTopics(userInfoRequest.value.data.data.nursingTopics)
        setFileName(userInfoRequest.value.data.data.fileName)
        setPublished(userInfoRequest.value.data.data.published)
        setStudentMessage(userInfoRequest.value.data.data.studentMessage)
        setOwner(userInfoRequest.value.data.data.owner)

        // Clinical Request
        console.log(clinicalsRequest.value.data)
        setClinicalData(clinicalsRequest.value.data.data)

        // Checklist request
        setSiteChecklists(siteChecklistRequest.value.data.data)

        // Now load page
        setIsLoading(false)
      })
    }
  }, [fileid, history, isLoading, studentid])

  if (isLoading) {
    return <Loading />
  }

  const MissingNursingTopicsModal = (props) => {
    return (
      <Modal
        {...props}
        size="small"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="messageBox"
        centered
        contentClassName={!isMobile ? '' : 'modalForMobile'}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addClassModalBody">
            <div style={{ textAlign: 'center' }}>
              <span>It looks like you forgot to select your Clinical Types above.</span>
            </div>
          </div>
        </Modal.Body>
        {/* switches button depending on modal type */}
        <Modal.Footer>
          <div style={{ width: '100%' }}>
            <AppointGreyButton style={{ float: 'right', marginRight: '10px' }} variant="secondary" onClick={props.onHide}>
              Go Back
            </AppointGreyButton>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  const NewSituationMessageBox = (props) => {
    const handleSave = () => {
      console.log('tempSituation')
      console.log(tempSituation)
      if (tempSituation.trim() != '') {
        props.onSubmit()
      } else {
        setSituationMessageBoxError('Situation Cannot Be Blank')
      }
    }

    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName={!isMobile ? '' : 'modalForMobile'}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {situationMessageBoxType == 'edit' ? <>Edit </> : <>Add </>}
            Situation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addClassModalBody">
            <TextField
              id="situationInput"
              placeholder="Ex. Student Athlete"
              style={{ width: '100%' }}
              value={tempSituation}
              onChange={(e) => setTempSituation(e.target.value)}
              multiline
              minRows={10}
              maxRows={15}
            />
            <p style={{ textAlign: 'center', width: '100%' }}>
              {/* {errorSituation ? 'Cannot save a blank situation' : 'Please enter your situation below.'} */}
              Add a description of legitimate personal situations that are not time specific. <b>Time bound</b> situations should be added in{' '}
              <b>Blocked Time Request</b> below.
            </p>
            {situationMessageBoxError != '' ? (
              <Alert style={{ marginTop: '10px' }} severity="error">
                {situationMessageBoxError}
              </Alert>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div style={{ width: '100%' }}>
            {situationMessageBoxType == 'edit' ? (
              <AppointRedButton onClick={props.onDelete}>
                Delete
              </AppointRedButton>
            ) : (
              <></>
            )}
            <AppointBlueButton style={{ float: 'right' }} onClick={() => handleSave()}>
              Save
            </AppointBlueButton>
            <AppointGreyButton style={{ float: 'right', marginRight: '10px' }} onClick={props.onHide}>
              Close
            </AppointGreyButton>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  function handleAfternoonTime(tmpTime) {
    if (tmpTime[1] == 'PM') {
      var tmpStrArray = tmpTime[0].split(':')
      var LeftNum = parseInt(tmpStrArray[0])
      LeftNum += 12
      var finalTime = LeftNum + ':' + tmpStrArray[1]
      tmpTime[0] = finalTime
    }
    return tmpTime[0]
  }

  function NewClassMessageBox(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="messageBox"
        centered
        contentClassName={!isMobile ? '' : 'modalForMobile'}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{props.type} Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addClassModalBody">
            <TextField
              style={{ width: '100%', marginBottom: '10px' }}
              value={className}

              onChange={(e) => setClassName(e.target.value)}
              // className={classes.Right}
              label="Class Name (abbreviation)"
            />
            <TextField
              style={{ width: '100%', marginBottom: '10px' }}
              value={location}

              onChange={(e) => setLocation(e.target.value)}
              className={classes.Right}
              id="locationInput"
              label="Location"
            />
            {/* <input placeholder='Location' value={location} onChange={e => handleInput(e, 'location')} type="text" id='locationInput' className='locationInput studentFileAddClassInput'/> */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                style={{ width: '100%', marginBottom: '10px' }}
                value={startTime}

                onChange={(e) => setStartTime(e.target.value)}
                className={classes.Left}
                id="startTimeInput"
                label="Start Time"
                InputLabelProps={{ shrink: true }}
                type="time"
              />
              <TextField
                style={{ width: '100%', marginBottom: '10px' }}
                value={endTime}

                onChange={(e) => setEndTime(e.target.value)}
                className={classes.Right}
                id="endTimeInput"
                label="End Time"
                InputLabelProps={{ shrink: true }}
                type="time"
              />
            </div>

            <div className="addClassWeekDayButtons">
              <button onClick={() => setSundayBoolean(!sundayBoolean)} className={sundayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Sun
              </button>
              <button onClick={() => setMondayBoolean(!mondayBoolean)} className={mondayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Mon
              </button>
              <button onClick={() => setTuesdayBoolean(!tuesdayBoolean)} className={tuesdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Tue
              </button>
              <button onClick={() => setWednesdayBoolean(!wednesdayBoolean)} className={wednesdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Wed
              </button>
              <button onClick={() => setThursdayBoolean(!thursdayBoolean)} className={thursdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Thu
              </button>
              <button onClick={() => setFridayBoolean(!fridayBoolean)} className={fridayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Fri
              </button>
              <button onClick={() => setSaturdayBoolean(!saturdayBoolean)} className={saturdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Sat
              </button>
            </div>
            <div className="errorMessage">{errorMessage}</div>
          </div>
        </Modal.Body>
        {/* switches button depending on modal type */}
        {props.type === 'Add' ? (
          <Modal.Footer>
            <AppointGreyButton onClick={props.onHide}>
              Cancel
            </AppointGreyButton>
            <AppointBlueButton onClick={props.onSubmit}>
              Add
            </AppointBlueButton>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <div style={{ width: '100%' }}>
              <AppointRedButton onClick={props.onDelete}>
                Delete
              </AppointRedButton>
              <AppointBlueButton style={{ float: 'right' }} onClick={props.onSubmit}>
                Save
              </AppointBlueButton>
              <AppointGreyButton style={{ float: 'right', marginRight: '10px' }} variant="secondary" onClick={props.onHide}>
                Cancel
              </AppointGreyButton>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    )
  }

  function handleInput(e, type) {
    if (type === 'className') {
      setClassName(e.target.value)
    } else if (type === 'location') {
      setLocation(e.target.value)
    } else if (type === 'startTime') {
      setStartTime(e.target.value)
    } else if (type === 'endTime') {
      setEndTime(e.target.value)
    } else if (type === 'reason') {
      setBlockedTimeReason(e.target.value)
    } else if (type === 'situation') {
      setSituationMessage(e.target.value)
    }
  }

  function handleHide() {
    console.log('handleHide()')
    setSundayBoolean(false)
    setMondayBoolean(false)
    setTuesdayBoolean(false)
    setWednesdayBoolean(false)
    setThursdayBoolean(false)
    setFridayBoolean(false)
    setSaturdayBoolean(false)

    setClassName('')
    setLocation('')
    setStartTime('')
    setEndTime('')

    setBlockedTimeReason('')

    setErrorMessage('')

    setEditIndex()
    setEditClassMessageBox(false)
    setBlockedTimeMessageBox(false)
    setEditBlockedTimeMessageBox(false)
    setNewClassMessageBox(false)

    setNewSituationMessageBox(false)
    setSituationMessage('')

    setSituationMessageBoxType('')
    setTempSituation('')
    setSituationMessageBoxError('')

    setMissingNursingTopicsModal(false)
  }

  function BlockedTimeMessageBox(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="messageBox"
        centered
        contentClassName={!isMobile ? '' : 'modalForMobile'}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Blocked Time Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addClassModalBody">
            <TextField
              style={{ width: '100%', marginBottom: '10px' }}
              value={className}

              onChange={(e) => setClassName(e.target.value)}
              className={classes.Right}
              id="classNameInput"
              label="Summary Title"
            />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                style={{ width: '100%', marginBottom: '10px' }}
                value={startTime}

                onChange={(e) => setStartTime(e.target.value)}
                className={classes.Left}
                id="startTimeInput"
                label="Start Time"
                InputLabelProps={{ shrink: true }}
                type="time"
              />
              <TextField
                style={{ width: '100%', marginBottom: '10px' }}
                value={endTime}

                onChange={(e) => setEndTime(e.target.value)}
                className={classes.Right}
                id="endTimeInput"
                label="End Time"
                InputLabelProps={{ shrink: true }}
                type="time"
              />
            </div>

            <div className="addClassWeekDayButtons">
              <button onClick={() => setSundayBoolean(!sundayBoolean)} className={sundayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Sun
              </button>
              <button onClick={() => setMondayBoolean(!mondayBoolean)} className={mondayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Mon
              </button>
              <button onClick={() => setTuesdayBoolean(!tuesdayBoolean)} className={tuesdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Tue
              </button>
              <button onClick={() => setWednesdayBoolean(!wednesdayBoolean)} className={wednesdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Wed
              </button>
              <button onClick={() => setThursdayBoolean(!thursdayBoolean)} className={thursdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Thu
              </button>
              <button onClick={() => setFridayBoolean(!fridayBoolean)} className={fridayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Fri
              </button>
              <button onClick={() => setSaturdayBoolean(!saturdayBoolean)} className={saturdayBoolean ? 'addClassWeekTrue' : 'addClassWeekFalse'}>
                Sat
              </button>
            </div>
            <TextField
              id="reason"
              name="reason"
              placeholder="Detailed Explanation"

              style={{ width: '100%' }}
              value={blockedTimeReason}
              onChange={(e) => setBlockedTimeReason(e.target.value)}
              multiline
              minRows={10}
              maxRows={15}
            />
            <div className="errorMessage">{errorMessage}</div>
          </div>
        </Modal.Body>
        {/* switches button depending on modal type */}
        {props.type === 'Add' ? (
          <Modal.Footer>
            <AppointGreyButton onClick={props.onHide}>
              Cancel
            </AppointGreyButton>
            <AppointBlueButton onClick={props.onSubmit}>
              Add
            </AppointBlueButton>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <div style={{ width: '100%' }}>
              <AppointRedButton onClick={props.onDelete}>
                Delete
              </AppointRedButton>
              <AppointBlueButton style={{ float: 'right' }} onClick={props.onSubmit}>
                Save
              </AppointBlueButton>
              <AppointGreyButton style={{ float: 'right', marginRight: '10px' }} variant="secondary" onClick={props.onHide}>
                Cancel
              </AppointGreyButton>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    )
  }

  function addBlockedTime() {
    let summary = document.getElementById('classNameInput').value
    let startTime = document.getElementById('startTimeInput').value
    let endTime = document.getElementById('endTimeInput').value
    let weekDays = [sundayBoolean, mondayBoolean, tuesdayBoolean, wednesdayBoolean, thursdayBoolean, fridayBoolean, saturdayBoolean]
    let weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let reason = document.getElementById('reason').value

    //// Fixes issue where new blocked times always had the same reason text as the first
    reason = blockedTimeReason

    // Replaces true with weekday name
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === true) {
        weekDays[i] = weekDayNames[i]
      }
    }
    // Removes falses from list to isolate the weekdays that are needed
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === false) {
        weekDays.splice(i, 1)
        i -= 1
      }
    }

    // These two initial if statements normalize classes that run past Midnight
    var timeCheck = [false, '']
    var tempStartTime = timeToSeconds(startTime) //timeToSeconds(startTime + ' ' + startTimeNoon)
    var tempEndTime = timeToSeconds(endTime) // timeToSeconds(endTime + ' ' + endTimeNoon)
    if (tempEndTime < tempStartTime) {
      tempEndTime += 86400
    }
    // Loops through each class
    for (let i = 0; i < classData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < classData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (classData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (classData[i].endTime < classData[i].startTime) {
              classData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= classData[i].endTime && classData[i].endTime <= tempEndTime) ||
              (classData[i].startTime <= tempEndTime && tempEndTime <= classData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + classData[i].className + ' in your schedule'
            }
          }
        }
      }
    }

    for (let i = 0; i < blockedTimeData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < blockedTimeData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (blockedTimeData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (blockedTimeData[i].endTime < blockedTimeData[i].startTime) {
              blockedTimeData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= blockedTimeData[i].endTime && blockedTimeData[i].endTime <= tempEndTime) ||
              (blockedTimeData[i].startTime <= tempEndTime && tempEndTime <= blockedTimeData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + blockedTimeData[i].summary + ' in your schedule'
            }
          }
        }
      }
    }

    // _____________________DO NOT TOUCH_____________________________
    // There is no way to grab a unique blocked time object without an
    // Identifier. We have no identifier besides the name which is why
    // we do not allow duplicates
    let duplicate = false
    for (let i = 0; i < blockedTimeData.length; i++) {
      if (blockedTimeData[i].summary.trim().toLowerCase() === summary.trim().toLowerCase()) {
        duplicate = true
      }
    }
    // ___________________ DO NOT TOUCH _______________________________
    if (duplicate) {
      setErrorMessage('Duplicate name with another blocked time')
    } else if (summary.length > 10) {
      setErrorMessage('Summary limit is 10 characters. Please use abbreviation.')
    } else if (reason.length == 0) {
      setErrorMessage('Enter a Detailed Explanation')
    } else if (summary.length == 0) {
      setErrorMessage('Enter a Summary Title.')
    } else if (startTime == null || startTime == '') {
      setErrorMessage('Start Time was formatted incorrectly')
    } else if (endTime == null || endTime == '') {
      setErrorMessage('End Time was formatted incorrectly')
    } else if (timeCheck[0]) {
      setErrorMessage(timeCheck[1])
    } else if (weekDays.length === 0) {
      setErrorMessage('Select a weekday')
    } else if (startTime >= endTime) {
      setErrorMessage('Cannot have a start time after an end time')
    } else {
      setErrorMessage('')

      // Sends to server to save
      const packagedData = {
        studentObjectID: studentid,
        fileObjectID: fileid,
        summary,
        startTime: timeToSeconds(startTime),
        endTime: timeToSeconds(endTime),
        weekDays,
        reason,
      }

      axios.post(axiosURL + '/files/addBlockedTime', packagedData).then((res) => {
        // console.log(res)
        // console.log(res.data.blockedTimeList)
        setBlockedTimeData(res.data.blockedTimeList)
        handleHide()
      })
    }
  }

  function editBlockedTime() {
    let summary = document.getElementById('classNameInput').value
    let startTime = document.getElementById('startTimeInput').value
    let endTime = document.getElementById('endTimeInput').value
    let weekDays = [sundayBoolean, mondayBoolean, tuesdayBoolean, wednesdayBoolean, thursdayBoolean, fridayBoolean, saturdayBoolean]
    let weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let reason = document.getElementById('reason').value

    //// Fixes ability to actually update reason value
    reason = blockedTimeReason

    // Replaces true with weekday name
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === true) {
        weekDays[i] = weekDayNames[i]
      }
    }
    // Removes falses from list to isolate the weekdays that are needed
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === false) {
        weekDays.splice(i, 1)
        i -= 1
      }
    }

    // These two initial if statements normalize classes that run past Midnight
    var timeCheck = [false, '']
    var tempStartTime = timeToSeconds(startTime)
    var tempEndTime = timeToSeconds(endTime)
    if (tempEndTime < tempStartTime) {
      tempEndTime += 86400
    }
    // Loops through each class
    for (let i = 0; i < classData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < classData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (classData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (classData[i].endTime < classData[i].startTime) {
              classData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= classData[i].endTime && classData[i].endTime <= tempEndTime) ||
              (classData[i].startTime <= tempEndTime && tempEndTime <= classData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + classData[i].className + ' in your schedule'
            }
          }
        }
      }
    }

    for (let i = 0; i < blockedTimeData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < blockedTimeData[i].weekDays.length; e++) {
        // So that we don't check it against itself
        if (i === editIndex) {
          continue
        }
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (blockedTimeData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (blockedTimeData[i].endTime < blockedTimeData[i].startTime) {
              blockedTimeData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= blockedTimeData[i].endTime && blockedTimeData[i].endTime <= tempEndTime) ||
              (blockedTimeData[i].startTime <= tempEndTime && tempEndTime <= blockedTimeData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + blockedTimeData[i].summary + ' in your schedule'
            }
          }
        }
      }
    }

    // _____________________DO NOT TOUCH_____________________________
    // There is no way to grab a unique blocked time object without an
    // Identifier. We have no identifier besides the name which is why
    // we do not allow duplicates
    let duplicate = false
    for (let i = 0; i < blockedTimeData.length; i++) {
      if (blockedTimeData[i].summary.trim().toLowerCase() === summary.trim().toLowerCase() && i !== editIndex) {
        duplicate = true
      }
    }
    // _____________________DO NOT TOUCH_____________________________

    if (duplicate) {
      setErrorMessage('Duplicate name with another blocked time')
    } else if (summary.length > 10) {
      setErrorMessage('Summary limit is 10 characters. Please use abbreviation.')
    } else if (reason.length == 0) {
      setErrorMessage('Enter a Detailed Explanation')
    } else if (summary.length == 0) {
      setErrorMessage('Enter a Summary Title.')
    } else if (startTime == null || startTime == '') {
      setErrorMessage('Start Time was formatted incorrectly')
    } else if (endTime == null || endTime == '') {
      setErrorMessage('End Time was formatted incorrectly')
    } else if (timeCheck[0]) {
      setErrorMessage(timeCheck[1])
    } else if (weekDays.length === 0) {
      setErrorMessage('Select a weekday')
    } else if (startTime >= endTime) {
      setErrorMessage('Cannot have a start time after an end time')
    } else {
      setErrorMessage('')

      console.log('blockedTimeData')
      console.log(blockedTimeData)

      let tempData = blockedTimeData
      tempData[editIndex] = {
        summary,
        reason,
        startTime: timeToSeconds(startTime),
        endTime: timeToSeconds(endTime),
        weekDays: weekDays,
      }
      const packagedData = {
        studentObjectID: studentid,
        fileObjectID: fileid,
        blockedTimeList: tempData,
      }

      editOrDeleteBlockedTime(packagedData)
    }
  }

  function editOrDeleteBlockedTime(packagedData) {
    axios.post(axiosURL + '/files/editOrDeleteBlockedTime', packagedData).then((res) => {
      setBlockedTimeData(res.data.blockedTimeList)
      handleHide()
    })
  }

  function addClass() {
    // let className = document.getElementById('classNameInput').value
    let location = document.getElementById('locationInput').value
    let startTime = document.getElementById('startTimeInput').value
    let endTime = document.getElementById('endTimeInput').value
    let weekDays = [sundayBoolean, mondayBoolean, tuesdayBoolean, wednesdayBoolean, thursdayBoolean, fridayBoolean, saturdayBoolean]
    let weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    // Replaces true with weekday name
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === true) {
        weekDays[i] = weekDayNames[i]
      }
    }
    // Removes falses from list to isolate the weekdays that are needed
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === false) {
        weekDays.splice(i, 1)
        i -= 1
      }
    }

    // These two initial if statements normalize classes that run past Midnight
    var timeCheck = [false, '']
    var tempStartTime = timeToSeconds(startTime)
    var tempEndTime = timeToSeconds(endTime)
    if (tempEndTime < tempStartTime) {
      tempEndTime += 86400
    }
    // Loops through each class
    for (let i = 0; i < classData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < classData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (classData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (classData[i].endTime < classData[i].startTime) {
              classData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= classData[i].endTime && classData[i].endTime <= tempEndTime) ||
              (classData[i].startTime <= tempEndTime && tempEndTime <= classData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + classData[i].className + ' in your schedule'
            }
          }
        }
      }
    }

    for (let i = 0; i < blockedTimeData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < blockedTimeData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (blockedTimeData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (blockedTimeData[i].endTime < blockedTimeData[i].startTime) {
              blockedTimeData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= blockedTimeData[i].endTime && blockedTimeData[i].endTime <= tempEndTime) ||
              (blockedTimeData[i].startTime <= tempEndTime && tempEndTime <= blockedTimeData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + blockedTimeData[i].summary + ' in your schedule'
            }
          }
        }
      }
    }
    console.log('startTime: ', "'" + startTime + "'", typeof startTime, 'endTime: ', "'" + endTime + "'")
    if (className.length > 6) {
      setErrorMessage('Class Name limit is 6 characters. Please use abbreviation.')
    } else if (className.length == 0) {
      setErrorMessage('Enter a Class Name.')
    } else if (startTime == null || startTime == '') {
      setErrorMessage('Start Time was formatted incorrectly')
    } else if (endTime == null || endTime == '') {
      setErrorMessage('End Time was formatted incorrectly')
    } else if (timeCheck[0]) {
      setErrorMessage(timeCheck[1])
    } else if (weekDays.length === 0) {
      setErrorMessage('Select a weekday')
    } else if (startTime >= endTime) {
      setErrorMessage('Cannot have a start time after an end time')
    } else {
      setErrorMessage('')
      // Sends to server to save
      const packagedData = {
        studentObjectID: studentid,
        fileObjectID: fileid,
        className: className,
        location: location,
        startTime: timeToSeconds(startTime),
        endTime: timeToSeconds(endTime),
        weekDays: weekDays,
      }

      axios.post(axiosURL + '/files/addClass', packagedData).then((res) => {
        console.log(res)
        // Adds it to current list so we do not have to reload
        classData.push({
          className: className,
          location: location,
          startTime: timeToSeconds(startTime),
          endTime: timeToSeconds(endTime),
          weekDays: weekDays,
        })
        handleHide()
      })
    }
  }

  function editClass() {
    // let className = document.getElementById('classNameInput').value
    let location = document.getElementById('locationInput').value
    let startTime = document.getElementById('startTimeInput').value
    let endTime = document.getElementById('endTimeInput').value
    let weekDays = [sundayBoolean, mondayBoolean, tuesdayBoolean, wednesdayBoolean, thursdayBoolean, fridayBoolean, saturdayBoolean]
    let weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    // Replaces true with weekday name
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === true) {
        weekDays[i] = weekDayNames[i]
      }
    }
    // Removes falses from list to isolate the weekdays that are needed
    for (let i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === false) {
        weekDays.splice(i, 1)
        i -= 1
      }
    }

    // These two initial if statements normalize classes that run past Midnight
    var timeCheck = [false, '']
    var tempStartTime = timeToSeconds(startTime)
    var tempEndTime = timeToSeconds(endTime)
    if (tempEndTime < tempStartTime) {
      tempEndTime += 86400
    }
    // Loops through each class
    for (let i = 0; i < classData.length; i++) {
      // So that we don't check it against itself
      if (i === editIndex) {
        continue
      }
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < classData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (classData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (classData[i].endTime < classData[i].startTime) {
              classData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= classData[i].endTime && classData[i].endTime <= tempEndTime) ||
              (classData[i].startTime <= tempEndTime && tempEndTime <= classData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + classData[i].className + ' in your schedule.'
            }
          }
        }
      }
    }

    for (let i = 0; i < blockedTimeData.length; i++) {
      // Next two for statement loop through each week day in those classes
      for (let e = 0; e < blockedTimeData[i].weekDays.length; e++) {
        for (let t = 0; t < weekDays.length; t++) {
          // If they are on the same week day we are going to check the time
          if (blockedTimeData[i].weekDays[e].toLowerCase() === weekDays[t].toLowerCase()) {
            // Normalizes over night classes
            if (blockedTimeData[i].endTime < blockedTimeData[i].startTime) {
              blockedTimeData[i].endTime += 86400
            }
            // Verified that this will catch any and all time conflicts
            if (
              (tempStartTime <= blockedTimeData[i].endTime && blockedTimeData[i].endTime <= tempEndTime) ||
              (blockedTimeData[i].startTime <= tempEndTime && tempEndTime <= blockedTimeData[i].endTime)
            ) {
              timeCheck[0] = true
              timeCheck[1] = 'Start and End time conflict with ' + blockedTimeData[i].summary + ' in your schedule'
            }
          }
        }
      }
    }

    console.log(className.length)
    if (className.length > 6) {
      setErrorMessage('Class Name limit is 6 characters. Please use abbreviation.')
    } else if (className.length == 0) {
      setErrorMessage('Enter a Class Name.')
    } else if (startTime == null || startTime == '') {
      setErrorMessage('Start Time was formatted incorrectly')
    } else if (endTime == null || endTime == '') {
      setErrorMessage('End Time was formatted incorrectly')
    } else if (timeCheck[0]) {
      setErrorMessage(timeCheck[1])
    } else if (weekDays.length === 0) {
      setErrorMessage('Select a weekday')
    } else if (startTime >= endTime) {
      setErrorMessage('Cannot have a start time after an end time')
    } else {
      setErrorMessage('')

      // making sure things do not update visually unless the server responds with a confirmation
      // that the class was edited
      let tempData = classData
      tempData[editIndex] = {
        className: className,
        location: location,
        startTime: timeToSeconds(startTime),
        endTime: timeToSeconds(endTime),
        weekDays: weekDays,
      }
      console.log(tempData)
      // Sends to server to save
      const packagedData = {
        studentObjectID: studentid,
        fileObjectID: fileid,
        className: className,
        classesList: tempData,
      }

      editOrDeleteClass(packagedData)
    }
  }

  function addSituation() {
    let situation = document.getElementById('situationInput').value

    const packagedData = {
      studentObjectID: studentid,
      fileObjectID: fileid,
      circumstance: situation,
    }

    axios.post(axiosURL + '/files/AddSituation', packagedData).then((res) => {
      if (res.data.success) {
        setSituation(res.data.circumstance)
        handleHide()
      }
    })
  }

  function deleteSituation() {
    let situation = ''

    console.log(situation)

    const packagedData = {
      studentObjectID: studentid,
      fileObjectID: fileid,
      circumstance: situation,
    }

    axios.post(axiosURL + '/files/AddSituation', packagedData).then((res) => {
      if (res.data.success) {
        setSituation(res.data.circumstance)
        handleHide()
      }
    })
  }

  function editOrDeleteClass(packagedData) {
    axios.post(axiosURL + '/files/editOrDeleteClass', packagedData).then((res) => {
      setClassData(res.data.classesList)
      handleHide()
    })
  }

  function handleBlockedTimeEdit(index, event) {
    setClassName(event.summary)
    setBlockedTimeReason(event.reason)

    // Handles setting the times
    let tempStartTime = secondsToTimeWithLeadingZero(event.startTime).split(' ')
    let tempEndTime = secondsToTimeWithLeadingZero(event.endTime).split(' ')

    // handleAfternoonTime adjusts for being AM or PM
    setStartTime(secondsToMilitaryTime(event.startTime))
    setEndTime(secondsToMilitaryTime(event.endTime))

    setEditIndex(index)

    // Sets the weekDays
    for (let i = 0; i < event.weekDays.length; i++) {
      switch (event.weekDays[i].toLowerCase()) {
        case 'sunday':
          setSundayBoolean(true)
          break
        case 'monday':
          setMondayBoolean(true)
          break
        case 'tuesday':
          setTuesdayBoolean(true)
          break
        case 'wednesday':
          setWednesdayBoolean(true)
          break
        case 'thursday':
          setThursdayBoolean(true)
          break
        case 'friday':
          setFridayBoolean(true)
          break
        case 'saturday':
          setSaturdayBoolean(true)
          break
        default:
      }
    }

    setEditBlockedTimeMessageBox(true)
  }

  function handleEditClass(index, event) {
    setClassName(event.className)
    setLocation(event.location)

    setStartTime(secondsToMilitaryTime(event.startTime))
    setEndTime(secondsToMilitaryTime(event.endTime))

    setEditIndex(index)

    // Sets the weekDays
    for (let i = 0; i < event.weekDays.length; i++) {
      switch (event.weekDays[i].toLowerCase()) {
        case 'sunday':
          setSundayBoolean(true)
          break
        case 'monday':
          setMondayBoolean(true)
          break
        case 'tuesday':
          setTuesdayBoolean(true)
          break
        case 'wednesday':
          setWednesdayBoolean(true)
          break
        case 'thursday':
          setThursdayBoolean(true)
          break
        case 'friday':
          setFridayBoolean(true)
          break
        case 'saturday':
          setSaturdayBoolean(true)
          break
        default:
      }
    }
    setEditClassMessageBox(true)
  }

  function handleDeleteEditClass() {
    let tempData = classData.filter((_, index) => index != editIndex)

    const packagedData = {
      studentObjectID: studentid,
      fileObjectID: fileid,
      className: className,
      classesList: tempData,
    }

    editOrDeleteClass(packagedData)
  }

  function handleDeletEditBlockedTime() {
    let tempData = blockedTimeData.filter((_, index) => index != editIndex)

    const packagedData = {
      studentObjectID: studentid,
      fileObjectID: fileid,
      blockedTimeList: tempData,
    }

    editOrDeleteBlockedTime(packagedData)
  }

  const ClinicalTypes = () => {
    const handleClinicalTypeClick = (type) => {
      let packagedData = {
        studentObjectID: studentid,
        fileObjectID: fileid,
        clinicalType: type,
      }

      // Could probably do this logic in the backend which would be better
      if (
        studentNursingTopics
          .map((a) => {
            return a.toLowerCase()
          })
          .includes(type.toLowerCase())
      ) {
        packagedData.operation = 'remove'
      } else {
        packagedData.operation = 'add'
      }

      axios.post(axiosURL + '/files/studentClinicalTypeChange', packagedData).then((res) => {
        if (res.data.success) {
          setStudentNursingTopics(res.data.studentNursingTopics)
        } else {
          notify('e', res.data.message)
        }
      })
    }

    return (
      <>
        <div>
          {fileNursingTopics.map((type) => {
            return (studentNursingTopics
              .map((a) => {
                return a.toLowerCase()
              })
              .includes(type.toLowerCase())
              ? 
              <AppointBlueButton
                key={type}
                id={type}
                onClick={(e) => handleClinicalTypeClick(type)}
                className={'studentFileClinicalTypeButton'}>
                {capitalize(type)}
              </AppointBlueButton>
              : 
              <AppointGreyButton
                key={type}
                id={type}
                onClick={(e) => handleClinicalTypeClick(type)}
                className={'studentFileClinicalTypeButton'}>
                {capitalize(type)}
              </AppointGreyButton>
            )
          })}
        </div>
      </>
    )
  }

  function toggleChecklistItem(location, checklistItemId) {
    const packagedData = {
      studentObjectID: studentid,
      fileObjectID: fileID,
      location,
      checklistItemId,
    }

    axios.post(axiosURL + '/files/toggleStudentChecklist', packagedData, getAuthHeader()).then((res) => {
      console.log(res.data.data)
      if (res.data.success) {
        setStudentChecklists(res.data.data)
      } else {
        notify('e', res.data.message)
      }
    })
  }

  function clinicalDaysString(e) {
    let tmpString = ''

    if (typeof e !== 'undefined') {
      for (var i = 0; i < e.length - 1; i++) {
        tmpString += e[i]
        tmpString += ', '
      }
      tmpString += e[e.length - 1]

      return tmpString
    } else {
      return 'Error'
    }
  }

  function renderClinicalDays(inpatientDays) {
    if (inpatientDays.length === 0) {
      return 'All Days'
    } else {
      return clinicalData.inpatientDays.join(', ')
    }
  }

  return (
    <div className="main">
      {/* Has to be formatted like this so when you type the input does not unfocus */}
      {NewClassMessageBox({
        show: newClassMessageBox,
        onHide: handleHide,
        onSubmit: addClass,
        type: 'Add',
      })}

      {NewClassMessageBox({
        show: editClassMessageBox,
        onDelete: handleDeleteEditClass,
        onHide: handleHide,
        onSubmit: editClass,
        type: 'Edit',
      })}

      {BlockedTimeMessageBox({
        show: blockedTimeMessageBox,
        onHide: handleHide,
        onSubmit: addBlockedTime,
        type: 'Add',
      })}

      {BlockedTimeMessageBox({
        show: editBlockedTimeMessageBox,
        onDelete: handleDeletEditBlockedTime,
        onHide: handleHide,
        onSubmit: editBlockedTime,
        type: 'Edit',
      })}

      {NewSituationMessageBox({
        show: newSituationMessageBox,
        onHide: handleHide,
        onSubmit: addSituation,
        onDelete: deleteSituation,
        situation: situation,
        type: 'Add',
      })}

      {MissingNursingTopicsModal({
        show: missingNursingTopicsModal,
        onHide: handleHide,
      })}

      {/* {NewSituationMessageBox({
        show: editSituationMessageBox,
        onDelete: deleteSituation,
        onHide: handleHide,
        onSubmit: editSituation,
        type: 'Edit'
        })} */}

      {/* {EditClassMessageBox({
        show: editClassMessageBox,
        onHide: handleHide,
        onSubmit: addClass
        })} */}

      {/* <NewClassMessageBox
            show={newClassMessageBox}
            onHide={() => {setErrorMessage(''); setNewClassMessageBox(false)}}
            onSubmit={() => {addClass()}}
        /> */}

      <AppointTileWrapper style={{ backgroundColor: '#26425e' }} className="studentFileFileName">
        <div style={{ textAlign: 'center', color: '#ffffff' }} className="">
          <b>{fileName}</b>
        </div>
      </AppointTileWrapper>

      <AppointTileWrapper className="studentFileFileName">
        <div style={{ textAlign: 'center' }}>
          Please note the schedule entered below will be cross checked with your official academic schedule. You are responsible for ensuring the
          accuracy of the information that you provide.
        </div>
      </AppointTileWrapper>

      {studentMessage.trim() !== '' && (
        <AppointTileWrapper className="studentFileClasses">
          <div className="studentFileTitle">
            <div>Message from {owner}</div>
          </div>
          <div>
            <b>{studentMessage}</b>
            <br />
          </div>
        </AppointTileWrapper>
      )}

      {isMobile ? ( /// If mobile then this will display. Did this because the table on the dashboard still does not clearly show all columns before entering the file
        /// The long term goal is to fix that. But we need to rotate the table somehow so that columns and rows are swapped, as there is far more vertical space
        /// than there is horizontal space on a phone. Most likely students won't need to see many files at once.
        published ? (
          <div className="studentFileFileName">Your Clinicals have been published at the bottom</div>
        ) : (
          <div className="studentFileFileName"> Your Coordinator has not yet published your clinicals</div>
        )
      ) : (
        <></>
      )}

      <AppointTileWrapper className="studentFileClasses">
        <div className="studentFileTitle">
          Classes
          <span className="studentFileAddClass" onClick={() => setNewClassMessageBox(true)}>
            <PlusCircleOutlined />
          </span>
        </div>

        <span className="studentFileClassesCardsArea">
          {classData.map((singleClass, index) => (
            <StudentFileCard data={singleClass} type={'class'} index={index} key={index} handleEdit={handleEditClass} />
          ))}
        </span>

        {classData.length === 0 && <div className="studentFileEmptyNotice">Add your classes by clicking the plus icon above!</div>}
      </AppointTileWrapper>

      <AppointTileWrapper className="studentFileClasses">
        <div className="studentFileTitle">
          <div>Clinical Types</div>
          <ClinicalTypes />
        </div>
        {fileNursingTopics.length === 0 ? (
          <div className="studentFileEmptyNotice">There are currently no clinical types to choose from.</div>
        ) : (
          <div className="studentFileEmptyNotice">Please select the clinical types that you need for the semester</div>
        )}
      </AppointTileWrapper>

      <AppointTileWrapper className="studentFileClasses">
        <div className="studentFileTitle">
          Circumstances & Situations
          <span
            className="studentFileAddClass"
            onClick={() => {
              if (situation != '') {
                setSituationMessageBoxType('edit')
              }
              setTempSituation(situation)
              setNewSituationMessageBox(true)
            }}
          >
            {situation == '' ? (
              <PlusCircleOutlined /* Not sure if 'studentFileAddClass' needs to change. */ />
            ) : (
              // <img onClick={() => {}} alt="logo" src={config.editIcon} className="editIconStudentFile" />
              <EditOutlined className="studentCardIconSituation" />
            )}
          </span>
        </div>

        {true && ( // classData.length === 0 &&    //// May want to update this based on if there are circumstances input already or not
          <div className="studentFileEmptyNotice">
            {situation == '' ? 'Please share any personal issues you have that may impact scheduling' : situation}
          </div>
        )}
      </AppointTileWrapper>

      <AppointTileWrapper className="studentFileClasses">
        <div className="studentFileTitle">
          Blocked Time Request
          <span className="studentFileAddClass" onClick={() => setBlockedTimeMessageBox(true)}>
            <PlusCircleOutlined />
          </span>
        </div>

        <span className="studentFileClassesCardsArea">
          {blockedTimeData.map((blockedTime, index) => (
            <StudentFileCard data={blockedTime} type={'blockedTime'} index={index} key={index} handleEdit={handleBlockedTimeEdit} />
          ))}
        </span>

        {blockedTimeData.length === 0 && (
          <div className="studentFileEmptyNotice">Please share any non-academic, time specific activities you have that may impact scheduling</div>
        )}
      </AppointTileWrapper>

      <AppointTileWrapper className="studentFileClasses">
        <div className="studentFileTitle">Clinicals</div>

        {published &&
          !isLoading &&
          clinicalData.map((clinical) => {
            console.log(clinical)
            let complete = false
            let student = studentChecklists[clinical.location]
            let site = siteChecklists[clinical.location]
            if (isNullorUndefined(student)) student = []
            if (student.length === site.length) {
              complete = true
            }

            return (
              <Accordian
                style={{
                  marginBottom: '10px',
                }}
                header={
                  <>
                    {clinical.type} {clinical.section}
                    <Alert
                      severity={complete ? 'success' : 'error'}
                      style={{
                        height: '27px',
                        fontSize: '13px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '10px',
                      }}
                    >
                      {complete ? <>Complete</> : <>Incomplete</>}
                    </Alert>
                  </>
                }
              >
                <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {console.log('clinical', clinical)}
                  <StudentFileCard data={clinical} fileObjectID={fileid} studentObjectID={studentid} type={'clinical'} />
                  <AppointTileWrapper
                    style={{
                      padding: '10px',
                      width: '325px',
                      margin: '10px',
                    }}
                  >
                    Checklist Items
                    <hr></hr>
                    {siteChecklists[clinical.location].length === 0 ? (
                      <>There are no checklist items for this clinical site</>
                    ) : (
                      siteChecklists[clinical.location].map((item) => {
                        return (
                          <span
                            style={{
                              display: 'block',
                            }}
                          >
                            <Checkbox
                              checked={studentChecklists[clinical.location] && studentChecklists[clinical.location].indexOf(item._id) !== -1}
                              onChange={() => toggleChecklistItem(clinical.location, item._id)}
                            />
                            {item.value}
                          </span>
                        )
                      })
                    )}
                  </AppointTileWrapper>
                </span>
              </Accordian>
            )
          })}

        {clinicalData.length === 0 && published && <div className="studentFileEmptyNotice">No assigned clinicals.</div>}

        {!published && <div className="studentFileEmptyNotice">Schedules have not yet been published to view, please check back later</div>}
      </AppointTileWrapper>

      {!published && (
        <div style={{}}>
          <AppointBlueButton className={!isMobile ? classes.DropDownButton : classes.DropDownButtonMobile} onClick={() => submitEntries()}>
            Submit
          </AppointBlueButton>
        </div>
      )}
    </div>
  )
}
