// FOR BUILDING
// Go to axiosURL and change building to true

import axios from 'axios';
import React, { useLayoutEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { axiosURL } from './axiosURL_Front';
import UserContext from './context/UserContext';

/* Components */
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ApplicationNav from './components/Navbar/ApplicationNav';
import Navbar from './components/Navbar/Navbar';
import './components/misc/Main.css';
import ProgressBar from './components/misc/ProgressBar';
import ErrorBoundaryWithHistory from './components/ErrorBoundary/ErrorBoundaryWithHistory';

/* CSS Styling */
// import 'bootstrap/dist/css/bootstrap.min.css'

export default function App(){
  const [userData, setUserData] = useState({ /* Used for Authentication; LEAVE AS ""*/
    token: "",
    user: ""
  })
  const [isLoading, setIsLoading] = useState(true)
  
  /* Checks browser tokens to see if user is already logged in (F12 -> Application -> Local Storage -> Local host*/
  useLayoutEffect(() => {
      let token = localStorage.getItem("auth-token");
      /* if the token does not exist, create one */
      if (token === null || token === ''){
        localStorage.setItem("auth-token", "");
        token = "";        
      }
        /* Validates the token that was stored and sets the data in userContext so that we can access it gloablly */
     axios.post(axiosURL + "/users/validateToken/", null, {headers: {'x-auth-token': token}})
      .then(res => {
          if(res.data.success){
            axios.get(axiosURL + "/users/bytoken", {headers: {'x-auth-token': token}})
              .then(res => {
                setUserData({
                  token,
                  user: res.data
                })
                setIsLoading(false)
              })
          } else {
            localStorage.setItem("auth-token", ""); /* Removes token from website cache*/
            setUserData({ /* resets token stuff in program */
                token: undefined,
                user: undefined
            })
            setIsLoading(false)
          }
        })
        .catch(error => {
          localStorage.setItem("auth-token", ""); /* Removes token from website cache*/
          setUserData({ /* resets token stuff in program */
              token: undefined,
              user: undefined
          })
          setIsLoading(false)
        }) 
      
  }, []) /* empty array means function will run once. called dependency */

  if(isLoading) {
    return <></>
  }

  return(
    <>
    
      <React.StrictMode>
        {/* <button onClick={() => toast.success('hi')}>h</button> */}
        <UserContext.Provider value={{userData, setUserData, isMobile}}> {/* Sets the context and gives all components access to userData */}
          
            <div className='App'>    
              <Router>
              <ErrorBoundaryWithHistory>  
                <Switch>
                  <Route exact path='/' component={Navbar} />
                  <Route exact path='/home' component={Navbar} />
                  <Route exact path='/about' component={Navbar} />
                  <Route exact path='/products' component={Navbar} />
                  <Route exact path='/login' component={Navbar} />
                  <Route exact path='/activate/:token' component={Navbar} />
                  <Route exact path='/contactus' component={Navbar} />
                  <Route exact path='/error' component={Navbar} />
                  <Route path='/(dashboard|clinicalassistant|accounts|userprofile|profile|studentfile|reporting|settings|entity|systemSettings|feedback|clinicalsites)' component={ApplicationNav}/>
                  <Route component={Navbar} />
                </Switch>
                </ErrorBoundaryWithHistory> 
              </Router>
              
              
              <ProgressBar />
            </div>
          
        </UserContext.Provider>
      </React.StrictMode>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{ backgroundColor: "white", color: "black" }}
        /> 
    </>
  );
}