import React, { } from 'react'

export default function Login(props) {

  return(
    <div>
      <h1>
        About
      </h1>
    </div>
  )
}