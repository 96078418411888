import { styled } from '@mui/system';
import PlatformStyles from '../PlatformStyles';

const buttonStyles = {
  cursor: 'pointer',
  borderRadius: PlatformStyles.BorderRadius,
  boxShadow: PlatformStyles.BoxShadow,
  minWidth: '140px',
  textAlign: 'center',
  height: '40px',
  padding: '.5rem 1rem',
  border: 'none',
  '&:hover': {
    backgroundColor: PlatformStyles.LogoGrayBlue,
    position: 'relative',
    bottom: '1px',
    color: 'white',
    boxShadow: '0 0 5px 2px rgba(51, 93, 168, 0.5)',
  },
};

const disabled = ({ disabled }) => ({
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
})

export const AppointBlueButton = styled('button')({
  ...buttonStyles,
  backgroundColor: PlatformStyles.LogoNavyBlue,
  color: 'white',
},
  disabled);

export const AppointWhiteButton = styled('button')({
  ...buttonStyles,
  backgroundColor: 'white',
  color: 'black',
},
  disabled);

export const AppointGreyButton = styled('button')({
  ...buttonStyles,
  backgroundColor: PlatformStyles.Gray,
  '&:hover': {
    ...buttonStyles['&:hover'],
    backgroundColor: PlatformStyles.DarkGray,
    },
  color: 'white',
},
  disabled);

export const AppointDarkGreyButton = styled('button')({
  ...buttonStyles,
  backgroundColor: PlatformStyles.DarkGray,
  color: 'white',
},
  disabled);

export const AppointRedButton = styled('button')({
  ...buttonStyles,
  backgroundColor: PlatformStyles.PlatformRed,
  '&:hover': {
    ...buttonStyles['&:hover'],
    backgroundColor: PlatformStyles.DarkRed,
    },
  color: 'white',
},
  disabled);

export const AppointYellowButton = styled('button')({
  ...buttonStyles,
  '&:hover': {
    ...buttonStyles['&:hover'],
    color: 'black',
    backgroundColor: '#FED402'
   },
  backgroundColor: PlatformStyles.Yellow,
  color: '#595959',
},
  disabled);

export const AppointTransparentButton = styled('button')({
  ...buttonStyles,
  '&:hover': {
    ...buttonStyles['&:hover'],
    color: 'black',
    backgroundColor: PlatformStyles.LightGray,
    boxShadow: 'none',
   },
  backgroundColor: PlatformStyles.Transparent,
  boxShadow: 'none',
  color: 'black',
},
  disabled);
