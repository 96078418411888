const inProgressPill = <div className='functionBarPill functionBarPillInProgress'>In Progress</div>
const unPublishedPill = <div className='functionBarPill functionBarPillInProgress'>Unpublished</div>
const publishedPill = <div className='functionBarPill functionBarPillPublished'>Published</div>
const closedPill = <div className='functionBarPill functionBarPillClosed'>Closed</div>
const deletedPill = <div className='functionBarPill functionBarPillClosed'>Deleted</div>
const activePill = <div className='functionBarPill functionBarPillsGray'>Active</div>

export function AdministratorFilePills(published, closed) {
    return FilePills(published, closed, false)
}

export function StudentFilePills(published, closed) {
    return FilePills(published, closed, true)
}

export function DeletedPills(deleted){
    if (deleted){
        return deletedPill
    }
    else{
        return activePill
    }
}

function FilePills(published, closed, isStudent) {
    if(closed){
        return closedPill
    }
    if(published){
        return publishedPill
    }
    if(isStudent) {
        return unPublishedPill
    } else {
        return inProgressPill
    }
}

