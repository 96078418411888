import MaterialTable from "@material-table/core";
import { React, useContext, useLayoutEffect, useState } from 'react';
import UserContext from '../../context/UserContext';
import './Table.css';

import { PlusCircleOutlined } from '@ant-design/icons';
import PlatformStyles from "../../PlatformStyles";

export default function Table(props) {
    const {userData, isMobile} = useContext(UserContext)
    const [tableInfo, setTableInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    function onRowClick(e, rowData){
        props.handleRowClick(rowData)
    }
    
    useLayoutEffect(() => {
        const type = props.type
        switch(type){
            case 'userDetails': 
                setTableInfo({
                    title:"User Details",
                    data: props.data,
                    columns: props.columns,
                    // This is with the export button, not sure why I had it before
                    // options: {search: true, paging: false, filtering: false, exportButton: true.valueOf, tableStyle: {borderRadius: '20px'}},
                    options: {
                        search: true, 
                        paging: true,
                        pageSize: 10,
                        filtering: false,
                         tableStyle: {
                            borderRadius: '20px'
                        }
                    },
                    actions: [{
                        icon: () => <PlusCircleOutlined />,
                        tooltip: 'Add Students',
                        onClick: () =>  {props.handleAddStudents()},
                        isFreeAction: true
                      }],
                      style: {borderRadius: '10px', boxShadow: '0 0 4px 2px rgb(214, 214, 214)', paddingBottom: '10px'},
                      onRowClick: onRowClick
                })
                break;
            case 'studentDashboard':
                setTableInfo({
                    title:"Your Schedules",
                    data: props.data,
                    columns: props.columns,
                    options: { search: true, paging: false, filtering: false },
                    style: {borderRadius: '10px', boxShadow: '0 0 4px 2px rgb(214, 214, 214)', paddingBottom: '10px'},
                    onRowClick: onRowClick
                })
                if (isMobile){
                    setTableInfo({
                        title:"Your Schedules",
                        data: props.data,
                        columns: props.columns,
                        options: { search: false, paging: false, filtering: false },
                        style: {borderRadius: '5px', boxShadow: '0 0 4px 2px rgb(214, 214, 214)', paddingBottom: '10px'},
                        onRowClick: onRowClick
                    })
                }
                break;
            case 'general':
              let actions = []
              if (props.handleAdd) {
                actions = [{
                  icon: () => <PlusCircleOutlined />,
                  tooltip: props.addFunctionToolTip,
                  onClick: () =>  {props.handleAdd()},
                  isFreeAction: true
                }]
              }
              setTableInfo({
                title: props.title,
                data: props.data,
                columns: props.columns,
                options: { search: true, paging: false, filtering: false, exportButton: true },
                actions,
                style: {...props.style, borderRadius: PlatformStyles.BorderRadius, boxShadow: PlatformStyles.BoxShadow},
                onRowClick: onRowClick
              })
              break;
            case 'adminDashboard':
                setTableInfo({
                    title:"Schedules",
                    data: props.data,
                    columns: props.columns,
                    options: { search: true, paging: false, filtering: true, exportButton: true},
                    style: {borderRadius: '10px', boxShadow: '0 0 4px 2px rgb(214, 214, 214)', paddingBottom: '10px'},
                    onRowCLick: onRowClick
                })
                break;
            case 'removeStudent':
                setTableInfo({
                    title: <div>Count: {props.data.length}</div>,
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false, exportButton: false},
                    style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', marginTop: '15px', tableStyle: 'fixed'},
                    
                })
                break;
            case 'removeClinical':
                setTableInfo({
                    title: <div>Count: {props.data.length}</div>,
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false, exportButton: false},
                    style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', marginTop: '15px', tableStyle: 'fixed'},
                    
                })
                break
            case 'entity':
                setTableInfo({
                    title: props.title,
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false, exportButton: true, pageSize: 10},
                    style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', tableStyle: 'fixed'},
                    actions: [{
                        icon: () => <PlusCircleOutlined />,
                        tooltip: 'Add Entity',
                        onClick: () =>  {props.handleAddEntity()},
                        isFreeAction: true
                      }],
                    onRowClick: onRowClick
                    
                })
                break
            case 'superUserFiles':
                setTableInfo({
                    title: 'All Files',
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false},
                    style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', marginTop: '15px', tableStyle: 'fixed'},
                    onRowClick: onRowClick
                })
                break
            case 'superUserAdministrators':
                setTableInfo({
                    title: 'Administrators',
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false, exportButton: true},
                    style: {overflowWrap: 'anywhere', marginTop: '15px', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', tableStyle: 'fixed'},
                    actions: [{
                      icon: () => <PlusCircleOutlined />,
                      tooltip: 'Add Administrator',
                      onClick: () =>  {props.handleAddAdminstrator()},
                      isFreeAction: true
                    }],
                    onRowClick: onRowClick
                })
                break
            case 'superUserStudents':
                setTableInfo({
                    title: 'Students',
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false, exportButton: true},
                    style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', tableStyle: 'fixed'},
                    onRowClick: onRowClick
                })
                break
            case 'systemSettings':
                setTableInfo({
                    title: 'System Settings',
                    data: props.data,
                    columns: props.columns,
                    options:{ search: true, paging: true, filtering: false, exportButton: true, pageSize: 10},
                    actions: [{
                        icon: () => <PlusCircleOutlined />,
                        tooltip: 'Add Setting',
                        onClick: () =>  {props.handleAddSetting()},
                        isFreeAction: true
                      }],
                    style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', tableStyle: 'fixed'}
                })
                break
              case 'feedback':
              setTableInfo({
                  title: props.title,
                  data: props.data,
                  columns: props.columns,
                  options: { rowStyle: {height: '50px'}, search: true, paging: true, filtering: false, exportButton: true, pageSize: 5 },
                  style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', tableStyle: 'fixed'},
                  onRowClick: onRowClick
              })
              break
            case 'clinicalSites':
              setTableInfo({
                  title: props.title,
                  data: props.data,
                  columns: props.columns,
                  options:{ search: true, paging: true, filtering: false, exportButton: true, pageSize: 10},
                  style: {overflowWrap: 'anywhere', fontSize: '15px', borderRadius: '10px', boxShadow: '0 0 4px 1px rgb(214, 214, 214)', paddingBottom: '0px', tableStyle: 'fixed'},
                  actions: [{
                      icon: () => <PlusCircleOutlined />,
                      tooltip: 'Link Site',
                      onClick: () =>  {props.handleLinkSite()},
                      isFreeAction: true
                    }],                  
              })
              break
            default:
               break
        }
        setIsLoading(false)
    }, [userData, props])

    if (isLoading) {
        return(<div className='main'></div>)
    }

    return (
      <MaterialTable
          style={tableInfo.style}
          title={tableInfo.title}
          data={tableInfo.data}
          actions={tableInfo.actions}
          columns={tableInfo.columns}
          options={tableInfo.options}
          onRowClick={tableInfo.onRowClick}
      />   
    );
};
