import capitalize from "../../functions/capitalize"

export function listToString(receviedList){
    if (receviedList !== undefined || receviedList == null ){
        var returnList = ""
        for (var i=0; i < receviedList.length; i++){
            if (receviedList[i] == null) continue;  
            returnList = returnList.concat(capitalize(receviedList[i].toString()))
            if (i !== receviedList.length - 1){
                returnList = returnList.concat(', ')
            }
        }
        return returnList
    } else {
        return ''
    }
}

export function listToStringWithDelimeter(receviedList, delimeter){
  if (receviedList !== undefined){
      var returnList = ""
      for (var i=0; i < receviedList.length; i++){
          returnList = returnList.concat(capitalize(receviedList[i].toString()))
          if (i !== receviedList.length - 1){
              returnList = returnList.concat(delimeter)
          }
      }
      return returnList
  } else {
      return ''
  }
}