export default function secondsToMilitaryTime(seconds){
    const hours = Math.floor(seconds/3600)
    const minutes = (seconds - (hours * 3600)) / 60
    return doubleDigit(hours) + ":" + doubleDigit(minutes)
}

function doubleDigit(x){
    // if num between 1 - 9, converts to two digit format
        if (x.toString().length == 1){
            return "0" + x.toString()
        } else {
            return x.toString()
        }
    }