import { makeStyles } from "@mui/styles";
import { MenuItem, InputBase, Menu, Divider, TextField } from "@mui/material";
import CheckIcon from "@material-ui/icons/Check";
import { useEffect, useState, useContext } from "react";
import { Button } from "reactstrap";
import {socket} from '../../../socket'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import UserContext from '../../../context/UserContext'
import PlatformStyles from "../../../PlatformStyles";
import { AppointBlueButton } from "../../../utils/AppointButton";

const useStyles = makeStyles(() => ({
  DropDownButton: {
    margin: "12px 0px 0px 0px",
    fontSize: "1rem",
    width: "320px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none !important",
    borderRadius: PlatformStyles.BorderRadius,
    boxShadow: PlatformStyles.BoxShadow +' !important',
    
    backgroundColor: "white !important",
    color:"black !important",
    cursor: "pointer",
    padding: "0px 20px",
    "&:hover":{
      backgroundColor: '#e6e6e6 !important',
      color: 'black'
    }
  },
  fileUploadButton: {
    marginBottom: '20px',
    marginLeft: '7px'
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  search: {
    position: "relative",
    borderRadius: PlatformStyles.BorderRadius,
    marginRight: "20px",
    marginLeft: 0,
    width: "100%",
    border: "1px solid grey"
  },
  searchIcon: {
    padding: '0 2px',
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputInput: {
    // padding: '10px, 1px, 1px, 0',
    // marginLeft: '50px',
    // transition: "width",
    width: "100%"
  },
  searchBarContainer: {
    minWidth: "inherit",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "default",
    "&.MuiListItem-button": {
      "&:hover": {
        backgroundColor: "white"
      }
    }
  },
  menuDivider: {
    margin: "0 20px"
  },
  dashboardSelectMenu: {
    "& .MuiPopover-paper": {
      minWidth: "380px",
      maxWidth: "fit-content",
      transform: 'translate(0%, 35%)',

    }
  },
  externalLinkIcon: {
    borderLeft: "1px solid var(--color-gray-eighty-five)",
    padding: "10px 0px 10px 10px",
    color: "var(--color-primary)",
    cursor: "pointer"
  },
  checkedItem: {
    color: "indigo"
  }
}));

const SingleAddStudent = ({schoolID, fileID, addTestingData}) => {
  // Needed to get schoolID
  const {userData, setUserData} = useContext(UserContext)

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selection, setSelection] = useState('');
  const [selectionEmail, setSelectionEmail] = useState('');
  const [options, setOptions] = useState([])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {      
      socket.emit('studentSearch', schoolID, searchText);
      socket.on('studentSearch', result => {
        socket.off('studentSearch');
        setOptions(result)
      });
      
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])


  const handleMenuOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    socket.emit('studentSearch', userData.user.schoolID, searchText);
    socket.on('studentSearch', result => {
      socket.off('studentSearch');
      setOptions(result)
    });
  };

  const handleClose = (e) => {   
    if (e.target.id !== selection && e.target.id !== "") {
      let studentInfo = e.target.id.split('^&*')
      setSelection(studentInfo);
      setSelectionEmail(studentInfo[1]);
    }
    setAnchorEl(null);
    setOptions([])    
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleAdd = () => {
    // we add a fake header to mimic what an excel file with a single email would look like
    // This allows us to use the same backend function
    let file = [['Emails'], [selectionEmail, '', '']]

    if(selectionEmail != ''){
      socket.emit('addStudentsToFile', file, fileID, addTestingData)
    }
  }

  return (
    <div style={{marginBottom: '30px'}}>
      <Button
        type="button"
        className={classes.DropDownButton}
        onClick={handleMenuOpen}
      >
        {selection == ''?
          <i>Add Student</i>
          :
          selection
        }
        <KeyboardArrowDownIcon />
      </Button>
      {renderDashboardMenu()}
      <AppointBlueButton disabled={selection == ''} style={{height: '30px', width: '80px !important', lineHeight: '13px', marginTop: '5px'}} onClick={() => handleAdd()}>Add</AppointBlueButton>
    </div>
  );

  function renderDashboardMenu() {

    function renderOption(name, email) {
      // ^&* We use this as something unique that we can split on later
      return <div style={{height: '100%'}} id={name + '^&*' + email}>{name} <i id={name + '^&*' + email} style={{fontSize: '13px'}}>{email}</i></div>;
    }

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted={true}
          open={!!anchorEl}
          onClose={handleClose}
          className={classes.dashboardSelectMenu}
          transformOrigin={{
            vertical: -5
          }}
        >
          <MenuItem
            className={classes.searchBarContainer}
            disableTouchRipple={true}
          >
            <TextField
              value={searchText}
              onChange={handleSearchChange}
              id="Student Name" 
              placeholder="Student Name"
              style={{
                width: '100%'
              }}
              />
          </MenuItem>
          <Divider />
          {options.length === 0 ? (
            <MenuItem disabled>
              <i>No Students Found</i>
            </MenuItem>
            ) : (
              options.map((item, index) => {
                return (
                  <div key={index}>
                    <MenuItem onClick={(e) => handleClose(e)}>
                      {renderOption(item.label, item.email)}
                    </MenuItem>
                    <Divider className={classes.menuDivider} />
                  </div>
                );
              })
          )}
        </Menu>
      </>
    );
  }
}

export default SingleAddStudent;
