import React, { useContext, useState } from 'react'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import UserContext from '../../context/UserContext'
import { useHistory } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import loggedIn from '../../functions/checkLoggedIn'
import Loading from '../Loading/Loading';
import belongsToSuperUserEntity from '../../functions/belongsToAppointEntity';
import Table from '../Users/Table';
import { notify } from "../Notification/notify";
import config from '../../config';
import { Button, Modal } from  'react-bootstrap'
import { makeStyles } from "@mui/styles";
import { TextField } from '@mui/material';
import { getAuthHeader } from '../../functions/getToken';
import { normalizeTime } from '../../functions/normalizeTime';
import { AppointBlueButton, AppointGreyButton } from '../../utils/AppointButton';

const useStyles = makeStyles(() => ({
  Input: {
    width: '100%'
  }
}));

export default function SystemSettings(props){
  const history = useHistory();
  const classes = useStyles();
  const {userData, setUserData} = useContext(UserContext)
  
  const [isLoading, setIsLoading] = useState(true);
  const [systemSettings, setSystemSettings] = useState([])
  const defaultEditInfo = {name: '', value: '', description: ''}
  const [editInformation, setEditInformation] = useState(defaultEditInfo)
  const [settingModal, setSettingModal] = useState(false)
  
  useLayoutEffect(() =>{
    // Checks that the user is logged in. If not, pushes them to the login page
    if(!loggedIn(localStorage.getItem("auth-token"))){
      history.push('/login')
      return
    }

    if (!belongsToSuperUserEntity(userData.user.schoolID)) {
      history.push('/dashboard')
      return
    }

    axios.post(axiosURL + '/systemSetting/getAll', null, getAuthHeader())
      .then(res => {
        if (res.data.success) {
          for (let setting of res.data.data) {
            setting.updatedAt = normalizeTime(setting.updatedAt)
            setting.createdAt = normalizeTime(setting.createdAt)
          }
          setSystemSettings(res.data.data)
          setIsLoading(false)
        } else {
          notify('e', res.data.message)
        }
        
      })
        
    
  }, [isLoading, history, userData.user.schoolID])

  if(isLoading){
    return <Loading />
  }

  const columns = [
    {
        title: "Name",
        field: "name"
    },
    {
        title: "Value",
        field: "value",
    },
    {
        title: "Description",
        field: "description",
    },
    {
        title: "Created",
        field: "createdAt",
    },
    {
      title: "Updated",
      field: "updatedAt",
    },
    {
      title: "",
      render: (rowData) => (
        <img onClick={() => {openEditSetting(rowData)}} alt="logo" src={config.editIcon} className="editIcon" />          
      ),
      filtering: false
    },
  ];

  function openEditSetting(rowData) {
    setEditInformation(rowData)
    setSettingModal(true)
  }

  function closeSettingModal() {
    setEditInformation(defaultEditInfo)
    setSettingModal(false)
  }

  function SettingModal(props) {
    const [name,setName] = useState(editInformation.name)
    const [value, setValue] = useState(editInformation.value)
    const [description, setDescription] = useState(editInformation.description)
    const [password, setPassword] = useState('')

    async function handleSubmit() {
      const packagedData = {
        name,
        value,
        description,
        password,
        existingSetting: editInformation.name === '' ? false : true
      }
      await axios.post(axiosURL + '/systemSetting/CreateOrEdit', packagedData, getAuthHeader())
        .then(res => {
          if (res.data.success) {
            setSystemSettings(res.data.data)
            closeSettingModal()
          } else {
            notify('e', res.data.message)
          }
        })
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            System Setting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField 
            disabled={editInformation.name !== ''}
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{marginTop:'10px'}}
            className={classes.Input}
            id="name"
            label="name" 
          />
          <br/>
          <TextField 
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{marginTop:'10px'}}
            className={classes.Input}
            id="value"
            label="value" 
          />
          <br/>
          <TextField 
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{marginTop:'10px'}}
            className={classes.Input}
            id="description"
            label="description" 
          />

          <TextField 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{marginTop:'10px'}}
            className={classes.Input}
            id="password"
            label="password" 
          />
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
          <AppointBlueButton onClick={handleSubmit}>Save</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function handleAddSetting() {
    setSettingModal(true)
  }

  return(
    <div className='main'>
      <SettingModal 
        show={settingModal} 
        onHide={closeSettingModal} 
      />

      <Table data={systemSettings} handleAddSetting={handleAddSetting} columns={columns} type='systemSettings' />
    </div>
  )
}



