import { Chip, FormControl, Input, Switch } from "@mui/material";
import { makeStyles} from '@mui/styles'
import { TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { axiosURL } from '../../axiosURL_Front';
import UserContext from '../../context/UserContext';
import belongsToAppointEntity from '../../functions/belongsToAppointEntity';
import checkLoggedIn from '../../functions/checkLoggedIn';
import { normalizeTime } from "../../functions/normalizeTime";
import { isAdministrator, isSuperUser } from '../../functions/UserTypeChecks';
import { AppointBlueButton, AppointGreyButton } from "../../utils/AppointButton";
import Loading from '../Loading/Loading';
import { Alert } from '../misc/Alert';
import { AdministratorFilePills } from '../misc/FilePills';
import InformationToolTip from "../misc/InformationToolTip";
import { notify } from "../Notification/notify";
import DashboardCard from './Dashboard.Card';
import './Dashboard.css';
import StudentDashboard from './Dashboard.Student';
import SuperUserEntityView from "./Dashboard.SuperUser";

export default function Dashboard(props) {
  const history = useHistory();
  /* Sets user context */
  const {userData, setUserData, isMobile} = useContext(UserContext)
  const [isLoading, setLoading] = useState(true);
  let [files, setFiles] = useState([])
  let [studentCount, setStudentCount] = useState(0)
  let [inProgressCount, setInProgressCount] = useState(0)
  let [publishedCount, setPublishedCount] = useState(0)
  let [closedCount, setClosedCount] = useState(0)

  const [newFileMessageBox, setNewFileMessageBox] = useState(false);
  if(!checkLoggedIn(localStorage.getItem('auth-token'))){history.push('/login')}

  useEffect(() =>{
      if(userData.token != ""){
        let packagedData = {
          schoolID: userData.user.schoolID,
          userID: userData.user.id
        }
  
        axios.post(axiosURL + '/files/adminUserID', packagedData)
        .then(res => {  
          setFiles(res.data)

          setInProgressCount(res.data.filter(file => file.published === false && file.closed === false).length)
          setPublishedCount(res.data.filter(file => file.published === true && file.closed === false).length)
          setClosedCount(res.data.filter(file => file.closed === true).length)

          // Format times
          for(let file of res.data) {
            file.createdAt = normalizeTime(file.createdAt)
          }

          axios.post(axiosURL + '/users/CountBySchoolID', packagedData)
          .then(res => { setStudentCount(res.data); setLoading(false) })    
        })    
      }   
  }, [userData])

  // Blank screen while data is loading in
  if (isLoading) {
    return <Loading />;
  }

  // Student Dashboard if they are a student
  if (isLoading === false && userData.user.accountType === 'student'){
    return <StudentDashboard />
  }

  // SuperUser Dashboard if they are a superuser and check schoolID since appoint is only -1
  if (isLoading === false && isSuperUser(userData.user.accountType) && belongsToAppointEntity(userData.user.schoolID)) {
    // 1 is the id of schools, we also use this page for clinical sites
    return <SuperUserEntityView type={1} />
  }
  
  // if they are a coordinator we are going to block the dashboard temporarily
  if(isMobile && isAdministrator(userData.user.accountType)) {
    return (<div className='App_MobileMessage'>While students can access their accounts on mobile, we are currently working on adapting the coordinator view to be mobile friendly. Please access the site on a laptop / desktop to continue your scheduling.</div>)
  }

  function openFile(e){
    // Clicking the pill will pass in undefined. not sure why but this was quick fix
    if(e == undefined) return
    
    // props.handleOpenFile('clinicalassistant', e)
    history.push('dashboard/clinicalassistant/' + e);    
  }

  function NewFileMessageBox(props) {
    const classes = useStyles();
	  const [currValue, setCurrValue] = useState("");
	  const [studentMessage, setStudentMessage] = useState("");
    const [fileName, setFilename] = useState("");
    let [nursingTopics, setNursingTopics] = useState([])
    let [errorMessage, setErrorMessage] = useState("")
    const [useCustomMsg, setUseCustomMsg] = useState(false)

    const handleKeyUp = (e) => {
        if (e.keyCode == 13) {
          if(nursingTopics.map((a) => { return a.toLowerCase() }).includes(e.target.value.trim().toLowerCase())){
            setErrorMessage("You cannot enter duplicate types")
          } else if(e.target.value.trim() === ''){
            return
          } else {
            // Trims white space and capitalizes first letter of each word
            setNursingTopics((oldState) => [...oldState, e.target.value
              .trim()
              .toLowerCase()
              .split(' ')
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')]);

            setCurrValue("");
            setErrorMessage("")
            setFilename(fileName)
          }            
        }
    };

    const handleTopicsChange = (e) => {
      setCurrValue(e.target.value);
    };

    const handleStudentMessageChange = (e) => {
      setStudentMessage(e.target.value);
    };

    const handleFileNameChange = (e) => {
      setFilename(e.target.value);
    };
  
    const handleDelete = ( item, index) =>{
      let arr = [...nursingTopics]
      arr.splice(index,1)
      setNursingTopics(arr)
    }

    function newFile (fileName, nursingTopics, studentMessage) {
      const packagedData = {
        schoolID: userData.user.schoolID,
        owner: userData.user.id,
        fileName,
        fileType: "CA",
        nursingTopics,
        studentMessage
      }
  
      axios.post(axiosURL + '/files/add', packagedData)
      .then(res => {
        if (res.data.success === true) {
          res.data.file.createdAt = normalizeTime(res.data.file.createdAt)
          files.unshift(res.data.file)
          setNewFileMessageBox(false)
          notify('s', 'New Schedule Created')
        } else {
          setErrorMessage(res.data.message)
        }
      })
      .catch(err => {
        notify('e', 'There was an error creating the schedule, please refresh the page and try again')
      })
    }

    return (
      <Modal
        {...props}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="messageBox"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Schedule
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
             label='Schedule Name'
             type="text"
             style={{width: "100%", marginBottom: '10px'}}
             autoComplete='off'
             value={fileName}
             onChange={handleFileNameChange}
            />
          <TextField
            placeholder='Clinical Type'
            autoComplete='off'
            style={{width: "100%", marginBottom: "5px"}}
            value={currValue}
            onChange={handleTopicsChange}
            onKeyDown={handleKeyUp}
            />
          <div className={"container"}>
              {nursingTopics.map((item,index) => (
                  <Chip size="small" onDelete={()=>handleDelete(item,index)} label={item}/>
              ))}
          </div>
          <br/>
          <div className='nursingTopicsTip'>
            <span>Clinical types are created one at a time. Input a clinical type, then hit the enter key. Remember, 
              these must match the clinical types that you import into the schedule later. These will also be the list that 
              students select from. They can be edited in the future, however, so don't stress! When the last topic has 
              been inputted, create the schedule with the blue "Create" button below.</span>
          </div>
          <br/>
          Custom Student Message
          <Switch
            checked={useCustomMsg}
            onChange={() => {setUseCustomMsg(!useCustomMsg)}}
            color={'primary'}
            inputProps={{ 'aria-label': 'controlled' }}
          />

          <InformationToolTip>This section allows you to type out a message to be shown to students when inputting their schedules</InformationToolTip>
          {useCustomMsg &&
            <TextField
              id="outlined-multiline-static"
              placeholder="Message"
              className='Home_ContactUsFormTextField'
              multiline
              value={studentMessage}
              minRows={4}
              onChange={(e) => handleStudentMessageChange(e)}
            />
          }

          <br></br>
          {errorMessage !== '' ?           
            <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>
            : 
            <></>
          }
    
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
          <AppointBlueButton onClick={() => { newFile(fileName, nursingTopics, studentMessage) }}>Create</AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }
  
  // Css for new file menu
  const useStyles = makeStyles(() => ({
    formControlRoot: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
        paddingTop: '15px',
        flexDirection: "column",
        padding: 4,
        borderRadius:'4px',
        "&> div.container": {
            gap: "6px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        "& > div.container > span": {
            padding: "1px 3px",
            borderRadius: "4px",
        }
    }
  }));

  function measureText(text){
    var dict = {
      '0':6,      '1':6,      '2':6,      '3':6,      '4':6,      '5':6,      '6':6,
      '7':6,      '8':6,      '9':6,      'a':6,      'b':6,      'c':5,      'd':6,
      'e':6,      'f':4,      'g':5,      'h':6,      'i':3,      'j':3,      'k':5,
      'l':3,      'm':9,      'n':6,      'o':6,      'p':6,      'q':6,      'r':4,
      's':5,      't':4,      'u':6,      'v':5,      'w':8,      'x':5,      'y':5,
      'z':4,      'A':6,      'B':6,      'C':6,      'D':7,      'E':6,      'F':5,
      'G':7,      'H':7,      'I':3,      'J':4,      'K':6,      'L':5,      'M':9,
      'N':8,      'O':8,      'P':7,      'Q':8,      'R':7,      'S':6,      'T':6,
      'U':7,      'V':6,      'W':10,     'X':6,      'Y':6,      'Z':5,      ' ':3,   
      '%':8,      '@':10,     '&':8    
    }
    var size = 0
    var letters = text.split('')
    for (var i=0;i<letters.length;i++){
      if(dict[letters[i]] == undefined){
        size += 6         /// average default value if not in the list, such as symbols
      }
      else{
        size += dict[letters[i]]
      }
    }
    return size
  }
 
  function CreateFileList(){
    // Keeps track of all files
    let fileList = []
    if (files.length !== 0){
      let maxLength = 0 
      for (var i=0; i<files.length; i++){

        if (measureText(files[i].fileName) > maxLength) {
          maxLength = measureText(files[i].fileName)
        }
      }

      for (var i=0; i<files.length; i++){
        // Adds to list
        fileList.push(
            <div className='fileRow'>
              <button style={{zIndex: "10", maxWidth: maxLength*1.6+50}} className='fileName' key={files[i]._id} value={files[i]._id} onClick={(e) => openFile(e.target.value)}>
                {files[i].fileName}
              </button>

              <button style={{zIndex: "10"}} className='fileName' key={files[i]._id} value={files[i]._id} onClick={(e) => openFile(e.target.value)}>
                {AdministratorFilePills(files[i].published, files[i].closed)}
              </button>
    
              <button className='lastModified' key={files[i].fileName} value={files[i]._id} onClick={(e) => openFile(e.target.value)}>
                {files[i].createdAt}</button>
            </div>
          )
      }
    } else {
      fileList.push(<button className='noFilesAlert' onClick={() => setNewFileMessageBox(true)}>Add a new schedule to start working!</button>)
    }
    return fileList
  }

  // Used to create the cards at the top of the page
  const cards = [
    {
      icon: 'team',
      color: '#424c75',
      title: 'Students',
      number: studentCount,
    },
    {
      icon: 'inProgressSchedule',
      color: '#424c75',
      title: 'In Progress',
      number: inProgressCount,
    },
    {
      icon: 'publishedSchedule',
      color: '#424c75',
      title: 'Published',
      number: publishedCount,
    },
    {
      icon: 'closedSchedule',
      color: '#424c75',
      title: 'Closed',
      number: closedCount,
    },
  ]

  // Used to insert the cards at the top of the page
  const numberCards = cards.map((item, key) => (
    // <Col key={key} lg={6} md={12}>
      <DashboardCard {...item} />
    // </Col>
  ))

  return(
    <div className='main'>
      {/* Box that pops us when you are making a new file */}
      <NewFileMessageBox
          show={newFileMessageBox}
          onHide={() => {setNewFileMessageBox(false)}}
          // onSubmit={}
          
      />

      {/* Cards at the top of the page that show information */}
      <div className='DashboardCards'>
        {numberCards}
      </div>

      <div className='fileContentSection'>

          <div className='header'>
            <span id='fileNameHeader'>Schedule Name<AppointBlueButton className='newFileButton' variant="primary" onClick={() => setNewFileMessageBox(true)}>New Schedule</AppointBlueButton></span>
            
            <span id='lastModifiedHeader'>Created Time</span>
          </div> 
          
          <CreateFileList />  
      </div>

    </div>
  )
}
