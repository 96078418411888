export function normalizeTime(time){ 
  const date = new Date(Date.parse(time))

  let dateString;
  const currentDate = new Date();
  if (date.toDateString() === currentDate.toDateString()) {
    dateString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
  } else if (date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
    dateString = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  } else {
    dateString = date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
  }
  
  return dateString
}

export function toReadableDate(dateString) {
  let date = new Date(dateString);
  let formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  return formattedDate
}