import React, { useContext, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import belongsToSuperUserEntity from '../../functions/belongsToAppointEntity';
import loggedIn from '../../functions/checkLoggedIn';
import Loading from '../Loading/Loading';
import InternalFeedbackView from './InternalFeedbackView';
import UserFeedbackView from './UserFeedbackView';

export default function Feedback(props){
  const history = useHistory();
  const {userData, setUserData} = useContext(UserContext)
  
  const [isLoading, setIsLoading] = useState(true);
  
  useLayoutEffect(() =>{
    // Checks that the user is logged in. If not, pushes them to the login page
    if(!loggedIn(localStorage.getItem("auth-token"))){
      history.push('/login')
      return
    }
    
    setIsLoading(false)

  }, [isLoading, history])

  if(isLoading){
    return <Loading />
  }

  return(
    <>
      {belongsToSuperUserEntity(userData.user.schoolID) ? 
          // **************** SUPERUSER VIEW ****************
          <InternalFeedbackView />
        :
          // **************** USER VIEW ****************
          <UserFeedbackView />
      }
    </>
  )
}
