import { makeStyles } from '@mui/styles';
import PlatformStyles from '../../../PlatformStyles';
import React, { useState } from 'react'
import axios from 'axios';
import { axiosURL } from '../../../../src/axiosURL_Front'
import '../Content/Content.css'
import { TextField } from '@mui/material';
import {FormControl} from '@mui/material';
import Box from '@mui/material/Box'
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles(() => ({
    Input: {
      borderRadius: PlatformStyles.BorderRadius,
      border: 'none',
      // width: '320px',
      width: '100%',
    },
    Header: {
      marginTop: '15px',
      float: 'center'
    }
  }));

export default function ContactUs() {
    const classes = useStyles()  

    const [nameInput, setNameInput] = useState('')  
    const [emailInput, setEmailInput] = useState('')
    const [subjectInput, setSubjectInput] = useState('')
    const [msgInput, setMsgInput] = useState('')
    const [sent, setSent] = useState(false)

    function contactUsSubmit(){

        var info = {
          name: nameInput,
          email: emailInput,
          subject: subjectInput,
          msg: msgInput
        }
    
        axios.post(axiosURL + '/users/contactUs', info)
          .then(res => {
            if(res.data == true){
              setSent(true)
              document.getElementById('Home_ContactUsFormSubmitButton').style.backgroundColor = '#808080'
              setNameInput('')
              setEmailInput('')
              setSubjectInput('')
              setMsgInput('')
            }
          })
      }

       
  
  return(
    <div className= {(isMobile ?  'ContactUs_Body ContactUs_BodyMobile': 'ContactUs_Body')} >
        <h1 className={classes.Header}>Contact Us</h1>
        <form onSubmit={contactUsSubmit} >
          <FormControl>
            
            <Box sx={{display:'flex', flexDirection: (isMobile ? 'column' : 'row') }}> {/* Outter Box */}
              <Box sx={{width:(isMobile ? '90%' : '45%'), margin:'auto', marginTop:'5px'}}> {/* Left Box */}
                {/* Name */}
                <TextField
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                    style={{marginTop:'10px'}}
                    className={classes.Input}
                    id="Name"
                    label="Name" 
                />

                {/* Email */}
                <TextField 
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    style={{marginTop:'10.5px'}}
                    className={classes.Input}
                    id="Email"
                    label="Email Address" 
                />

                {/* Subject */}
                <TextField 
                    value={subjectInput}
                    onChange={(e) => setSubjectInput(e.target.value)}
                    style={{marginTop:'10px'}}
                    className={classes.Input}
                    id="Subject"
                    label="Subject" 
                />

              </Box>
              <Box sx={{width:(isMobile ? '90%' : '45%'), margin:'auto', marginBottom:'10px' , marginTop:'5px'}}> {/* Right Box */}
                {/* Message */}
                <TextField 
                    value={msgInput}
                    onChange={(e) => setMsgInput(e.target.value)}
                    style={{marginTop:'10px'}}
                    className={classes.Input}
                    id="Message"
                    label="Message" 
                    multiline={true}
                    rows = {6.85}
                />
              </Box>
            </Box>

          </FormControl>
            {/* SUBMIT BUTTON */}
            <div>
                <input style={{marginTop:'10px', marginBottom:'30px', 
                width: (isMobile ? '90%':'665px'), 
                borderColor: '#364A5E', backgroundColor: '#364A5E'}} type="submit" value="Submit" className="btn btn-primary" />
            </div>
        </form>
    </div>

  )
}