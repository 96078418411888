import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosURL } from "../../axiosURL_Front";
import config from "../../config";
import UserContext from "../../context/UserContext";
import capitalize from "../../functions/capitalize";
import loggedIn from "../../functions/checkLoggedIn";
import { convertEntityType } from "../../functions/entityTypeHelpers";
import { getAuthHeader } from "../../functions/getToken";
import openInGoogleMaps from "../../functions/openInGoogleMaps";
import PlatformStyles from "../../PlatformStyles";
import { AppointTileWrapper } from "../../utils/AppointTileWrapper";
import Loading from "../Loading/Loading";
import { notify } from "../Notification/notify";

// We pass in type to denote whether we are loading schools or clinical sites
// 1 is for schools, 2 if for clinical sites
export default function ClinicalSites({ type }) {
  const useStyles = makeStyles(() => ({
    AppointTile: {
      padding: "5px !important",
      width: "100%",
      minWidth: "150px",
      maxWidth: "300px",
      height: "100px",
      margin: "10px",
      display: "flex",
      cursor: "pointer",
      boxShadow: "none !important",
      border: PlatformStyles.GrayBorder,
      "&:hover": {
        boxShadow: PlatformStyles.BoxShadow + " !important",
        bottom: "3px",
        position: "relative",
      },
    },
  }));

  const history = useHistory();
  const classes = useStyles();
  /* Sets user context */
  const { userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSite, setSelectedSite] = useState(null)
  const [siteChecklists, setSiteChecklists] = useState(new Map())
  let [entities, setEntities] = useState([]);

  useLayoutEffect(() => {
    // Checks that the user is logged in. If not, pushes them to the login page
    if (!loggedIn(localStorage.getItem("auth-token"))) {
      history.push("/login");
      return;
    }
    const promises = [
      axios.post(axiosURL + '/entity/getLinkedSites', { schoolId: userData.user.schoolID }, getAuthHeader()),
      axios.post(axiosURL + '/files/getChecklistForLinkedSites', { entityId: userData.user.schoolID}, getAuthHeader())
    ]

    Promise.allSettled(promises).then((responses) => {
      const linkledSitesRequest = responses[0]
      const checklistItemsRequest = responses[1]

      
      if (
        linkledSitesRequest.status === 'rejected' ||
        checklistItemsRequest.status === 'rejected'
      ) {
        history.push('/dashboard')
        notify('e', 'There was an error, please try again')
        return
      }

      // Linked Sites Request
      if (!linkledSitesRequest.value.data.success) {
        history.push('/dashboard')
        notify('e', linkledSitesRequest.value.data.message)
        return
      }
      let tempData = []
      for (let entity of linkledSitesRequest.value.data.data) {
        console.log(entity)
        tempData.push({
          name: entity.name,
          id: entity.id,
          type: convertEntityType(entity.type),
          address: entity.address.address,
          placeId: entity.address.placeId,
          rowClick: { name: entity.name, id: entity.id, type: entity.type },
        })
      }
      setEntities(tempData)

      // Checklists Request
      if (!checklistItemsRequest.value.data.success){
        history.push('/dashboard')
        notify('e', checklistItemsRequest.value.data.message)
        return
      }
      setSiteChecklists(checklistItemsRequest.value.data.data)

      setIsLoading(false)      
    })

  }, [isLoading, userData, history]);

  if (isLoading) {
    return <Loading />
  }

  function renderSiteName(name, address) {
    return (
      <div>
        <div
          style={{
            width: "100%",
            height: "fit-content",
            fontSize: "16px",
          }}
        >
          {capitalize(name)}
        </div>
        <div style={{ width: "100%", fontSize: "12px" }}>
          {address}
        </div>
      </div>
    )
  }

  return (
    <AppointTileWrapper
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <h3 style={{ width: '100%' }}>Clinical Sites</h3>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignContent: 'flex-start',
          width: selectedSite ? '48%' : '100%',
          borderRight: selectedSite ? '1px solid ' + PlatformStyles.Gray : 'none',
        }}
      >
        {entities.map((entity) => {
          return (
            <AppointTileWrapper
              className={classes.AppointTile}
              onClick={() => {
                setSelectedSite(entities.filter((e) => e.id === entity.id)[0])
              }}
            >
              <div
                style={{
                  backgroundColor: PlatformStyles.LogoLightBlue,
                  height: '100%',
                  width: '7px',
                  borderRadius: '10%',
                  marginRight: '10px',
                }}
              ></div>
              {renderSiteName(entity.name, entity.address)}
            </AppointTileWrapper>
          )
        })}
      </div>

      {selectedSite && (
        <div
          style={{
            width: '50%',
            padding: '10px',
            position: 'relative',
          }}
        >
          {renderSiteName(selectedSite.name, selectedSite.address)}
          <img
            alt="Close"
            src={config.cancelIcon}
            className="trashCanIcon" // Gives the red on hover
            style={{
              position: 'absolute',
              width: '20px',
              height: '20px',
              top: '0',
              right: '0',
              cursor: 'pointer',
            }}
            onClick={() => setSelectedSite(null)}
          />
          <br></br>
          Checklist Items:
          {siteChecklists[selectedSite.id].length === 0 ? (
            <div
              style={{
                fontSize: '14px',
                width: '100%',
                padding: '10px',
                margin: '10px',
              }}
            >
              No Checklist items for clinical site
            </div>
          ) : (
            siteChecklists[selectedSite.id].map((item) => {
              return (
                <div
                  style={{
                    fontSize: '14px',
                    backgroundColor: PlatformStyles.LightGray,
                    borderRadius: PlatformStyles.BorderRadius,
                    boxShadow: PlatformStyles.BoxShadow,
                    width: '100%',
                    padding: '10px',
                    margin: '10px',
                  }}
                >
                  {item.value}
                </div>
              )
            })
          )}
        </div>
      )}
    </AppointTileWrapper>
  )
}