// import ErrorMessage from '../misc/ErrorMessage'
import React, { useContext, useLayoutEffect, useState  } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import UserContext from '../../context/UserContext'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import checkLoggedIn from '../../functions/checkLoggedIn'
import '../Register/Register.css'
import { TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";
import PlatformStyles from '../../PlatformStyles';
import { Alert } from '../misc/Alert';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles(() => ({
  Input: {
    // backgroundColor: 'red',
    borderRadius: PlatformStyles.BorderRadius,
    border: 'none',
    width: '300px',
  },
  BackToLogin: {
    float: 'right',
    fontSize: '13px'
  },
  Header: {
    marginTop: '15px',
    marginLeft: '28px',
    float: 'left'
  }
  
}));

export default function PasswordReset() {

  // If they are already logged in, it goes to the dashboard
  useLayoutEffect(() => {
    if(checkLoggedIn(localStorage.getItem('auth-token'))){
      history.push('/dashboard')
    }
  }, [])
  
  const history = useHistory();
  const classes = useStyles();
  const { setUserData } = useContext(UserContext)

  let [isRequest, setIsRequest] = useState(false)
  let [isExpiredLink, setIsExpiredLink] = useState(false)
  let [token, setToken] = useState('')

  let [email, setEmail] = useState('')
  
  let [password, setPassword] = useState('')
  let [confirmPassword, setConfirmPassword] = useState('')
  let [showPassword, setShowPassword] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')

  const [disableEmailInput, setDisableEmailInput] = useState(false)
  const [disablePasswordInput, setDisablePasswordInput] = useState(false)



  useLayoutEffect(() =>{
    let token = window.location.href.split('/')
    token = token[token.length - 1]
    setToken(token)
    
    if (token == 'request'){
      setIsRequest(true)
    } else {
      const packagedData = {
        token
      }

      axios.post(axiosURL + '/users/accountTypeByToken', packagedData)
      .then(res => {
        if(res.data.success == true)  {
          setIsRequest(false)
        } else {
          setErrorMessage('Password Reset Link Expired')
          setDisablePasswordInput(true)
          setIsExpiredLink(true)
        }
      })
    }

        
  }, [])

  const submit = (e) => {
    e.preventDefault();

    if (isRequest){
      setErrorMessage('')
      setConfirmationMessage('')

      if (email == '') {
        setErrorMessage('Email Required')
        return
      }

      email = email.toLowerCase()
      const packagedData = { email }

      axios.post(axiosURL + '/users/resetPasswordRequest', packagedData)
      .then(res => {
        console.log(res.data.success == false)
        if(res.data.success == false){
          setErrorMessage(res.data.message)
        } else if (res.data.success) { 
          setEmail('')
          setDisableEmailInput(true)
          setConfirmationMessage(res.data.message)
        }   
      })
      
    } else if (!isExpiredLink) {
      setErrorMessage('')
      setConfirmationMessage('')
      if (password == '') {
        setErrorMessage('New Password Required')
        return
      } else if (confirmPassword == '') {
        setErrorMessage('Confirm Password Required')
        return
      } else if (password != confirmPassword) {
        setErrorMessage('Passwords must match')
        return
      }

      const packagedData = { 
        token,
        password,
        newPassword: confirmPassword
      }
      console.log(packagedData)

      axios.post(axiosURL + '/users/resetPassword', packagedData)
        .then(res => {
          if(!res.data.success){
            setErrorMessage(res.data.message)
          } else if (res.data.success) { 
            setDisablePasswordInput(true)
            setConfirmationMessage(res.data.message)
          } 
        })
      
      
    }
  }

  return(
    <div>

        <div className='body'>
          
            <h3 className={classes.Header}>Reset Password</h3>
            <form onSubmit={submit} className='form'>
                {/* Email */}
                {isRequest ? 
                  <TextField 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{marginTop:'10px'}}
                      className={classes.Input}
                      id="Email"
                      label="Email"
                      disabled={disableEmailInput} 
                    /> 
                  :
                  <>
                    <TextField 
                      value={password}
                      disabled={disablePasswordInput}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{marginTop:'10px', marginBottom:'10px'}}
                      className={classes.Input}
                      id="New Password"
                      label="New Password" 
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                          endAdornment: 
                          <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  disabled={disablePasswordInput}
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                              >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>,
                      }}
                    />

                    <TextField 
                      value={confirmPassword}
                      disabled={disablePasswordInput}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{marginTop:'10px', marginBottom:'10px'}}
                      className={classes.Input}
                      id="Confirm Password"
                      label="Confirm Password" 
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                          endAdornment: 
                          <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  disabled={disablePasswordInput}
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                              >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>,
                      }}
                    />
                  </>
                }

                {/* SUBMIT BUTTON */}
                <div>
                    <input disabled={disablePasswordInput || disableEmailInput} style={{marginTop:'20px', marginBottom:'10px'}} type="submit" value="Reset Password" className="btn btn-primary" />
                </div>

                {errorMessage !== '' &&
                  <Alert severity="error">{errorMessage}</Alert>  
                }

                {confirmationMessage !== '' &&
                  <Alert severity="success">{confirmationMessage}</Alert>  
                }

                <br></br>

                {isRequest || disablePasswordInput ?
                  <a href='/Login' className={classes.BackToLogin}>
                    Back to Login
                  </a>
                  :
                  <></>
                }
                
            </form>
        </div>
    </div>
  )
}