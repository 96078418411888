import React, {} from 'react'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import './settings.css'

export default function Settings() {
  
  return(
      <div className="">Settings
      </div>
  )
}