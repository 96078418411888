import React from 'react';
import { isMobile } from 'react-device-detect';
import config from '../../../config';

export default function Features(props) {
  return(
    <div className='center'>
      <iframe
        title='Appoint Clinical Assistant'
        style={{
            width: '89vw',
            height: '50vw',
            maxWidth: '62rem',
            maxHeight: '35rem',
            borderRadius: '10px'
        }}
        src="https://www.youtube.com/embed/ArRhvu0ycJ4"
        allow="autoplay; fullscreen"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>

    <p className='home_FeaturesGlanceTitle'>
      Features At a Glance
    </p>

    <p className='home_FeaturesGlanceText SecondaryText center'>
    We want to simplify your daily workflow, not complicate it. Our tool makes scheduling more efficient. You will spend less time on the same task and get better results.
    </p>

    <div className='center'>
      {/* FEATURE 1 */}
      <div className={'home-flex-container homeFeatures_row_imageRight' + (isMobile ?  'homeFeatures_mobile_margins': '')}>
        <span className='home_FeatureTextBlock'>
          <p className={(isMobile ?  'HomeFeatureTitleMobile':  'HomeFeatureTitle')} id='keyPoint1'>Easy-to-use Platform</p>
          <p className={(isMobile ?  'HomeFeatureTextMobile center':  'HomeFeatureText SecondaryText')}>
            We have created a simple interface where organization is intuitive. All the information you need is there without any extra. 

            When you open the platform, you will get a clear snapshot of uploaded students, open schedules, and completed schedules.

            You can jump right back into the last schedule you were working on or create a brand new one. With the simple upload of clinical data and students data excel files, you are ready to start.
          </p>
        </span>
        <img src={config.easyOfUse} className="FeatureImage" />      
      </div>
      

      {/* FEATURE 2 */}
      <div className={'home-flex-container homeFeatures_row_imageLeft' + (isMobile ?  'homeFeatures_mobile_margins': '')}>
        <img src={config.algorithm} className="FeatureImage" />  
        <span className='home_FeatureTextBlock'>
          <p className={(isMobile ?  'HomeFeatureTitleMobile':  'HomeFeatureTitle')} id='keyPoint2'>Automatic Scheduling Algorithm</p>
          <p className={(isMobile ?  'HomeFeatureTextMobile center':  'HomeFeatureText SecondaryText')}>
            We empower you to create schedules in the matter of seconds. Hundreds of students can be assigned to clinicals that fit within their existing class schedule and discipline needs with the click of a button. This allows you to spend more time on your students and less time checking for errors.

            Due to the potential need for some students to switch their class times to accommodate a clinical, any outstanding student schedules that did not get automatically scheduled will appear in an outstanding list. From here it is easy to determine who needs a class change request.
          </p>
        </span>
        
                
      </div>

      {/* FEATURE 3 */}
      <div className={'home-flex-container homeFeatures_row_imageRight' + (isMobile ?  'homeFeatures_mobile_margins': '')}>
        <span className='home_FeatureTextBlock'>
          <p className={(isMobile ?  'HomeFeatureTitleMobile':  'HomeFeatureTitle')}  id='keyPoint3'>Customizable Reports and Exports</p>
          <p className={(isMobile ?  'HomeFeatureTextMobile center':  'HomeFeatureText SecondaryText')}>
            This past year taught us the importance of tracking students within the hospital. Our reports will be custom and can be pulled in seconds.

            You can filter to get information you need within a platform you already know - Excel. Once your scheduling is complete, you can easily export the schedules to email to the students. 
          </p>
        </span>
        <img src={config.customizedReports} className="FeatureImage" />          
      </div>

      {/* FEATURE 4 */}
      <div className={'home-flex-container homeFeatures_row_imageRight' + (isMobile ?  'homeFeatures_mobile_margins': '')} style = {{marginBottom: '-100px'}}>
      {/* <div className='home-flex-container homeFeatures_row_imageLeft' style = {{marginBottom: '-100px'}}> */}
        <img src={config.constantlyEvolving} className="FeatureImage" />  
        <span className='home_FeatureTextBlock'>
          <p className={(isMobile ?  'HomeFeatureTitleMobile':  'HomeFeatureTitle')} id='keyPoint4'>Constantly Evolving Platform</p>
          <p className={(isMobile ?  'HomeFeatureTextMobile center':  'HomeFeatureText SecondaryText')}>
            Our platform has been designed with you in mind. Our customer service team will meet with you throughout the process of setup to check in. 

            We are agile and have the ability to keep up to date on new advancements or tools you need to succeed. 
          </p>
        </span> 
      </div>

    </div>
  </div>
  )
}
