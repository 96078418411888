import { makeStyles } from "@mui/styles";
import { Switch } from '@mui/material';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { axiosURL } from '../../axiosURL_Front';
import config from '../../config';
import UserContext from '../../context/UserContext';
import { getAuthHeader } from '../../functions/getToken';
import PlatformStyles from '../../PlatformStyles';
import { AppointBlueButton } from '../../utils/AppointButton';
import { AppointTileWrapper } from '../../utils/AppointTileWrapper';
import InformationToolTip from '../misc/InformationToolTip';
import { notify } from '../Notification/notify';

const useStyles = makeStyles(() => ({
  EmojiImage: {
    width: '40px',
    height: '40px',
  },
  selectedEmoji: {
    backgroundColor: PlatformStyles.LogoGrayBlue + ' !important'
  }
}));

const EmojiButton = styled('button')({
  borderRadius: PlatformStyles.BorderRadius,
  backgroundColor: 'white',
  border: PlatformStyles.GrayBorder,
  display: 'inline-block',
  height: 'fit-content', 
  width: 'fit-content',
  padding: '5px',
  marginRight: '15px',
  marginBottom: '15px',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: PlatformStyles.BoxShadowDark,
    position: 'relative',
    bottom: '5px'
  }
});

export default function UserFeedbackView(props){
  const classes = useStyles();
  const {userData, setUserData} = useContext(UserContext)
  
  const [anonymized, setAnonyimzed] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [emotion, setEmotion] = useState('')

  function sendFeedback(message, anonymized, emotion) {
    if (message === '') {
      notify('i', 'Please enter a message')
      return
    }

    const packagedData = {
      anonymized,
      message,
      emotion,
      userID: userData.user.id
    }

    axios.post(axiosURL + '/feedback/create', packagedData, getAuthHeader())
      .then(res => {
        if (res.data.success) {
          notify('s', res.data.message)

          setAnonyimzed(false)
          setFeedbackMessage('')
          setEmotion('')
        }
      })   
  }
  
  // Emojis
  // https://codepen.io/aaroniker/pen/mdyYBPP
  const EmojiIcons = [
    {
      source: config.veryBadEmoji,
      title: 'very bad',
      id: 0
    },
    {
      source: config.badEmoji,
      title: 'bad',
      id: 1
    },
    {
      source: config.neutralEmoji,
      title: 'neutral',
      id: 2
    },
    {
      source: config.goodEmoji,
      title: 'good',
      id: 3
    },
    {
      source: config.veryGoodEmoji,
      title: 'very good',
      id: 4
    },

  ]

  return(
    <AppointTileWrapper style={{minWidth: '300px'}}>
      <h4>How can we improve?</h4>
      <p style={{maxWidth: '650px'}}>We appreciate you taking the time to share your thoughts. Your input helps us improve our service and provide you with a better experience. Thank you for your feedback!</p>
      <TextField
        id="outlined-multiline-static"
        className='Home_ContactUsFormTextField'
        multiline
        value={feedbackMessage}
        minRows={10}
        onChange={(e) => setFeedbackMessage(e.target.value)}
      />

      {EmojiIcons.map(e => {
        function handleClick() {
          if (e.id === emotion) {
            setEmotion('')
            return
          }
          setEmotion(e.id)
          console.log(e.id)
        }

        let selectedClass = ''
        if (emotion === e.id) selectedClass = 'selectedEmoji'

        return (
          <EmojiButton onClick={handleClick} className={classes[selectedClass]}>
            <img alt='title' className={classes.EmojiImage} src={e.source} />
          </EmojiButton>
        )

      })}
      <br/>

      Anonymize Response
      <Switch
        checked={anonymized}
        onChange={() => {setAnonyimzed(!anonymized)}}
        color={'primary'}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <InformationToolTip>Your account will not be associated with the feedback that you provide</InformationToolTip>
      <br/>
      <br/>
      <AppointBlueButton onClick={() => sendFeedback(feedbackMessage, anonymized, emotion)}>Send Feedback</AppointBlueButton>
    </AppointTileWrapper>
  )
}
