// import ErrorMessage from '../misc/ErrorMessage'
import React, { useContext, useLayoutEffect, useState  } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import UserContext from '../../context/UserContext'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import checkLoggedIn from '../../functions/checkLoggedIn'
import '../Register/Register.css'
import { TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";
import PlatformStyles from '../../PlatformStyles';
import { Alert } from '../misc/Alert';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles(() => ({
  Input: {
    // backgroundColor: 'red',
    borderRadius: PlatformStyles.BorderRadius,
    border: 'none',
    width: '300px',
  },
  ForgotPassword: {
    float: 'right',
    fontSize: '13px'
  },
  Header: {
    marginTop: '15px',
    marginLeft: '28px',
    float: 'left'
  },
  
}));

export default function Login() {

  // If they are already logged in, it goes to the dashboard
  useLayoutEffect(() => {
    if(checkLoggedIn(localStorage.getItem('auth-token'))){
      history.push('/dashboard')
    }
  }, [])
  
  const history = useHistory();
  const classes = useStyles();
  const { setUserData } = useContext(UserContext)

  let [email, setEmail] = useState('')
  const [password, setPassword] = useState('')  
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  

  const submit = async (e) => {
    e.preventDefault();

    email = email.toLowerCase()
    // console.log(tempEmail)
    const user = {
      email,
      password
    }
    console.log('hi')
    const loginRes = await axios.post(axiosURL + '/users/login', user)
                        .catch(err=> {
                          setErrorMessage('There was an error logging in, please try again later')
                        })
    
    if(loginRes.data.msg){
      setErrorMessage(loginRes.data.msg)
    } else{ 
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user
      })

      /* stores information in browser */
      localStorage.setItem('auth-token', loginRes.data.token)
      history.push('/dashboard')
    }    
  }

  const handlePasswordChange = (value) => {
    setPassword(value)

  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return(
    <div>
      <div className='body'>
        <h3 className={classes.Header}>Login</h3>

        <form onSubmit={submit} className='form'>
          {/* Email */}
          <TextField 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{marginTop:'10px'}}
              className={classes.Input}
              id="Email"
              label="Email" 
          />

          {/* PASSWORD */}
          <TextField 
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              style={{marginTop:'10px', marginBottom:'10px'}}
              className={classes.Input}
              id="Password"
              label="Password" 
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
          />
          {/* SUBMIT BUTTON */}
          <div>
              <input style={{marginTop:'10px', marginBottom:'10px'}} type="submit" value="Submit" className="btn btn-primary" />
          </div>

          {errorMessage != '' &&
            <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>  
          }

          <br></br>

          <a href='/resetpassword/request' className={classes.ForgotPassword}>
            Forgot Password?
          </a>

          
        </form>
      </div>
    </div>
  )
}