// eslint-disable-next-line import/no-anonymous-default-export
export default {
    BoxShadow: '0 0 4px 2px rgb(214, 214, 214)',
    BoxShadowDark: '0 0 4px 2px rgba(0,0,0,0.14)',
    BoxShadowWhiteBackground: '0px 0px 1px 0px rgba(0, 0, 0, 0.38)',
    BorderRadius: '10px !important',
    BorderRadiusWoImportant: '10px',
    PlatformRed: '#b60024',
    DarkRed: '#942329',
    PlatformGreen: '#00a921',
    LogoLightBlue: '#A0B9C6',
    LogoLightBlueHover: '#80949E',
    LogoGrayBlue: '#6E7794',
    LogoNavyBlue: '#24435F',
    LogoPurple: '#78395B',
    DarkGray: '#6b6b6b',
    Gray: '#808080',
    LightGray: '#f5f5f5',
    GrayBorder: '1px solid lightgrey',
    inProgressBackground:'#c5dce8',
    Yellow: '#FEE569',
    Transparent: 'transparent'
  }