import React from 'react'
import '../Content/Content.css'
import { makeStyles } from '@mui/styles';
import config from '../../../config';

import OurGoalBackground from "../OurGoalBackground.png"

const useStyles = makeStyles((theme) => ({
    Container: {
      opacity: '1',
      backgroundImage: `url(${OurGoalBackground})`,
      backgroundPosition: 'top',
      backgroundSize: 'cover', 
      minHeight: '700px',
      width: '100%',
      paddingBottom: '50px'
    }
}));

export default function OurGoal() {

    const classes = useStyles()  
  
  return(
    <div style={{textAlign: 'center'}}>
        <div className={classes.Container}>
            <div>
              <img style={{width:'180px', marginTop:'50px', marginBottom:'-50px'}} src={config.logoPath}></img>
            </div>
            <div className='Home_OurGoalTitle'>
              Our Goal
            </div>
            <div className='Home_OurGoalSubTitle'>
              is to empower Healthcare Educators,
              <br></br>
              connecting students with hands-on clinical experiences
            </div>
            <div className='Home_OurGoalText'>

            {/* By immersing themselves in each and every clinical experience, <br></br> undergraduate nursing students learn and practice 
            essential clinical skills. <br></br>  */}
            
            Appoint{'\u2122'} is here to bring your nursing students directly to those clinical experiences. 
            <br></br> Allow us to seamlessly integrate The Clinical Assistant software into your clinical program, today!
              
            </div>
            
        </div>
    </div>
  )
}