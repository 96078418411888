import ErrorMessage from '../misc/ErrorMessage'
import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext'
import axios from 'axios';
import { axiosURL } from '../../axiosURL_Front'
import './reporting.css'

// https://www.youtube.com/watch?v=IDUBHxn5nAQ

export default function Reporting() {
  
  return(
      <div className="">Reporting</div>
  )
}