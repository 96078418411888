import React, { useState, createRef, useCallback } from "react";
import './FileUploader.css'
import Papa from "papaparse";
import { notify } from "../Notification/notify";
import Dropzone from './Dropzone'
import { AppointBlueButton, AppointGreyButton, AppointRedButton } from "../../utils/AppointButton";

const buttonStyle = {height: '30px', width: '80px !important', lineHeight: '13px', marginTop: '5px'}

// https://codesandbox.io/s/charming-hamilton-x7skeu?file=/src/App.js:270-281
const FileUploader = ({upload, width, height, fileTypes}) => {
  let [selectedFile, setSelectedFile] = useState(null)

  const handleUpload = () => {
    // If object is not blank
    if(selectedFile != null && Object.keys(selectedFile).length !== 0){
      upload(selectedFile.results)
      setSelectedFile(null)
    }
  }
  
  const [fileDropped, setFileDropped] = useState(false);
 
  const onDrop = useCallback(newFile => {
    for (let i = 0; i < fileTypes.length; i++) {
      fileTypes[i] = fileTypes[i].toLowerCase()
    }
    
    if (!fileTypes.includes(newFile[0].name.split('.').pop().toLowerCase())) {
      let notificationString = ''
      if (fileTypes.length === 1) {
        notificationString =  ' ' + fileTypes[0]
      } else {
        for (let i = 0; i < fileTypes.length - 1; i++) {
          notificationString += ' ' + fileTypes[i].toUpperCase() + ','
        }
        notificationString += ' or ' + fileTypes[fileTypes.length -1].toUpperCase()
      }

      notify('e', 'File format must be' + notificationString)
      return
    }

    if (fileTypes.includes('csv')) {
      Papa.parse(newFile[0], {
        complete: function(results) {
          setSelectedFile({name: newFile[0].name, results});
        }}
      )
    } else {
      console.log('not csv')
      setSelectedFile({name: newFile[0].name, results: newFile})
    }
  }, [fileTypes]);

  return (
    <div style={{width: width, height: height, marginBottom: '50px'}}>
      <Dropzone onDrop={onDrop} title={selectedFile != null ? selectedFile.name : ''}/>
        <div className='fileUploaderButtonArea'>
          <AppointBlueButton disabled={selectedFile == null} style={buttonStyle} id="UploadButton" className='fileUploadButton' onClick={() => handleUpload()}>Upload</AppointBlueButton>
          {selectedFile != null && 
            <AppointGreyButton style={buttonStyle} id="UploadButton" className='fileUploadButton' onClick={() => setSelectedFile(null)}>Remove</AppointGreyButton>
          }
        </div>
    </div>
    
  );
}

export default FileUploader;
