import { useHistory } from 'react-router-dom';
import config from '../../config'

const altTag = 'Appoint logo'

export function AppointLogo({ compact, blueVarient, height, style }) {
  const history = useHistory();
  let standardHeight = '65px'
  let color = 'white'
  let logo = config.logoWithName
  if (height) {
    standardHeight = height
  }

  if (blueVarient) {
    color = '#364a5e'
    logo = config.logoWithBlueName
  }

  if (compact) {
      return <img alt={altTag} src={config.logoPath} style={style} className="logo" />
  }

  return (
    <div className='navBar_Brand' onClick={() => history.push('/')}>
      <img alt={altTag} src={logo} style={{height: standardHeight}}/>
      <div style={{marginLeft:'-7px', marginTop:'-8px', marginRight:'-5px', color:color, fontSize:'10px'}}>{'\u2122'}</div>
      <h1 className='navbar-clinicalAssistant' style={{color}}>CLINICAL <br></br> ASSISTANT</h1>
    </div>
  )
}