import { Checkbox as MuiCheckBox } from "@mui/material"

export default function Checkbox({checked, onChange}) {
    return (
      <MuiCheckBox 
          checked={checked}
          onChange={onChange}
          color="primary"
      />
    )
  }


