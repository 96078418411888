import { styled } from '@mui/system';
import PlatformStyles from '../PlatformStyles';
import { isMobile } from 'react-device-detect';

const padding  = isMobile ? '.5rem': '1.5rem'

export const AppointTileWrapper = styled('div')({
  backgroundColor: 'white',
  borderRadius: PlatformStyles.BorderRadius,
  boxShadow: PlatformStyles.BoxShadow,
  padding: padding,
});