export default function copyToClipboard(s) {
  return new Promise((resolve, reject) => {
    navigator.clipboard.writeText(s)
      .then(() => {
        resolve("Copied to clipboard successfully.");
      })
      .catch(() => {
        reject(new Error("Failed to copy to clipboard."));
      });
  });
}
