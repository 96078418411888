import { Switch } from '@mui/material';
import { makeStyles } from "@mui/styles";
import axios from 'axios';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PlatformStyles from '../../PlatformStyles';
import { axiosURL } from '../../axiosURL_Front';
import config from '../../config';
import UserContext from '../../context/UserContext';
import belongsToSuperUserEntity from '../../functions/belongsToAppointEntity';
import { getAuthHeader } from '../../functions/getToken';
import { normalizeTime } from '../../functions/normalizeTime';
import { AppointTileWrapper } from '../../utils/AppointTileWrapper';
import Loading from '../Loading/Loading';
import { notify } from '../Notification/notify';
import Table from '../Users/Table';
import { Alert } from '../misc/Alert';
import { AppointBlueButton, AppointGreyButton } from '../../utils/AppointButton';

const useStyles = makeStyles(() => ({
  EmojiImage: {
    height: '20px !important',
    width: '40px',
    height: '40px',
  },
  selectedEmoji: {
    backgroundColor: PlatformStyles.LogoGrayBlue
  }
}));


export default function InternalFeedbackView(props){
  const history = useHistory();
  const classes = useStyles();
  const {userData, setUserData} = useContext(UserContext)
  
  const [isLoading, setIsLoading] = useState(true);
  
  const [unReviewedFeedback, setUnReviewedFeedback] = useState([])
  const [reviewedFeedback, setReviewedFeedback] = useState([])

  const [feedbackModal, setFeedbackModal] = useState(false)
  const defaultFeedbackContext = {name: '', createdAt: '', emotion: 0, message: '', reviewed: '', schoolName: ''}
  const [feedbackModalContext, setFeedbackModalContext] = useState(defaultFeedbackContext)
  const [showEmojis, setShowEmojis] = useState(true)
  
  useLayoutEffect(() =>{
    // Checks that the user is logged in. If not, pushes them to the login page

    if (belongsToSuperUserEntity(userData.user.schoolID)) {
      axios.post(axiosURL + '/feedback/getAll', null, getAuthHeader())
        .then(res => {
          if (res.data.success) {
            const feedback = sortFeedback(res.data.data)

            setUnReviewedFeedback(feedback.filter(f => f.reviewed === false))
            setReviewedFeedback(feedback.filter(f => f.reviewed === true))

          } else {
            notify('e', res.data.message)
          }
          setIsLoading(false)
        })
    }    

  }, [isLoading, history, userData.user.schoolID])

  if(isLoading){
    return <Loading />
  }

  function sortFeedback(f) {
    return f.sort(function(a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }

  const EmojiIcons = {
    0: {
      source: config.veryBadEmoji,
      title: 'very bad'
    },
    1: {
      source: config.badEmoji,
      title: 'bad'
    },
    2: {
      source: config.neutralEmoji,
      title: 'neutral'
    },
    3: {
      source: config.goodEmoji,
      title: 'good'
    },
    4: {
      source: config.veryGoodEmoji,
      title: 'very good'
    },
    null: {
      source: config.transparentImage
    },
    '-1': {
      source: config.transparentImage
    }
  }

  function FeedbackModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Feedback from {props.feedback.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.feedback.anonymized &&
            <>
              <Alert severity="error">THE USER REQUESTED THIS FEEDBACK TO BE ANONYMOUS. DO NOT REACH OUT TO THEM REGARDING THIS FEEDBACK.</Alert>
              <br/>
            </>
          }
          <h5>Name:</h5>
          <p style={{textAlign: "center"}}>
            {props.feedback.name}
          </p>
          <h5>School Name:</h5>
          <p style={{textAlign: "center"}}>
            {props.feedback.schoolName}
          </p>
          <h5>Date Created:</h5>
          <p style={{textAlign: "center"}}>
            {normalizeTime(props.feedback.createdAt)}
          </p>
          <h5>Emotion:</h5>
          <p style={{textAlign: "center"}}>
            {showEmojis ? 
              <img alt='title' className={classes.EmojiImage} src={EmojiIcons[props.feedback.emotion].source} />
              :
              <>{props.feedback.emotion}</>
            }
            
          </p>
          <h5>Message:</h5>
          <p style={{textAlign: "center"}}>
            {props.feedback.message}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Close</AppointGreyButton>
          <AppointBlueButton onClick={() => props.onSubmit(props.feedback._id)}>
            {!props.feedback.reviewed ? 
              <>Mark Reviewed</>
              :
              <>Mark UnReviewed</>
            }
          </AppointBlueButton>
        </Modal.Footer>
      </Modal>
    );
  }

  function toggleReviewed(id) {

    axios.post(axiosURL + '/feedback/review', { id }, getAuthHeader())
      .then(res => {
        if (res.data.success) {
          let updatedFeedback = res.data.data
          if (res.data.data.reviewed) {
            setUnReviewedFeedback(unReviewedFeedback.filter(f => f._id !== updatedFeedback._id ))
            setReviewedFeedback(sortFeedback(reviewedFeedback.concat(updatedFeedback)))
          } else {
            setReviewedFeedback(reviewedFeedback.filter(f => f._id !== updatedFeedback._id ))
            setUnReviewedFeedback(sortFeedback(unReviewedFeedback.concat(updatedFeedback)))
          }
          setFeedbackModalContext(defaultFeedbackContext)
          setFeedbackModal(false)
        } else {
          notify('e', res.data.message)
        }
      })
  }

  

  const feedbackColumns = [
    {
      title: "User",
      field: "name"
    },
    {
      title: "School",
      field: "schoolName",
    },
    {
      title: "Emotion",
      field: "emotion",
      render: (rowData) => {
        if (showEmojis) {
          return (
            <img alt='title' className={classes.EmojiImage} src={EmojiIcons[rowData.emotion].source} />
          )
        } else {
          return <>{parseInt(rowData.emotion)}</>
        }
        
      },
    },
    {
      title: "Created",
      field: "createdAt",
      type: 'date'
    },
  ];

  return(
    <AppointTileWrapper style={{minWidth: '300px'}}>
      <FeedbackModal
          show={feedbackModal}
          type={'add'}
          feedback={feedbackModalContext}
          onSubmit={toggleReviewed}
          onHide={() => { setFeedbackModal(false); setFeedbackModalContext(defaultFeedbackContext) }}
      />

      Show Emojis
      <Switch
        checked={showEmojis}
        onChange={() => {setShowEmojis(!showEmojis)}}
        color={'primary'}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <br/>
      Emotions are scaled very bad (0) to very good (5). 2 is neutral. -1 means they didn't select an emotion

      <Table type='feedback' title='UnReviewed Feedback' columns={feedbackColumns} data={unReviewedFeedback} handleRowClick={(e) => { setFeedbackModal(true); setFeedbackModalContext(e) }} />
      <br/>
      <Table type='feedback' title='Reviewed Feedback' columns={feedbackColumns} data={reviewedFeedback} handleRowClick={(e) => { setFeedbackModal(true); setFeedbackModalContext(e) }} />
    </AppointTileWrapper>
  )
}
